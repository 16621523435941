import React, {useState} from "react";
import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useGlobal } from "../../../../../../resources/setting/global";
import transparentSmall from './../../../../../../assets/images/background-transparent-small.png';
import ApiServer from "../../../../../../resources/conn/ApiServer";
import UtilsObject from "../../../../../../resources/utils/UtilsObject";
import UtilsCanvas from "../../../../../../resources/utils/UtilsCanvas";
import UtilsArray from "../../../../../../resources/utils/UtilsArray";
import UtilsColor from "../../../../../../resources/utils/UtilsColor";
import GlobalEvents from "../../../../../../resources/event/GlobalEvents";

const useStyles = makeStyles((theme) => ({
  scrollBar: {
    overflow: 'scroll',
    overflowX: 'hidden',
    paddingLeft: 10, 
    paddingRight: 10,
  },
  paper: {
    padding: 10,
    //textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(0),
  },
}));

export default function BoxColorFill() {

  const classes = useStyles(); // style
  const {valGlobal, setGlobal} = useGlobal(); // global varible
  const [disabledInputs, setDisabledInputs] = useState(true); // disabled inpust
  const [panelsHeight, setPanelsHeight] = useState( 100 ); // scroll height
  const [color, setColor] = useState(''); // selected fill color
  const [workingFillColors, setWorkingFillColors] = useState([]); // array container last color selecteds
  const [colors, setColors] = useState([]); // color after input word filter
  const [colorsFilter, setColorsFilter] = useState([]); // color after input word filter
  const [colorFilter, setColorFilter] = useState(''); // input filter  words

  const resizePanelsHeight = () => {
    setPanelsHeight( 
      window.innerHeight - 
      valGlobal.structure.tableLines.top.height - 
      valGlobal.structure.tableLines.bottom.height );
  };

  const getColors = () => {
    new ApiServer().get('colors', 'get').then( data => {
      if( data.statusText === 'OK' && data.hasOwnProperty('data') ){
        setColors( data.data );
        setColorsFilter( data.data );
      }
    });
  };

  const getData = ( event ) => {

    //const ao = event.hasOwnProperty('activeObject') ? event.activeObject : undefined;
    UtilsObject.getActive( valGlobal.canvas, event && event.activeObject ).then( object => {
      setDisabledInputs( false );
      setColor( object.fill || '' );
      // setOpacity( object.opacity || 1 );

    }).catch(resetData);
  }

  const changeColorFilter = ( filter ) => {
    if( !filter ) setColorsFilter( colors ); 
    setColorsFilter(
      colors.filter(
        (el) => String(el.color_name).toLowerCase().indexOf( String(filter).toLowerCase() ) > -1 ||
        String(el.hex).toLowerCase().indexOf( String(filter).toLowerCase() ) > -1
       )
    );
  };

  const onChangeColor = ( event ) => {
    const v = String(event.target.value).substr(0,9) || '#FFFFFF' ;
    setColor( v );
    UtilsCanvas.set( valGlobal.canvas, {
      fill: v,
    });
  };
  
  const selectColorCode = ( event ) => {
    const key = event.keyCode || event.charCode ;
    if (key === 13) {
      const value = colors.find(({code,color_name})=>{ 
        // if code like filter
        return String(code).toLowerCase() === String(colorFilter).toLowerCase() || 
        // if name like filter
        String(color_name).toLowerCase() === String(colorFilter).toLowerCase() 
      });
      if(typeof value === 'object'){
        changeColorFill( value );
        setColorFilter('');
      }
    }
  }

  // const onChangeOpacity = ( event ) => {
  //   const v = Number(event.target.value);
  //   setOpacity( v );
  //   UtilsCanvas.set( valGlobal.canvas, {
  //     opacity: v / 100,
  //   });
  // };

  // 00
  // 11
  // 22
  // 33
  // 44
  // 55
  // 66
  // 77
  // 88
  // 99
  // AA
  // BB
  // CC
  // DD
  // EE
  // FF

  // https://casesandberg.github.io/react-color/
  const changeColorFill = ( el, insertToGlobal ) => {

    UtilsObject.getActive( valGlobal.canvas ).then( object => {

      insertToGlobal = insertToGlobal === undefined ? true : insertToGlobal;

      if( el === undefined ){
        el = {
          name: '',
          code: '',
          hex: null,
        };
      }

      // get type object
      const { typeName } = object;

      // pre-set document
      object.document = object.document || {};
      object.document.pattern = object.document.pattern || {};
      object.document.pallete = object.document.pallete || {};

      // denied change fill color
      switch (typeName) {
        case 'Bend':
        case 'Image':
        case 'Line':
        // case 'Group': 
        break;
        default:

          const values = {
            fill: el.hex,
            // document
            document: {
              ...object.document,
              // set fill pallete
              pallete: {
                ...object.document.pallete,
                fill: {
                  name: el.color_name,
                  code: el.code,
                  hex: el.hex,
                },  
              },
              // remove fill pattern
              pattern: {
                ...object.document.pattern,
                fill: {
                  name: "Silk: Silk",
                  url: false,
                  code: false,
                },
              },
            },
          };

          // change variable color
          setColor( el.hex );
          // apply object
          UtilsCanvas.set( valGlobal.canvas, values );
          object.set( values );
          // history
          valGlobal.canvas.history.register();
          // push to global colors
          if( el.hex !== null && insertToGlobal ){
            pushWorkingFillColors( el );
          }
          break;
      }

      //setAnchorEl(null);
      clearColorFilter();
      UtilsCanvas.focus();

    });

  }
  const resetData = () => {
    setDisabledInputs( true );
    setColor( '' );
    // setOpacity( 1 );
  }

  const clearColorFilter = (event) => {
    setColorsFilter( colors );
    setColorFilter( '' );
  }

  const getWorkingFillColors = () => {
    setWorkingFillColors(valGlobal.working.fillColors);
  }

  const pushWorkingFillColors = ( el ) => {

    let c = [ el, ...valGlobal.working.fillColors ];
    c = UtilsArray.unique( c, ['hex'] );
    c = c.slice(0,8);

    setGlobal({...valGlobal, 
      working: {...valGlobal.working, 
        fillColors: c,
      }
    });      
  
  }

  React.useEffect(()=>{
    getWorkingFillColors();
  },[valGlobal]);

  React.useEffect(()=>{
    getData();
    getColors();
    getWorkingFillColors();
    resizePanelsHeight();
    window.addEventListener("resize", resizePanelsHeight);
    GlobalEvents.on('changeActiveObject',getData);
    return () => {
      window.removeEventListener("resize", resizePanelsHeight);
      GlobalEvents.off('changeActiveObject',getData);
    }
  },[])

  return (
    <div className={classes.scrollBar} style={{height: panelsHeight}}>
      {/* <Paper className={classes.paper}> */}
      <div style={{marginBottom:10}}>
        <Typography variant="subtitle1" gutterBottom>Fill</Typography>

        <Grid container spacing={0}>
          {workingFillColors.map((el) => (
            <Grid item xs={3} key={el.id}>
                <div style={{height: 15, backgroundColor: el.hex, borderRadius: 3, marginRight: 0, cursor: 'pointer' }}
                title={`Name: ${el.color_name}\nHex: ${el.hex}\nCode: ${el.code}\n`}
                onClick={()=>changeColorFill(el,false)}
                >&nbsp;</div>
            </Grid>
          ))}
        </Grid>

        <div style={{marginTop:3,marginBottom:3,}}>
          <label style={{color:'#777777'}}>Color:</label>
        </div>
        <div>
          <TextField value={ typeof color === 'object' ? 'process' : color }
            disabled={ typeof color === 'object' ? true : disabledInputs }
            onChange={ onChangeColor } 
            variant="outlined" size="small"
            fullWidth
            inputProps={{ 
              style: {
                textAlign: 'center',
                fontSize: 11,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 5,
                paddingRight: 5,
              } 
            }} />
        </div>
        {/* <div style={{marginTop:3,marginBottom:3,}}>
          <label style={{color:'#777777'}}>Opacity:</label>
        </div>
        <div>
          <TextField value={opacity}
            onChange={ onChangeOpacity } 
            variant="outlined" size="small"
            fullWidth
            inputProps={{ 
              style: {
                textAlign: 'center',
                fontSize: 11,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 5,
                paddingRight: 5,
              } 
            }} />
        </div> */}
        {/* <hr style={{margin:'15px 0 10px 0'}}/> */}
        <div style={{marginTop:3,marginBottom:3,}}>
          <label style={{color:'#777777'}}>Search:</label>
          <div style={{color:'#777777',float:'right'}}>
            <Search style={{fontSize:13}} />
          </div>
        </div>
        <div>
          <TextField 
            disabled={ disabledInputs }
            // onChange={ onChangeInput } 
            variant="outlined" size="small"
            fullWidth

            onKeyUp={ selectColorCode }
            onChange={ (e)=>{ setColorFilter( e.target.value ); changeColorFilter( e.target.value )} }
            onBlur={()=>UtilsCanvas.focus()}
            value={ colorFilter }

            title="Press Enter to select color code"

            inputProps={{ 
              style: {
                textAlign: 'center',
                fontSize: 11,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 5,
                paddingRight: 5,
              } 
            }} />
        </div>
      </div>

      <div
        key = {'transparent'}
        onClick={()=>changeColorFill( undefined, false )}
        style={{ height: 25, margin: 1, marginBottom:2, borderRadius: 5, cursor: "pointer", backgroundColor: '#ffffff', backgroundImage: `url(${transparentSmall})`, border: '1px solid #cccccc'}} >
        <div style={{ padding: "5px 1px 3px 0px", textAlign: "center", fontSize: "11px", color: '#777777'}} >
          <div style={{ fontSize: 12 }}>&nbsp;</div>
        </div>
      </div>

      {/* </Paper> */}
      {colorsFilter.map((el) => (
        // <div style={{display:'block',width:'100$',height:30, cursor:'pointer', backgroundColor: el}} key={el}>
        //   <div style={{padding:'6px 3px 3px 5px',fontSize:'12px',color: new UtilsColor().invertColorBW( el , true ) }}>{el}</div>
        // </div>
        <div
          key = {el.id}
          onClick={()=>changeColorFill(el)}
          style={{
            // width: 65,
            // width: '100%',
            // height: 65,
            // borderRadius: 40,
            // margin: "2px",
            // float: "left",

            height: 40,
            margin: '1px',
            borderRadius: 5,

            cursor: "pointer",
            backgroundColor: el.hex,
          }}
        >
          <div
            style={{
              // padding: "21px 1px 3px 0px",

              padding: "14px 1px 3px 0px",
              textAlign: "center",
              fontSize: "11px",
              color: new UtilsColor().invertColorBW(el.hex, true),
            }}
          >
            {/* {el.hex}  */}
            <div style={{ fontSize: 10 }}> {el.code ? el.code : el.color_name } </div>
          </div>
        </div>
      ))}
      {/* </Paper> */}
    </div>
  );
}
