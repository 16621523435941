import React, {useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Backdrop, Checkbox, CircularProgress, IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import ApiServer from "../../../../resources/conn/ApiServer";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import UtilsArray from '../../../../resources/utils/UtilsArray';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
    minWidth: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  input: {
    display: 'none',
  },
  paper: {
    width: 200,
    maxHeight: 350,
    overflow: 'auto',
  },
  button: {
    margin: 4,
  },
  itemText: {
    fontSize: '12px',//Insert your required size
    color: '#000',
  },
  itemTextSecondary: {
    fontSize: '11px',//Insert your required size
    color: '#ccc',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
export default function UpdateMaterialProcessRel(props) {

  const classes = useStyles();

  const {enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [submitEnabled, setSubmitEnabled ] = useState(true);
  const [id, setId ] = useState(null);

  // --------------------------------

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const listMaterialProcessRel = ( id ) => {   

    setId( id );
    if(!id) return;

    new ApiServer().get( 'material-process-rel', 'get/' + id ).then(( e )=>{
      console.log( e.statusText, e);
      if( e.statusText === 'OK' ){

        let l = [];
        let r = [];
        let data = e.data;

        // send to right or left list
        data.forEach( el => {
          // if exists on list rel set right list
          if(el.checked === 1){
            r.push( el );
          // else set left list
          } else {
            l.push( el );
          }
        })

        setLeft( UtilsArray.sortByHierarchyAndName( l ) );
        setRight( r ); 
        setSubmitEnabled( true );
        setLoading( false );
      }
    }).catch((err)=>{
      console.log( err.statusText, err);
    });
  };

  const onSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();

    if(submitEnabled === false) return;

    const error = (err) => {
      responses++;
      if( responses !== pools ) return;
      console.log( err.statusText, err);
      enqueueSnackbar('Error!', {variant:'error'});
      setSubmitEnabled( true );
      setLoading( false );
    };
  
    const done = (e) => {
      responses++;
      if( responses !== pools ) return;
  
      if(e.statusText==='OK'){
        GlobalEvents.dispatch('updateMaterials');
        enqueueSnackbar('Update!', {variant:'success'});
        clearAll();
  
        // ToolDialog close
        if(props.hasOwnProperty('setOpen')){
          props.setOpen( false );
        }
  
      }
      setSubmitEnabled( true );
      setLoading( false );
    }

    setSubmitEnabled( false );
    setLoading( true )
    // ----------------------------------------------------------------------------

    let create = [];
    let remove = [];

    // map all removed itens
    left.forEach((el)=>{
      if( Number(el.rel_id) > 0 ){
        remove.push(el.rel_id);
      }
    })
    // map news itens, not old itens
    right.forEach((el)=>{
     // without rel and no checked
     if( !el.rel_id && el.checked === 0 ){
        create.push(el.id);
     }
    })

    // console.log('Create',create);
    // console.log('Delete',remove);
    // console.log('Id',id);

    const data = {
      id: id,
      create_ids: create,
      remove_ids: remove,
    }

    let pools = 0;
    let responses = 0;

    if( create.length > 0 ){
      console.log('CREATE');
      pools++;
      new ApiServer().post( 'material-process-rel', 'create/' + id , data ).then(done).catch(error);  
    }
    if( remove.length > 0 ){
      pools++;
      console.log('DELETE');
      new ApiServer().delete( 'material-process-rel', 'delete/' + id , data ).then(done).catch(error);
    }

    if( pools === 0 ){
      setSubmitEnabled( true );
      setLoading( false );
    }
    
  };

  const clearAll = () => {
    setSubmitEnabled( true );
    setLoading( false );
    setId( null );
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  // const handleAllRight = () => {
  //   setRight(right.concat(left));
  //   setLeft([]);
  // };

  // const handleAllLeft = () => {
  //   setLeft(left.concat(right));
  //   setRight([]);
  // };

  const customList = (items) => (
    <div className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((el) => {
          const labelId = `transfer-list-item-${el.id}-label`;

          return (
            <ListItem key={el.id} role="listitem" button 
            onClick={handleToggle(el)} style={{padding:0}}
            disabled={el.depth===0?true:false}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(el) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              {/* <ListItemText id={labelId} primary={el.name} style={useStyles.itemText}/> */}
              <ListItemText
                disableTypography
                primary={<Typography type="body2" className={classes.itemText}>{el.name}</Typography>}
                  secondary={<Typography type="body2" className={classes.itemTextSecondary}>{el.parent_name}</Typography>}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </div>
  );

  useEffect(()=>{
    if( Number(props.data.id) > 0 ){
      setSubmitEnabled( false );
      listMaterialProcessRel( props.data.id );
    }
  },[props]);

  return (
    <Container component="main" maxWidth="md" style={{minWidth:500}}>
      <LinearProgress style={{display: (!loading?'none':'block'), minWidth:200}} />
      <div style={{display: (loading?'none':'block')}}>
        <form className={classes.form} noValidate autoComplete="off" >
          <Grid container spacing={2}>

            {/* <Grid item xs={12} sm={12} style={{textAlign:'center',marginBottom:15,marginTop:0,paddingTop:0,}}>
            {props.data.name || ''}
            </Grid> */}

            {/* <Grid item xs={12} sm={12}> */}

              <Grid container spacing={0} justify="center" alignItems="center" className={classes.root}>
                <Grid item style={{padding:0}}>{customList(left)}</Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    {/* <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleAllRight}
                      disabled={left.length === 0}
                      aria-label="move all right"
                    >
                      ≫
                    </Button> */}
                    
                    <IconButton
                      variant="contained"
                      color={ leftChecked.length === 0 ? "default" : "primary" }
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      <CallMadeIcon/>
                      {/* &gt; */}
                    </IconButton>
                    <IconButton
                      variant="contained"
                      color={ rightChecked.length === 0 ? "default" : "primary" }
                      // size="small"
                      className={classes.button}
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      <CallReceivedIcon/>
                      {/* &lt; */}
                    </IconButton>
                    {/* <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleAllLeft}
                      disabled={right.length === 0}
                      aria-label="move all left"
                    >
                      ≪
                    </Button> */}
                  </Grid>
                </Grid>
                <Grid item>{customList(right)}</Grid>
              </Grid>
              
            {/* </Grid> */}

            <Grid item xs={12} sm={12}>
              <Button
                disabled={!submitEnabled}
                type="no_submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Update {right.length || ''}
              </Button>

            </Grid>
          </Grid>

        </form>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

// UpdateMaterialProcessRel.propTypes = {
//   label: PropTypes.string.isRequired,
//   handleCheckboxChange: PropTypes.func.isRequired,
// };