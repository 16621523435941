import React, { useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

//icons
import { Delete } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { useGlobal } from "../../../../resources/setting/global";
import Effects from "../../../../resources/fabricjs/Effects";
import Pattern from "../../../../resources/fabricjs/Pattern";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import BoxPanel from "../../../../resources/component/general/views/BoxPanel";
import BoxRemoveColor from "./boxes/effects/BoxRemoveColor";
import If from "../../../../resources/utils/If";
import BoxBrightness from "./boxes/effects/BoxBrightness";
import BoxSaturation from "./boxes/effects/BoxSaturation";
import BoxGrayscale from "./boxes/effects/BoxGrayscale";
import UtilsObject from "../../../../resources/utils/UtilsObject";
import BoxContrast from "./boxes/effects/BoxContrast";
import BoxGamma from "./boxes/effects/BoxGamma";
import BoxNoise from "./boxes/effects/BoxNoise";
import BoxBlur from "./boxes/effects/BoxBlur";
import BoxSharpen from "./boxes/effects/BoxSharpen";
import BoxEmboss from "./boxes/effects/BoxEmboss";
import BoxInnerShadow from "./boxes/shadow/BoxInnerShadow";
import BoxDropShadow from "./boxes/shadow/BoxDropShadow";

const ITEM_HEIGHT = 510;
const ITEM_WIDTH = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  button: {
    paddingTop: 6,
    paddingBottom: 4,
    textTransform: "none",
    backgroundColor: '#2a82db',
  },
  avatar: {
    // backgroundColor: green[100],
    // color: green[600],
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  // container: {
  //   display: 'grid',
  //   gridTemplateColumns: 'repeat(12, 1fr)',
  //   gridGap: theme.spacing(3),
  // },
  
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    fontSize: 11,
    padding: 5,
    textTransform: "none",
    marginTop: 10,
  },
  menuItem: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		fontSize: 12,
		fontWeight: 500,
  }
}));

export default function PanelFilters(props) {

  // styles
  const classes = useStyles();
  // global variables
  const {valGlobal} = useGlobal();
  const Effect = new Effects();
  const [filters, setFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [enableButton, setEnableButton] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded( isExpanded ? panel : false );
  };

  const handleClick = (event) => {
    setAnchorEl( event.currentTarget );
  };

  const onDeleteFilter = (index) => {
    new Effects().removeByIndex( valGlobal.canvas, index, readEffects );
  };

  const handleClose = (el) => {
    new Effects().add( valGlobal.canvas, el.type, null, ()=>{
      // close menu options
      setAnchorEl(null);
      // close accordion
      setExpanded(false);
    });
  };

  const readEffects = ( event ) => {

    const eventObject = event && event.hasOwnProperty('activeObject') ? ( event.activeObject ? event.activeObject : null ) : null ;
    UtilsObject.getActive( valGlobal.canvas, eventObject ).then( object => {

      object.filters = object.filters || [];
      setFilters( object.filters );

      // filter on images
      if(object.typeName === "Image"){

        setEnableButton( true );
      
        // apply filters on pattern
      } else if( 
        !object.hasOwnProperty('document') || 
        !object.document.hasOwnProperty('pattern') || 
        !object.document.pattern.hasOwnProperty('fill') || 
        !object.document.pattern.fill.hasOwnProperty('url') || // no change url
        !object.document.pattern.fill.url ){ // no change url

        // disable button 
        setEnableButton( false );

      } else {

        const { name, url, scale, angle, padding, offsetX, offsetY } = object.document.pattern.fill;
        new Pattern().add( valGlobal.canvas, {
          object: object,
          isFill: true,
          name: name,
          url: url,
          scale: scale,
          //width: width === 0 ? null : width,
          angle: angle,
          padding: padding,
          offsetX: offsetX,
          offsetY: offsetY,
          filters: object.filters,
        });
        setEnableButton( true );

      }

    }).catch(()=>{

      setFilters( [] );
      setEnableButton( false );

    });

  };

  useEffect(() => {
    readEffects();
    GlobalEvents.on("changeActiveObject", readEffects );
    return () => {
      GlobalEvents.off("changeActiveObject", readEffects );
    };
  }, []);

  //https://material-ui.com/components/menus/#context-menu
  return (
    <div style={{ padding: 10 }} onClick={() => readEffects()}>

      <BoxPanel title="Filters">

        <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            size="medium"
            // startIcon={<AddIcon/>}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            fullWidth
            disabled={!enableButton}
          >
            Filters
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                marginTop: 1,
                maxHeight: ITEM_HEIGHT,
                width: ITEM_WIDTH,
              },
            }}
          >
            {new Effects().getFilters().map((el) => {
              return (
                <MenuItem onClick={() => handleClose(el)} key={el.type} className={classes.menuItem}>
                  <div style={{ width: 30 }}>
                    {Effect.getIcon( el.type )}
                  </div>
                  {el.label}
                </MenuItem>
              );
            })}
          </Menu>

          <p style={{display: !enableButton ? 'block' : 'none', marginBottom: 0 }}> 
            Select a <strong>image</strong> or a <strong>process</strong>.
          </p>

        </BoxPanel>

        {filters.length ? (
          <div style={{paddingTop:1,marginBottom:5}}>
            {filters.map((el, index) => {
                return (
                  <Accordion
                    key={index}
                    expanded={el.expanded === false ? false : expanded === index}
                    onChange={el.expanded === false ? null : handleChange(index)}
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <AccordionSummary
                      expandIcon={el.expanded === false ? null : <ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <React.Fragment>
                        {/* <IconButton
                          aria-label="delete"
                          size="small"
                          style={{ width: "20px", height: "20px" }}
                        >
                          <Delete
                            fontSize="small"
                            onClick={() => onDeleteFilter(index)}
                          />
                        </IconButton> */}
                        <span style={{ width: "30px", textAlign: "center" }}>
                          {Effect.getIcon( el.type )}
                        </span>
                        <div style={{ paddingTop: "3px", paddingLeft: "7px" }}>
                          {el.type}
                        </div>
                      </React.Fragment>
                      {/* <Typography className={classes.heading}>Effect 1</Typography>
                      <Typography className={classes.secondaryHeading}>Icon 1</Typography> */}
                    </AccordionSummary>
                    {el.expanded === false ? null : (
                      <AccordionDetails style={{ padding: 5, display: "block" }}>
                        
                        <If value={el.type === 'Grayscale'}>
                          <BoxGrayscale index={index} values={{...el,index:index}} />
                        </If>
                        <If value={el.type === 'Brightness'}>
                          <BoxBrightness index={index} values={{...el,index:index}} />
                        </If>
                        <If value={el.type === 'RemoveColor'}>
                          <BoxRemoveColor index={index} values={{...el,index:index}} />
                        </If>
                        <If value={el.type === 'Saturation'}>
                          <BoxSaturation index={index} values={{...el,index:index}} />
                        </If>
                        <If value={el.type === 'Contrast'}>
                          <BoxContrast index={index} values={{...el,index:index}} />
                        </If>
                        <If value={el.type === 'Gamma'}>
                          <BoxGamma index={index} values={{...el,index:index}} />
                        </If>
                        <If value={el.type === 'Noise'}>
                          <BoxNoise index={index} values={{...el,index:index}} />
                        </If>
                        <If value={el.type === 'Blur'}>
                          <BoxBlur index={index} values={{...el,index:index}} />
                        </If>
                        <If value={el.type === 'Sharpen'}>
                          <BoxSharpen index={index} values={{...el,index:index}} />
                        </If>
                        <If value={el.type === 'Convolute'}>
                          <BoxEmboss index={index} values={{...el,index:index}} />
                        </If>

                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          fullWidth
                          className={classes.deleteButton}
                          startIcon={<Delete fontSize="small" />}
                          onClick={() => onDeleteFilter(index)}
                        >
                          Remove
                        </Button>

                      </AccordionDetails>
                    )}
                  </Accordion>
                );
              })}
            </div>
          ) : ''}

        <BoxDropShadow/>
        <BoxInnerShadow/>

    </div>
  );
}
