import React, { useEffect, useState } from "react";
//import GlobalEvents from "../../../event/GlobalEvents";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Menu,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import DeleteIcon from "@material-ui/icons/Delete";

import { useGlobal } from "../../../setting/global";
import ElementOpacity from "./ElementOpacity";
import ElementDimension from "./ElementDimension";
import BoxDropShadow from "../../../../structure/layouts/the-big-fucking-magic/panels/boxes/shadow/BoxDropShadow";
import MenuAlign from "../../../../structure/layouts/the-big-fucking-magic/contextMenu/MenuAlign";
import MenuOrder from "../../../../structure/layouts/the-big-fucking-magic/contextMenu/MenuOrder";
import MenuRotate from "../../../../structure/layouts/the-big-fucking-magic/contextMenu/MenuRotate";
import MenuClipboard from "../../../../structure/layouts/the-big-fucking-magic/contextMenu/MenuClipboard";
import MenuFlipAndFit from "../../../../structure/layouts/the-big-fucking-magic/contextMenu/MenuFlipAndFit";
import CropIcon from '@material-ui/icons/Crop';
import UtilsCanvas from "../../../utils/UtilsCanvas";
import GlobalEvents from "../../../event/GlobalEvents";

const PIXEL_TO_MILLIMETER = 0.2645833333;
//const MILLIMETER_TO_PIXEL = 3.7795275591;

const styles = {
  box: {
    color: "#111111",
    backgroundColor: "#dddddd",
    borderRadius: "5px",
    padding: "5px 11px",
    // textAlign: 'center',
    margin: "0px 3px 0px 0px",
    float: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 12,
    fontWeight: 500,
    // lineHeight: 1.75,
  },
  textArea: {
    color: "#111111",
    borderRadius: "5px",
    border: "1px solid #BBBBBB",
    padding: "10px 10px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 14,
    fontWeight: 500,
    height: 150,
    width: 230,
    lineHeight: 1.5,
  },
  button: {
    textTransform: "none",
    height: 25,
    backgroundColor: "#ffffff",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 12,
    border: '1px solid #dddddd',
    padding: 0,
    margin: 0,
    cursor: "pointer",
    marginRight: 5,
    boxShadow: "1px 1px 1px #AAAAAACC",
  },
  buttonTransparent: {
    minWidth: 30,
    height: 25,
    backgroundColor: "transparent",
    border: "0",
    float: "left",
    padding: 0,
    margin: 0,
    cursor: "pointer",
  },
};

export default function ElementData() {

  const {valGlobal} = useGlobal();
  const [showTR, setShowTR] = useState(null);
  const [showSettings, setShowSettings] = useState(null);
  const [showButtons, setShowButtons] = useState(null);

  // is|has
  const [isMP, setIsMP] = useState(false);
  const [hasActiveObject, setHasActiveObject] = useState(false);
  const [haveDocument, setHaveDocument] = useState(false);
  // data
  const [typeName, setTypeName] = useState('');
  const [label, setLabel] = useState('Label');
  const [dimentions, setDimentions] = useState('Width x Height');
  const [pattern, setPattern] = useState('');
  const [pallete, setPallete] = useState('');

  const removeitem = () => {
    if(window.confirm('Are you sure?')){
      UtilsCanvas.remove(valGlobal.canvas, valGlobal.canvas.getActiveObjects());
    }
  };

  const openTR = (event) => {
    setShowTR(event.currentTarget);
  };
  const openSettings = (event) => {
    setShowSettings(event.currentTarget);
  };
  const openButtons = (event) => {
    setShowButtons(event.currentTarget);
  };

  const closeAll = () => {
    setShowTR(null);
    setShowSettings(null);
    setShowButtons(null);
  };

  const clearAll = () => {
    setHasActiveObject(false);
    setTypeName('');
    setLabel('Label');
    setDimentions('Width x Height');
    setPattern('');
    setPallete('');
  }

  const changeValGlobal = (event) => {
    if(valGlobal.canvas){
      const gao = valGlobal.canvas.getActiveObject();
      readActiveObject(gao);
      setHasActiveObject( gao ? true : false );
    }else{
      clearAll();
    }
  }

  const changeActiveObject = (event) => {
    return;
    if (event && event.hasOwnProperty("activeObject")) {
      if (event.activeObject) {
        readActiveObject(event.activeObject);
      } else {
        clearAll();
      }
    } else {
      clearAll();
    }
  };

  const readActiveObject = (object) => {
    
    if(!object) {
      clearAll();
      return;
    }

    setIsMP( object.hasOwnProperty('typeName') ? ( object.typeName === 'MP' ? true : false ) : false );

    setTypeName( object.hasOwnProperty('typeName') ? object.typeName : '' );
    setLabel( object.hasOwnProperty('label') ? object.label : 'Label' );
    setDimentions(`${Number(object.width * object.scaleX * PIXEL_TO_MILLIMETER).toFixed(1)} x ${Number(object.height * object.scaleY * PIXEL_TO_MILLIMETER).toFixed(1)} mm`);

    setHaveDocument(false);

    if( 
      !object.hasOwnProperty('document') || 
      !object.document.hasOwnProperty('pattern') || 
      !object.document.pattern.hasOwnProperty('fill') || 
      !object.document.pattern.fill.hasOwnProperty('name') || 
      !object.document.pattern.fill.name ){
      setPattern('');
    } else {
      if(object.document.pattern.fill.name){
        setPattern(`${object.document.pattern.fill.name}`);
        setHaveDocument(true);
      } else {
        setPattern('');
      }
    }

    if( 
      !object.hasOwnProperty('document') || 
      !object.document.hasOwnProperty('pallete') || 
      !object.document.pallete.hasOwnProperty('fill') || 
      !object.document.pallete.fill.hasOwnProperty('name') || 
      !object.document.pallete.fill.name ){
      setPallete('');
    } else {
      const fill = object.document.pallete.fill;
      let data = [];
      if(fill.name){
        data.push(fill.name); 
      }
      if(fill.code){
        data.push(fill.code); 
      }
      if(fill.hex){
        data.push(fill.hex); 
      }
      if(data.length){
        setPallete(data.join(' '));
        setHaveDocument(true);
      }else{
        setPallete('');
      }
    }
    
  }

  const Box = (data) => <div style={styles.box}>{data.children}</div>;

  useEffect(() => {
    changeValGlobal();
  }, [valGlobal]);

  // global event active object
  useEffect(() => {
    GlobalEvents.on("changeActiveObject", changeActiveObject);
    return () => {
      GlobalEvents.off("changeActiveObject", changeActiveObject);
    };
  }, []);

  return (
    <div>
        <React.Fragment>

        {hasActiveObject && (

          <div style={{ float: "left" }}>
              <Tooltip title="Remove item" placement="top">
                <button
                  typebtn="button"
                  style={styles.buttonTransparent}
                  onClick={() => removeitem()}
                >
                  <DeleteIcon style={{ color: "#dc143c", fontSize: 20 }} />
                </button>
              </Tooltip>
            </div>

        )}

          <Box>
            <div title={typeName}>
              {label}
            </div>
          </Box>

          <div style={{ float: 'left', marginRight: 5 }}>
            <CheckBoxIcon style={{ color: "#239B56" }} />
            {/* <CheckBoxOutlineBlankIcon style={{color:'#AAAAAA'}}/>
            <CropIcon style={{ color: "#333333" }} /> */}
          </div>

          <div style={{ float: "left" }}>
            <Button
              aria-controls="element-data"
              aria-haspopup="true"
              onClick={openTR}
              style={{
                ...styles.button,
                padding: 0,
                margin: 0,
                color: "#239B56",
              }}
            >
              Report
            </Button>
            <Menu
              id="element-data"
              anchorEl={showTR}
              keepMounted
              open={Boolean(showTR)}
              onClose={closeAll}
              style={{ padding: 0, margin: 0 }}
            >
              <div
                style={{ padding: "5px 15px", fontSize: 10, color: "#777777" }}
              >
                Manufacture
              </div>
              <div style={{ padding: "5px 15px" }}>
                <strong>Technical Report</strong>
              </div>
              <div style={{ padding: "5px 15px", fontSize: 14 }}>
                Show element on technical report?
              </div>
              <div style={{ padding: "5px 15px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      // onChange={() => onChange(el.toLowerCase())}
                      fullWidth
                      style={{
                        fontSize: 10,
                        paddingLeft: 20,
                        display: "block",
                        color: "#239B56",
                      }}
                      name="name"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      style={{ fontSize: 14, display: "block" }}
                    >
                      Yes, show it!
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={false}
                      // onChange={() => onChange(el.toLowerCase())}
                      fullWidth
                      style={{
                        fontSize: 10,
                        paddingLeft: 20,
                        display: "block",
                        color: "#ed312e",
                      }}
                      name="name"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      style={{ fontSize: 14, display: "block" }}
                    >
                      No
                    </Typography>
                  }
                />
              </div>
              <div style={{ padding: "5px 15px", fontSize: 14 }}>
                This is a <strong>shape cutter</strong>? <CropIcon style={{ fontSize:14, color:'#6d738f', marginLeft:10, }}/> 
                <span style={{ color:'#aaaaaa', fontSize:11, padding:'10px'}}>(Need MP)</span>
              </div>
              <div style={{ padding: "5px 15px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      // onChange={() => onChange(el.toLowerCase())}
                      fullWidth
                      style={{
                        fontSize: 10,
                        paddingLeft: 20,
                        display: "block",
                        color: "#239B56",
                      }}
                      name="name"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      style={{ fontSize: 14, display: "block" }}
                    >
                      Yes, is a cutter!
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={false}
                      // onChange={() => onChange(el.toLowerCase())}
                      fullWidth
                      style={{
                        fontSize: 10,
                        paddingLeft: 20,
                        display: "block",
                        color: "#ed312e",
                      }}
                      name="name"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      style={{ fontSize: 14, display: "block" }}
                    >
                      No
                    </Typography>
                  }
                />
              </div>
              <div style={{ padding: "5px 15px", fontSize: 14 }}>Label</div>
              <div style={{ padding: "5px 15px", fontSize: 14 }}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  shrink
                  autoComplete="labelname"
                  name="label_name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  style={{ width: 250 }}
                  // onChange={onChange}
                  // value={boardName}
                />
              </div>
              <div style={{ padding: "5px 15px", fontSize: 14 }}>Note</div>
              <div style={{ padding: "5px 15px", fontSize: 14 }}>
                <textArea style={styles.textArea} value="aaa" />
              </div>
              {/* <MenuItem style={{fontSize:13}}>Save</MenuItem> */}
            </Menu>
          </div>
          {hasActiveObject ? (
            <React.Fragment>
              <div style={{ float: "left" }}>
                <Button
                  aria-controls="dimensions"
                  aria-haspopup="true"
                  onClick={hasActiveObject ? openSettings : null}
                  style={{
                    ...styles.button,
                    padding: '0 10px',
                    margin: 0,
                  }}
                >
                  {dimentions}
                </Button>
                <Menu
                  id="dimensions"
                  anchorEl={showSettings}
                  keepMounted
                  open={Boolean(showSettings)}
                  onClose={closeAll}
                  style={{ padding: 0, margin: 0 }}
                >
                  <div
                    style={{ padding: "5px 15px", fontSize: 10, color: "#777777" }}
                  >
                    Presentation
                  </div>
                  {/* <div style={{ padding: "5px 15px" }}>
                    <strong>Settings</strong>
                  </div> */}
                  <div style={{ padding: "5px 15px" }}>
                    <ElementOpacity />
                  </div>
                  <Divider/>
                  <div style={{ padding: "5px 15px 15px 15px" }}>
                    <ElementDimension />
                  </div>
                  {/* <Divider/>
                  <div style={{ padding: "15px 15px 5px 15px", maxWidth:200 }}>
                    <BoxDropShadow />
                  </div> */}
                </Menu>
              </div>
              <div style={{ float: "left"}}>
                <Button
                  aria-controls="dimensions"
                  aria-haspopup="true"
                  onClick={hasActiveObject ? openButtons : null}
                  style={{
                    ...styles.button,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  Element
                </Button>
                <Menu
                  id="dimensions"
                  anchorEl={showButtons}
                  keepMounted
                  open={Boolean(showButtons)}
                  onClose={closeAll}
                  style={{ padding: 0, margin: 0 }}
                >
                  <div style={{ padding: "5px 15px", fontSize: 10, color: "#777777" }}>
                    Elements
                  </div>
                  <div style={{ padding: '5px 5px 25px 5px' }}>
                    {[<MenuAlign/>,<MenuOrder/>,<MenuRotate/>,<MenuClipboard/>,<MenuFlipAndFit/>].map( (el,index) => (
                      <div style={{padding:10, clear: 'both'}} key={index}>
                        {el}
                      </div>
                    ))}
                  </div>
                </Menu>
              </div>
              {haveDocument && (
                <Box>
                  {`${pattern} ${pallete}`}
                </Box>
              )}
            </React.Fragment>
          ):(
            <React.Fragment>
              <Box>Raw Material (MP)</Box>
              {haveDocument && (
                <Box>
                  {`${pattern} ${pallete}`}
                </Box>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
    </div>
  );
}
