import React from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import MenuBar from "../../layouts/dashboard/MenuBar";
import ViewCalculate from './formulary/View';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    marginBottom: 100,
  },
}));

export default function Calculator( props ) {

  const classes = useStyles();

  return (
    <React.Fragment>  
      <MenuBar/>
      <Container component="main" maxWidth="md">
        <Grid container className={classes.paper} >
          <Grid item xs={12}>
            <ViewCalculate/>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );

};
