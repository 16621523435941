import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Link as LinkBread,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Delete,
  Edit,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {Link, Redirect, useLocation} from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import BoardsUpdate from "../formulary/Update";
import BoardsCreate from "../formulary/Create";
import AddIcon from '@material-ui/icons/Add';
import ApiServer from "../../../../resources/conn/ApiServer";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import ModalDialog from "../../../../resources/component/general/modal/ModalDialog";
import If from "../../../../resources/utils/If";

const useStyles = makeStyles((theme) => ({
  breadcrumbsBar: {
    padding: 15,
  },
  card: {
    maxWidth: 500,
    backgroundColor: '#fc4600',
  },
  cardText: {
    color: '#ffffff',
  },
  media: {
    height: 180,
    backgroundColor: '#333333',
  },
}));

export default function BoardsListSimple(props) {

  const classes = useStyles();
  const getLocation = useLocation();
  const {enqueueSnackbar} = useSnackbar();
  
  const [openEdit, setOpenEdit] = useState(false);
  const [openNew, setOpenNew] = useState(false);

  const [id, setId] = useState(null);
  const [clientId, setClientId] = useState(0);
  const [collectionId, setCollectionId] = useState(0);
  
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [filter, setFilter] = useState('');

  const setLocation = () => {
    // get params last link
    const values = getLocation;
    if( values ){
      if( values.hasOwnProperty('collection_id') ){
        setCollectionId( values.collection_id );
      }
      if( values.hasOwnProperty('client_id') ){
        setClientId( values.client_id );
      }
      return values;
    }
    return null;
  }

  const listage = () => {

    const values = setLocation();;
    let colId = values.collection_id;
    
    new ApiServer()
      .get("boards", `get-collection/${colId}`)
      .then((e) => {
        if (e.statusText === "OK" && e.data !== undefined ) {
          setList(e.data);
          setListFilter(e.data);
        }else{
          setList([]);
          setListFilter([]);
        }
      })
      .catch((err) => {
        console.log(err.statusText, err);
      });
  };

  const onNew = () => {
    setOpenNew( true ); 
  };

  const onEdit = ( event, { id }) => {
    event.preventDefault();
    event.stopPropagation();
    setId( id );
    setOpenEdit( true ); 
  };

  const onDelete = ( event, {id}) => {

    event.preventDefault();
    event.stopPropagation();

    if(!window.confirm('Delete item?')) return;
    new ApiServer().delete( 'boards', `delete/${id}` ).then(( res )=>{
      console.log( res );
      if( res.statusText === 'OK' ){
        listage();
        enqueueSnackbar('Deleted',{variant:'success'});
      }
    }).catch((err)=>{
      enqueueSnackbar('Error',{variant:'error'});
    });
  };

  const onChangeFilter = ( filter ) => {
    if( !filter ) setListFilter( list ); 
    setListFilter(
      list.filter((el) => String(el.board_name).toLowerCase().indexOf( String(filter).toLowerCase() ) > -1 )
    );
  };

  useEffect(() => {
    listage();
    GlobalEvents.on('updateBoards',listage);
    return ()=>{
      GlobalEvents.off('updateBoards',listage);
    }
  }, []);

  return (
    <React.Fragment>
      <Container component="main">

        <Grid container style={{padding:15}}>
          <Grid item xs={6}>
            <Breadcrumbs aria-label="breadcrumb" style={{margin:5}}>
              <LinkBread color="inherit" href="/">
                Home
              </LinkBread>
              <LinkBread color="inherit" href="/collections">
                Collections Book
              </LinkBread>
              {/* <LinkBread color="inherit" href="javascript:;" onClick={()=>window.history.go(-1)}>
                Collections2
              </LinkBread> */}
              <Typography color="textPrimary">
                Boards
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid xs={6} container justify="flex-end">
            <IconButton aria-label="add" onClick={()=>onNew()} title="Add">
              <AddIcon fontSize="large" />
            </IconButton>
            {/* <Button color="primary" variant="outlined">
              Add Collection
            </Button> */}
          </Grid>
          <Grid item xs={12} style={{marginTop:5}}>
            <TextField 
              InputLabelProps={{ shrink: true }} shrink
              autoComplete="boardfilter"
              name="board_filter"
              variant="outlined"
              fullWidth
              label="Search"
              size="small"
              onChange={(event)=>{ setFilter(event.target.value); onChangeFilter(event.target.value) }}
              value={filter}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{padding:15}}>
          {listFilter.map((el) => (
            <Grid item xs={12} sm={6} md={4}>
              <Link
                to={{
                  pathname: `/collection/board/${el.id}`,
                  client_id: `${el.client_id}`,
                  collection_id: `${el.collection_id}`,
                  board_id: `${el.id}`,
                }}
                style={{ textDecoration: 'none' }}
              >
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={process.env.REACT_APP_IMAGE_HOST && el.file_name? process.env.REACT_APP_IMAGE_HOST + '200/' + el.file_name : '/assets/images/image-not-found.png'}
                      title={el.board_name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2" className={classes.cardText}>
                        {el.board_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                        Cores: 
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  {/* <CardActions>

                    <IconButton aria-label="edit" onClick={(e)=>onEdit(e, el)} title="Edit">
                      <Edit />
                    </IconButton>

                    <IconButton edge="end" aria-label="delete" onClick={(e)=>onDelete(e, el)} title="Delete">
                      <Delete />
                    </IconButton>

                    <Button size="small" color="primary">
                      Edit
                    </Button>
                    <Button size="small" color="primary">
                      Delete
                    </Button>

                  </CardActions> */}
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>

        <List dense={true}>
          {listFilter.map((el) => (
            <Link
              to={{
                pathname: `/collection/board/${el.id}`,
                client_id: `${el.client_id}`,
                collection_id: `${el.collection_id}`,
                board_id: `${el.id}`,
              }}
              style={{ textDecoration: 'none' }}
            >
            <ListItem style={{marginLeft:parseInt(20*el.depth)}} key={el.id} button>
              <ListItemAvatar>
                <img src={process.env.REACT_APP_IMAGE_HOST && el.file_name? process.env.REACT_APP_IMAGE_HOST + '100/' + el.file_name : '/assets/images/image-not-found.png'} 
                alt="Board" style={{maxWidth:'50px', maxHeight:'50px', borderRadius:'7px', marginRight:'20px'}}/>
              </ListItemAvatar>
              <ListItemText
                primary={el.board_name}
                // secondary={`year: ${el.year || ''}`}
              />
              <ListItemSecondaryAction>

                <IconButton edge="startX" aria-label="edit" onClick={(e)=>onEdit(e, el)} title="Edit">
                  <Edit />
                </IconButton>

                <IconButton edge="end" aria-label="delete" onClick={(e)=>onDelete(e, el)} title="Delete">
                  <Delete />
                </IconButton>

              </ListItemSecondaryAction>
            </ListItem>
          </Link>
          ))}
        </List>

        <ModalDialog open={openEdit} setOpen={(v)=>setOpenEdit(v)} title='Update'>
          <BoardsUpdate data={{id:id}} setOpen={(v)=>{ if(v === false) setOpenEdit(v) }}/>
        </ModalDialog>

        <ModalDialog open={openNew} setOpen={(v)=>setOpenNew(v)} title='Add'>
          <BoardsCreate data={{collection_id:collectionId,client_id:clientId}} setOpen={(v)=>{ if(v === false) setOpenNew(v) }}/>
        </ModalDialog>

        {/* <Nestable 
            collapsed={false}
            items={processTree} 
            renderItem={renderItem2} 
            // ref={el => this.refNestable = el}        
        /> */}

        <If value={ !collectionId && !getLocation.collection_id }>
          <Redirect push to={{pathname:'/collections'}}/>
        </If>

      </Container>
    </React.Fragment>
  );
}
