import React from 'react';
import BoxPanel from '../../../../resources/component/general/views/BoxPanel';
import { useGlobal } from '../../../../resources/setting/global';
import UtilsCanvas from '../../../../resources/utils/UtilsCanvas';
import UtilsObject from '../../../../resources/utils/UtilsObject';

export default function PanelText() {

  const {valGlobal} = useGlobal();
  const fontsFamily = [
    '',
    'Times New Roman',
    'Courier',
    'Courier New',
    'Arial',
    'Arial Narrow',
    'Helvetica',
    'Verdana',
    'Candara',
    'Lucida Bright',
    'Trebuchet MS',
  ]
  
  const onChangeFontFamily = (e) => {
    const c = valGlobal.canvas;
    UtilsObject.getActive( c ).then( object => {
      UtilsCanvas.set( c, {
        object: object,
        fontFamily: e.target.value,
      });
    });
  }
  
  return (
    <div style={{padding:10}}>
      <BoxPanel title="Paragraph">
        <label>Font family</label>
        <select onChange={onChangeFontFamily} style={{width:'100%'}}>
          {fontsFamily.map( el => (
            <option value={el}>{el}</option>
          ))}
        </select>
      </BoxPanel>
    </div>
  );
}
