import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

export default class ModalDialog extends Component {

  state = {
    open: false,
    title: 'Dialog',
    descriptionElementRef: null,
  }

  handleClose = () => {
    this.setState({ ...this.state, open: false });
    if( this.props.hasOwnProperty('setOpen') ){
      this.props.setOpen(false);
    }
  };

  componentDidMount(){
    if( this.descriptionElementRef !== null )
      this.descriptionElementRef.focus(); 
  }

  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      this.setState({ ...this.state, open: this.props.open });
    }
    if (this.props.title !== this.state.title) {
      this.setState({ ...this.state, title: this.props.title });
    }
    console.log(this.props);
  }

  descriptionElementRef = null;

  render() {

    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{this.state.title || 'Dialog'}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={(input) => this.descriptionElementRef = input }
            tabIndex={-1}
          >
            {this.props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
        </Button>
          {/* <Button onClick={handleClose} color="primary">
          Subscribe
        </Button> */}
        </DialogActions>
      </Dialog>
    )
  }
}
