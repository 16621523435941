import React from "react";
import { Tooltip } from "@material-ui/core";
import { useGlobal } from "../../../../resources/setting/global";
import UtilsObject from "../../../../resources/utils/UtilsObject";
import UtilsCanvas from "../../../../resources/utils/UtilsCanvas";

// icons
import copy from './../../../../assets/fontawesome/svgs/regular/copy.svg';
import paste from './../../../../assets/fontawesome/svgs/regular/paste.svg';
import clone from './../../../../assets/personalSVG/clone.svg';
import group from './../../../../assets/personalSVG/object-group.svg';
import ungroup from './../../../../assets/personalSVG/object-ungroup.svg';
import Exports from "../../../../resources/fabricjs/Exports";

const style = {
  button: {
    minWidth: 30,
    height: 25,
    backgroundColor: "transparent",
    border: "0",
    float: "left",
    padding: 0,
    margin: 0,
		cursor: 'pointer',
  },
  icon: {
    height: 17,
    color: "#777777",
    fill: "#777777",
  },
  division: {
    marginLeft: 20,
  },
};

export default function MenuClipboard(props) {

  const exp = new Exports();
  const { valGlobal } = useGlobal();

  const setCopy = () => {
  };
  
  // https://htmldom.dev/paste-an-image-from-the-clipboard/
  const setPaste = () => {
  };

  const setClone = () => {
    UtilsObject.getActive( valGlobal.canvas ).then( object => {

      object.clone( cloned => {
        valGlobal.canvas.add(cloned);
        cloned.set({
          key: UtilsCanvas.key(),
          left: cloned.left + 10,
          top: cloned.top + 10,
        });
        cloned.setCoords();
        valGlobal.canvas.setActiveObject(cloned);
        //valGlobal.canvas.requestRenderAll();
      },
      exp.getProperties());
  
    });
  }

  const setGroup = () => {

    const c = valGlobal.canvas;
    const objects = c.getActiveObjects();
    const {left, top} = objects;

    if (!c.getActiveObject()) {
      return;
    }
    if (c.getActiveObject().type !== 'activeSelection') {
      return;
    }
    c.getActiveObject().toGroup().set('typeName','SVG');
    c.requestRenderAll();

  };

  const setUngroup = () => {

    const c = valGlobal.canvas;

    if (!c.getActiveObject()) {
      return;
    }
    if (c.getActiveObject().type !== 'group') {
      return;
    }
    c.getActiveObject().toActiveSelection();

    // set typeName
    c.getActiveObjects().map( el => {
      if( !el.hasOwnProperty('typeName') ){
        let type = el.get('type');
        type = type.charAt(0).toUpperCase() + type.slice(1);
        el.set({ typeName:type, key:UtilsCanvas.key() });
      }
      return el; 
    });

    c.requestRenderAll();

    return;

    UtilsObject.getActive( c ).then( object => {

      console.log(object.get('type'));
      const t = object.get('type'); // fabric
      const n = object.typeName; // avenuz
      if( t === 'group' || n === 'SVG' || n === 'Group' ){

        // 2 or more object
        if( object.hasOwnProperty('_objects') === false || object._objects.length < 2 ) return;

        object._objects.forEach( element => {
          c.add(element);          
        });
        object.destroy();
        //c.renderAll();
    
      }

    });
  
  };
  
  return (
    <React.Fragment>
      <Tooltip title="Copy" placement="top">
        <button type="button" style={{...style.button,opacity:0.5}} onClick={setCopy}>
					<img src={copy} style={style.icon}/>
				</button>
      </Tooltip>
      <Tooltip title="Paste" placement="top">
				<button type="button" style={{...style.button,opacity:0.5}} onClick={setPaste}>
					<img src={paste} style={style.icon}/>
				</button>
      </Tooltip>
      <Tooltip title="Clone object" placement="top">
				<button type="button" style={{...style.button}} onClick={setClone}>
					<img src={clone} style={style.icon}/>
				</button>
      </Tooltip>
      <Tooltip title="Group (Objects)" placement="top">
				<button type="button" style={{...style.button}} onClick={setGroup}>
					<img src={group} style={style.icon}/>
				</button>
      </Tooltip>
      <Tooltip title="Ungroup (Objects)" placement="top">
				<button type="button" style={{...style.button}} onClick={setUngroup}>
					<img src={ungroup} style={style.icon}/>
				</button>
      </Tooltip>
    </React.Fragment>
  );
}
