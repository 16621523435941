import React, { useState} from "react";
import { fabric } from 'fabric';
import { Tooltip } from "@material-ui/core";
import { useGlobal } from "../../../../resources/setting/global";
import Shapes from "./../../../../resources/fabricjs/Shapes";
import Paragraph from "./../../../../resources/fabricjs/Paragraph";

// icon
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CropSquareIcon from "@material-ui/icons/CropSquare";
import Crop75Icon from "@material-ui/icons/Crop75";
import RemoveIcon from "@material-ui/icons/Remove";
import PanToolIcon from "@material-ui/icons/PanTool";
import TextRotationNoneIcon from '@material-ui/icons/TextRotationNone';
import FormatTextdirectionLToRIcon from '@material-ui/icons/FormatTextdirectionLToR';
import CreateIcon from '@material-ui/icons/Create';
import shapeOval from "./../../../../assets/personalSVG/shape-oval.svg";
import shapeRectFull from "./../../../../assets/personalSVG/shape-rect-full.svg";
import UtilsCanvas from "../../../../resources/utils/UtilsCanvas";

const styles = {
  tool: {
    zIndex: 10,
    position: 'absolute', 
    float: 'left',
    margin: 10, 
  },
  button: {
    float: 'left',
    width: 50, 
    height: 50, 
    borderRadius: 7, 
    backgroundColor: '#e9e9e9', 
    border: 'none', 
    cursor: 'pointer', 
    marginRight: 1,
  },
  buttonHover: {
    backgroundColor: '#ffffff', 
  }
};

const MenuToolBig = () => {

  const {valGlobal, setGlobal} = useGlobal();
  const [tool, setTool] = useState(null);
  const [buttonHover, setButtonHover] = useState({});
  
  const changeTool = (name) => {
    setTool(name);
    setGlobal({
      ...valGlobal,
      working: {
        ...valGlobal.working,
        tool: {
            ...valGlobal.working.tool,
            current: name,
        },
      },
    });
  };

  const hand = () => {
    changeTool(null);
  };

  const offPen = () => {
    setTimeout(()=>{
      valGlobal.canvas.isDrawingMode = false;
      valGlobal.canvas.off('mouse:up', offPen);  
    },300)
  };

  const drawPen = () => {
    changeTool("pen");
    valGlobal.canvas.isDrawingMode = !valGlobal.canvas.isDrawingMode;
    valGlobal.canvas.on('mouse:up', offPen);
  };

  const drawLine = () => {
    changeTool("line");
    new Shapes().drawLine(valGlobal.canvas);
  };

  const drawCircle = () => {
    changeTool("circle");
    new Shapes().drawCircle(valGlobal.canvas);
  };

  const drawEllipse = () => {
    changeTool("ellipse");
    new Shapes().drawEllipse(valGlobal.canvas);
  };

  const drawRect = () => {
    changeTool("rect");
    new Shapes().drawRect(valGlobal.canvas);
  };

  const drawSquare = () => {
    changeTool("square");
    new Shapes().drawSquare(valGlobal.canvas);
  };

  const addRect = () => {
    changeTool("square");
    const {width, height} = valGlobal.canvas.clipPath;
    new Shapes().addRectangle(valGlobal.canvas, { radius: 0, left: 0, top: 0, borderWidth: 0, opacity: 1, width: width, height: height });
    // const {width, height, top, left} = valGlobal.canvas.clipPath;
    // new Shapes().addRectangle(valGlobal.canvas, { radius:0, left:left, top:top, borderWidth:0, opacity:1, width:width, height:height, relativePosition:false });
  };

  // const addLabel = () => {
  //   changeTool("label");
  //   new Paragraph().addLabel(valGlobal.canvas,{
  //     text: 'Textasd uasu dasyduiay'
  //   });
  // };

  const addText = () => {
    changeTool("text");
    new Paragraph().addTex(valGlobal.canvas,{
      text: 'Lorem ipsum dolor sit amet.',
      fontSize: 30,
    });
  };

  const addParagraphy = () => {
    changeTool("paragraph");
    new Paragraph().addParagraph(valGlobal.canvas,{
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      fontSize: 30,
      width: 400,
      // text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id laoreet metus, vel pharetra dolor. Suspendisse sagittis commodo leo vel dignissim. Curabitur tempus scelerisque tempus. Ut lacinia magna et mattis congue. Donec semper ut nisi sit amet placerat. Sed dictum luctus tortor vel lacinia. Donec dignissim tellus aliquet, finibus enim vitae, fringilla lacus. Vivamus euismod mollis urna, eget accumsan eros aliquet eget. Nunc velit justo, sodales quis placerat quis, ullamcorper tempus massa. Duis tristique mauris eget augue luctus venenatis. Nam accumsan viverra arcu, nec fermentum tortor bibendum vitae. Sed mauris turpis, maximus at mollis quis, tristique laoreet justo. Cras auctor aliquet neque, vitae molestie nisl volutpat vitae. Fusce egestas nulla sit amet placerat pharetra.',
      // fontSize: 10,
      // width: 400,
    });
  };

  // const offPen = () => {
  //   setTimeout(()=>{
  //     valGlobal.canvas.isDrawingMode = false;
  //     valGlobal.canvas.off('mouse:up', offPen);  
  //   },300)
  // };

  // const drawPen = () => {
  //   changeTool("pen");
  //   valGlobal.canvas.isDrawingMode = !valGlobal.canvas.isDrawingMode;
  //   valGlobal.canvas.on('mouse:up', offPen);
  // };


  const addTextDraw = () => {

    changeTool("textdraw");
    // fabric.Object.prototype.objectCaching = false;

    const c = valGlobal.canvas;

    c.isDrawingMode = true;
    //c.freeDrawingBrush =  new fabric.PencilBrush({ decimate: 8 });

    const bpc = (opt) => {
      var path = opt.path;
      var pathInfo = fabric.util.getPathSegmentsInfo(path.path);
      path.segmentsInfo = pathInfo;
      //console.log(path.path, pathInfo);
      var pathLength = pathInfo[pathInfo.length - 1].length;
      var text = 'This text should be small enough to fit in drawn.';
      var fontSize = 2.5 * pathLength / text.length;
      var textDraw = new fabric.Text(text, { fontSize: fontSize, path: path, top: path.top, left: path.left });
      // only to storage draw
      textDraw.path = opt.path;
      textDraw.typeName = 'Path';
      c.add(textDraw);
    }

    const pc = (opt) => {
      c.remove(opt.path);
      c.isDrawingMode = false;
      c.off('before:path:created', bpc);
      c.off('path:created', pc);  
    }
  
    c.on('before:path:created', bpc);
    c.on('path:created', pc);

  }

  const addRectExtends = () => {

    // fabric.LabeledRect = fabric.util.createClass(fabric.Rect, {

    //   type: 'labeledRect', // tmp|test
    //   widthShadow: 100,

    //   // initialize can be of type function(options) or function(property, options), like for text.
    //   // no other signatures allowed.
    //   initialize: function(options) {
    //     options || (options = { });
    
    //     this.callSuper('initialize', options);
    //     this.set('label', options.label || '');
    //     this.set('widthShadow', options.widthShadow || this.widthShadow);
    //     this.set('typeName', 'LabeledRect'); // tmp|test

    //     this.set({ width: this.widthShadow, height: 300});

    //     // // give all labeled rectangles fixed width/heigh of 100/50
    //     // this.set({ width: 100, height: 50 });
    //   },

    //   // drawObject: function(ctx) {
    //   //   this._renderBackground(ctx);
    //   //   this._setStrokeStyles(ctx, this);
    //   //   this._setFillStyles(ctx, this);
    //   //   this._render(ctx); // <--- crashes here
    //   // },
    
    //   toObject: function() {
    //     return fabric.util.object.extend(this.callSuper('toObject'), {
    //       label: this.get('label'),
    //       widthShadow: this.get('widthShadow'),
    //     });
    //   },
    
    //   _render: function(ctx) {        
    //     this.callSuper('_render', ctx);

    //     // reset the global comp and draw a partial arc with the proper radians.
    //     // ctx.globalCompositeOperation = "source-over";
    //     // ctx.save();
    //     // ctx.restore();

    //     // ctx.globalCompositeOperation='source-atop';
    //     // ctx.globalCompositeOperation='source-over';
    //     ctx.globalCompositeOperation = "source-over";

    //     // ctx.fillRect(-this.width/2 + 5, -this.height/2 + 5, this.width-10, this.height-10 );
    //     // ctx.fillStyle = '#333333';
    //     // ctx.fill();

    //     ctx.shadowColor = '#EEEEEE'; //'rgb(10,10,10,.5)';
    //     ctx.shadowBlur = 5;
    //     ctx.shadowOffsetX = -6;
    //     ctx.shadowOffsetY = -6;

    //     ctx.globalCompositeOperation = "source-over";

    //     // Rectangle
    //     // ctx.fillStyle = '#333333';
    //     // ctx.fillRect(-this.width/2 + 5, -this.height/2 + 5, this.width-10, this.height-10 );
    //     // https://stackoverflow.com/questions/60785036/quartz-2d-implementing-html5-canvas-globalcompositeoperation
    //     // https://www.w3schools.com/tags/canvas_globalcompositeoperation.asp

    //     // var data = this.toDataURL({
    //     //   enableRetinaScaling: true,
    //     //   format: "png", // png | jpg
    //     //   // quality: 1, // only jpg
    //     //   multiplier: 1, // scale, default 1
    //     // });
    
    //     // const backFillColor = '#000000';
    //     // var img = document.createElement('img');
    //     // img.onload = function(){ createANewCanva( this, backFillColor ) };
    //     // img.src = data;
      
    //     //this.render();
    //     this._createInnerShadow( ctx );

    //   },
    //   _createInnerShadow: function(ctx) {
    //     // this.drawCacheOnCanvas(ctx);
    //     // this.drawObject(ctx);
    //     //this.fire('render:context');
    //     // ctx.fillStyle = 'rgb(255,255,255,.1)'
    //     // ctx.fillRect(-this.width/2 + 5, -this.height/2 + 5, this.width-10, this.height-10 );
    //   },
    //   createANewCanva: function( img, backFillColor) {
          
    //     var _alpha = .5;
    //     var _color = backFillColor;
    //     var distance = 10 / 2;
    //     var _offsetX = 2; // 0 - 2
    //     var _offsetY = 2; // 0 - 2
    
    //       // the size of the shadow depends on the size of the target,
    //       // then I will create extra "walls" around the picture to be sure
    //       // tbat the shadow will be correctly filled (with the same intensity everywhere)
    //       // (it's not obvious with this image, but it is when there is no space at all between the image and its border)
    //       var _offset = 50 + distance;
    //       var hole = document.createElement("canvas");
    //       var holeContext = hole.getContext("2d");
    //       hole.width = img.width + _offset*2;
    //       hole.height = img.height + _offset*2;
    
    //       // first, I draw a big black rect
    //       holeContext.fillStyle = "#000000";
    //       holeContext.fillRect(0,0,hole.width,hole.height);
          
    //       // then I use the image to make an hole in it
    //       holeContext.globalCompositeOperation = "destination-out";
    //       holeContext.drawImage(img,_offset,_offset);
          
    //       // I create a new canvas that will contains the shadow of the hole only
    //       var shadow = document.createElement("canvas");
    //       var shadowContext = shadow.getContext("2d");
    //       shadow.width = img.width;
    //       shadow.height = img.height;
    //       shadowContext.filter = "drop-shadow("+_offsetX+"px "+_offsetY+"px "+distance+"px "+_color+" ) ";
    //       shadowContext.drawImage(hole,-_offset,-_offset);
    //       shadowContext.globalCompositeOperation = "destination-out";
    //       shadowContext.drawImage(hole,-_offset,-_offset);
                
    //       // now, because the default-shadow filter is really to soft, I normalize the shadow 
    //       // then I will be sure that the alpha-gradient of the shadow will start at "shadowAlpha" and end at 0
    //       // normalizeAlphaShadow(shadow,_alpha);
          
    //       // // Finally, I create another canvas that will contain the image and the shadow over it
    //       var result = document.createElement("canvas");
    //       result.width = this.width;
    //       result.height = this.height;
    //       var context = result.getContext("2d");
    //       context.drawImage(img,0,0)
    //       context.drawImage(shadow,0,0);
    
    //   },
    // });
    
    // var lr = new fabric.LabeledRect({
    //   width: 200,
    //   height: 200,
    //   left: 100,
    //   top: 100,
    //   label: 'Testando Texto',
    //   fill: '#CCC',
    //   dirty: true,
    // });
    // lr.on('render:context',function(){
    //   //alert('render:context');
    // })

    // UtilsCanvas.add( valGlobal.canvas, lr );

    // // In order for this class to be cloned and saved/restore, you need to add a `fromObject` static method 
    // // standard options type:
    // fabric.LabeledRect.fromObject = function(object, callback) {
    //   // return fabric.Object._fromObject('LabeledRect', object, callback);
    //   var obj = new fabric.LabeledRect(object);
    //   callback && callback(obj);
    //   return obj;
    // };
  }

  const list = [
    {label: 'Hand',               name: 'hand', fn: hand ,              icon: <PanToolIcon style={{ width: 17 }}/>, tip: '' },
    {label: 'Draw Pen',           name: 'pen', fn: drawPen ,            icon: <CreateIcon style={{ width: 20 }}/>, tip: '' },
    {label: 'Draw Line',          name: 'line', fn: drawLine ,          icon: <RemoveIcon/>, tip: 'Press "Shift" key to horizontal line, "Alt" key to vertical line.'},
    {label: 'Draw Ellipse',       name: 'ellipse', fn: drawEllipse ,    icon: <img src={shapeOval} style={{ maxHeight: 16 }}/>, tip: '' },
    {label: 'Draw Circle',        name: 'circle', fn: drawCircle ,      icon: <RadioButtonUncheckedIcon />, tip: '' },
    {label: 'Draw Rect',          name: 'rect', fn: drawRect ,          icon: <Crop75Icon />, tip: '' },
    {label: 'Draw Square',        name: 'square', fn: drawSquare ,      icon: <CropSquareIcon />, tip: '' },
    {label: 'Add Rect Full Size', name: 'rectfull', fn: addRect ,       icon: <img src={shapeRectFull} style={{ maxHeight: 17 }}/>, tip: '' },
    {label: 'Add Label',          name: 'label', fn: addText ,           icon: <TextRotationNoneIcon />, tip: '' },
    // {label: 'Add Label',          name: 'label', fn: addLabel ,         icon: <TextRotationNoneIcon />, tip: '' },
    {label: 'Add Paragraph',      name: 'paragraph', fn: addParagraphy ,icon: <FormatTextdirectionLToRIcon />, tip: '' },
    {label: 'Add TextDraw',      name: 'paragraph', fn: addTextDraw ,icon: <FormatTextdirectionLToRIcon />, tip: '' },
    
    {label: 'Draw Rect',          name: 'rectExtends', fn: addRectExtends ,          icon: <Crop75Icon />, tip: '' },
    
  ];

  return (
    <div style={styles.tool}>
      {list.map((el)=>(
        <Tooltip title={`${el.label} ${el.tip}`} placement="bottom">
          <button style={styles.button} key={el.label} onClick={()=>el.fn()} >
            {el.icon}
          </button>
        </Tooltip>
      ))}
    </div>
  );
};
export default MenuToolBig;