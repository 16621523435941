import React, { useEffect, useState } from "react";
import GlobalEvents from "../../../event/GlobalEvents";
import { useGlobal } from "../../../setting/global";
import UtilsCanvas from "../../../utils/UtilsCanvas";
import UtilsObject from "../../../utils/UtilsObject";
import SliderInput from "../../general/inputs/SliderInput";

export default function ElementOpacity() {

  const {valGlobal} = useGlobal();
  const [opacity, setOpacity] = useState(1);

  const updateValues = () => {
    const c = valGlobal.canvas;
    UtilsObject.getActive( c ).then( object => {
      if(object.typeName === 'Bend') return;
      object.set({opacity: opacity});
      UtilsCanvas.renderAll( c );
      c.history.register();
    }).catch(clearAll);
  }

  const clearAll = () => {
    setOpacity(1);
  }

  const setValue = ( event ) => {
    const c = valGlobal.canvas;
    UtilsObject.getActive( c, event && event.activeObject ).then( object => {
      if( object.hasOwnProperty('opacity')){
        const { opacity } = object;
        setOpacity( opacity );
      }
    }).catch(clearAll);
  }  

  useEffect(()=>{
    setValue();
    GlobalEvents.on("changeActiveObject", setValue );
    return () => {
      GlobalEvents.off("changeActiveObject", setValue );
    };
  },[]);
  
  return (
    <SliderInput value={opacity} min={0} max={1} step={0.01} default={1} title="Opacity" onChange={(e)=>{ setOpacity(e.value); updateValues(); }} isInt={false} decimal={2}/>
  );
};
