import React, {useState,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import ArrayToTree from "array-to-tree";
import { Button, Menu, MenuItem } from "@material-ui/core";
import BoxPattern from "./boxes/pattern/BoxPattern";
import ApiServer from "../../../../resources/conn/ApiServer";
import UtilsCanvas from "../../../../resources/utils/UtilsCanvas";
import BoxPanel from "../../../../resources/component/general/views/BoxPanel";
import ProcessIcon from "./.././../../../assets/personalSVG/panel-process.svg";

const ITEM_HEIGHT = 100;
const ITEM_WIDTH = 250;

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  button: {
    paddingTop: 6,
    paddingBottom: 4,
    textTransform: "none",
    backgroundColor: '#2a82db',
  },
  menuItem: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		fontSize: 12,
		fontWeight: 500,
  },
});

export default function PanelProcess() {

  const classes = useStyles();
  const [processTree, setProcessTree] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  const listProcess = () => {
    new ApiServer()
      .get("process", "get")
      .then((e) => {
        if (e.statusText === "OK" && e.data !== undefined) {
          console.log(e.datae);
          setProcessTree(ArrayToTree(e.data));
        } else {
          setProcessTree([]);
        }
      })
      .catch((err) => {
        console.log(err.statusText, err);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    UtilsCanvas.focus();
  };

  useEffect(()=>{
    listProcess();
  },[]);

  return (
    <div style={{padding:10}}>
      {/* <BoxPanel title="Process">

        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          size="medium"
          // startIcon={<MenuIcon/>}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          fullWidth
        >
          Process
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              marginTop: 1,
              maxHeight: ITEM_HEIGHT * 4.5,
              width: ITEM_WIDTH,
            },
          }}
        >
          {['Dry-inl','Foil','Dublagem','Laser','Metal','Manual','Pintura','Queima','Relevo'].map((el) => {
            return (
              <MenuItem onClick={() => handleClose(el)} key={el} className={classes.menuItem}>
                <div style={{ width:30 }}>
                  <img src={ProcessIcon} style={{ width:18, marginTop:5 }}/>
                </div>
                {el}
              </MenuItem>
            );
          })}
        </Menu>

        <div style={{paddingTop:20}}>
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {processTree.map((el)=>{ return renderTree(el) })}
          </TreeView>
        </div>

      </BoxPanel> */}

      <BoxPattern/>

    </div>
  );
}
