import React, { useEffect, useState } from "react";
import { useGlobal } from "../../../../../../resources/setting/global";
import Effects from "../../../../../../resources/fabricjs/Effects";
import SliderInput from "../../../../../../resources/component/general/inputs/SliderInput";
import UtilsObject from "../../../../../../resources/utils/UtilsObject";

const styles = {
  controlls: {
    paddingLeft: 10,
    paddingRight: 10,
    display: 'block', 
    // background: '#eeeeee', 
    // borderRadius: 7,
  }
}

const BoxSaturation = (props) => {
  
  // global variables
  const {valGlobal} = useGlobal();
  // filters values
  const [saturation, setSaturation] = useState(0);

  // apply filter
  const apply = ( setting ) => {
    new Effects().Saturation( valGlobal.canvas, props.values.index, setting );
  }

  // update this component
  const updateComponents = () => {

    UtilsObject.getActive( valGlobal.canvas ).then( object => {

      if (object.hasOwnProperty('filters') === false) return;      
      object.filters = object.filters || [];
      object.filters.forEach( el => {
        if(el.name === props.values.name){
          // set values
          setSaturation(props.values.saturation);
        }
      });

    }).catch(()=>{});

  };

  useEffect(() => {
    if(saturation){
      const settings = {
        ...props.values,
        saturation: saturation,
      }
      apply(settings);
    }
  },[saturation]);

  useEffect(() => {    
    updateComponents();
  },[]);

  return (
    <div style={styles.controlls}>
      <SliderInput value={saturation} min={-1} max={2} step={0.01} default={0} onChange={(e)=>setSaturation(e.value)} isInt={false} decimal={2}/>
    </div>
  );
}
export default BoxSaturation;