import React, { useState } from 'react';
import { Button, Grid, TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useGlobal } from '../../../../resources/setting/global';
import ClipPath from '../../../../resources/fabricjs/ClipPath';
import BoxPatternMP from './../panels/boxes/pattern/BoxPatternMP';
import BoxPanel from '../../../../resources/component/general/views/BoxPanel';

const PIXEL_TO_MILLIMETER = 0.2645833333;
const MILLIMETER_TO_PIXEL = 3.7795275591;

const useStyles = makeStyles((theme) => ({
  button: {
    paddingTop: 6,
    paddingBottom: 4,
    textTransform: "none",
    backgroundColor: '#2a82db',
  },
}));

export default function PanelMaterial() {

  const classes = useStyles();
  const {valGlobal} = useGlobal();
  const [width,setWidth] = useState(700);
  const [height,setHeight] = useState(500);

  const changeWidth = (v) => {
    if( isNaN(v) && v !== '' ) v = 100;
    if(v > 400 ) v = 400; // 1500 px
    setWidth(v);
    const c = valGlobal.canvas;
    if(c === null) return;
    new ClipPath().addRect( c, v * MILLIMETER_TO_PIXEL, height * MILLIMETER_TO_PIXEL );
  }

  const changeHeight = (v) => {
    if( isNaN(v) && v !== '' ) v = 100;
    if(v > 400 ) v = 400; // 1500 px
    setHeight(v);
    const c = valGlobal.canvas;
    if(c === null) return;
    new ClipPath().addRect( c, width * MILLIMETER_TO_PIXEL, v * MILLIMETER_TO_PIXEL );
  }

  React.useEffect(()=>{
    const c = valGlobal.canvas;
    if(c === null) return;
    const { width, height } = new ClipPath().getClipPath( c );
    setWidth( Number(width * PIXEL_TO_MILLIMETER).toFixed(1) );
    setHeight( Number(height * PIXEL_TO_MILLIMETER).toFixed(1) );
  },[]);

  return (
      <div style={{padding:10}}>
                
        <BoxPatternMP/>

        <BoxPanel title="Faca">

          <div style={{width:'100%',height:'100px',borderRadius:7,backgroundColor:'#dddddd'}}>
            <div style={{paddingTop:30,textAlign:'center'}}>
              {/* <span style={{padding:'5px 10px 6px 10px',borderRadius:'7px',backgroundColor:'#ffffff'}}>
                Nome do Material
              </span> */}
              <Button variant="contained" color="primary" disabled className={classes.button}>
                Shape Cutter
              </Button>
            </div>
          </div>

        </BoxPanel>

        <BoxPanel title="Dimensions">

          <Grid container spacing={1} style={{textAlign:'center',alignItems:"center",display:'flex',paddingTop:7}}>
            <Grid item xs={3} style={{textAlign:'left'}}>
              width
            </Grid>
            <Grid item xs={4} >
              <TextField 
                value={width}
                onChange={ (e)=>changeWidth(e.target.value) } 
                variant="outlined" size="small"
                fullWidth
                inputProps={{ 
                  style: {
                    textAlign: 'center',
                    fontSize: 11,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 5,
                    paddingRight: 5,
                  } 
                }} />
            </Grid>
            <Grid item xs={2} style={{textAlign:'center'}}>
              mm
            </Grid>
            <Grid item xs={3} style={{textAlign:'right',color:'#999999', fontSize:10}}>
              {Number(width * MILLIMETER_TO_PIXEL).toFixed(0)} px
            </Grid>
            <Grid item xs={3} style={{textAlign:'left'}}>
              height
            </Grid>
            <Grid item xs={4}>
              <TextField 
                value={height}
                onChange={ (e)=>changeHeight(e.target.value) } 
                variant="outlined" size="small"
                fullWidth
                inputProps={{ 
                  style: {
                    textAlign: 'center',
                    fontSize: 11,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 5,
                    paddingRight: 5,
                  } 
                }} />
            </Grid>
            <Grid item xs={2} style={{textAlign:'center'}}>
              mm
            </Grid>
            <Grid item xs={3} style={{textAlign:'right',color:'#999999', fontSize:10}}>
              {Number(height * MILLIMETER_TO_PIXEL).toFixed(0)} px
            </Grid>
          </Grid>

        </BoxPanel>
    </div>
  );
}
