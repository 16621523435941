import React from 'react';
import { useGlobal } from '../../../../resources/setting/global';
import UtilsCanvas from '../../../../resources/utils/UtilsCanvas';
import BoxPanel from './../../../../resources/component/general/views/BoxPanel';
import { fabric } from "fabric";
import UtilsObject from '../../../../resources/utils/UtilsObject';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    paddingTop: 6,
    paddingBottom: 4,
    textTransform: "none",
    backgroundColor: '#2a82db',
    marginBottom: 5,
  },
}));

export default function PanelFinishing() {

  const classes = useStyles();
  const {valGlobal} = useGlobal();

	// define a function that can locate the controls.
	// this function will be used both for drawing and for interaction.

  function polygonPositionHandler(dim, finalMatrix, fabricObject) {
    const c = valGlobal.canvas;
    if( !fabricObject ) {
      return c.requestRenderAll();
    }
	  var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
		    y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
		return fabric.util.transformPoint(
			{ x: x, y: y },
      fabric.util.multiplyTransformMatrices(
        (fabricObject.canvas ? fabricObject.canvas.viewportTransform : c.viewportTransform) ,
        fabricObject.calcTransformMatrix()
      )
		);
	}

	// define a function that will define what the control does
	// this function will be called on every mouse move after a control has been
	// clicked and is being dragged.
	// The function receive as argument the mouse event, the current trasnform object
	// and the current position in canvas coordinate
	// transform.target is a reference to the current object being transformed,
	function actionHandler(eventData, transform, x, y) {
		var polygon = transform.target,
		    currentControl = polygon.controls[polygon.__corner],
		    mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
        polygonBaseSize = polygon._getNonTransformedDimensions(),
				size = polygon._getTransformedDimensions(0, 0),
				finalPointPosition = {
					x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
					y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
				};
		polygon.points[currentControl.pointIndex] = finalPointPosition;
		return true;
	}

  // define a function that can keep the polygon in the same position when we change its
  // width/height/top/left.
  function anchorWrapper(anchorIndex, fn) {
    return function(eventData, transform, x, y) {
      var fabricObject = transform.target,
          absolutePoint = fabric.util.transformPoint({
              x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
              y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
          }, fabricObject.calcTransformMatrix()),
          actionPerformed = fn(eventData, transform, x, y),
          newDim = fabricObject._setPositionDimensions({}),
          polygonBaseSize = fabricObject._getNonTransformedDimensions(),
          newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
  		    newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
      fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
      return actionPerformed;
    }
  }

	function editBend() {

		// clone what are you copying since you
		// may want copy and paste on different moment.
		// and you do not want the changes happened
		// later to reflect on the copy.

    const c = valGlobal.canvas;
    UtilsObject.getActive( c ).then( object => {

      if( object.typeName !== 'Polygon' && object.typeName !== 'Bend' ) return;
  
      object.edit = !object.edit;

      if (object.edit) {
        var lastControl = object.points.length - 1;
        object.cornerStyle = 'rect';
        object.cornerColor = 'rgba(0,0,0,1)';
        object.controls = object.points.reduce(function(acc, point, index) {
          acc['p' + index] = new fabric.Control({
            positionHandler: polygonPositionHandler,
            actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
            actionName: 'modifyPolygon',
            pointIndex: index
          });
          return acc;
        }, { });
      } else {
        object.cornerColor = 'black';
        object.cornerStyle = 'circle';
        object.controls = fabric.Object.prototype.controls;
      }
      object.hasBorders = !object.edit;
      c.requestRenderAll();
      
    });

	}

  const addBend = () => {

    // create a polygon object
    const points = [
      {x: 0, y: 0}, // 1 - width:height
      {x: 567/2, y: 0}, // 2 - width:height
      {x: 567/2, y: 378 - 10}, // 3 - width:height
      {x: 0, y: 378 - 10}, // 4 - width:height
      // {x: 0, y: 0}, // 1 
    ];

    const c = valGlobal.canvas;
    var polygon = new fabric.Polygon(points, {
      typeName: 'Bend',
      top: UtilsCanvas.setPositionTop( c, 0 ),
      left: UtilsCanvas.setPositionLeft( c, 0 ),
      fill: null,
      strokeWidth: 1,
      stroke: 'black',
      strokeDashArray: [5,5],
      strokeLineCap: 'round',
      scaleX: 1,
      scaleY: 1,
      strokeUniform: true, //scale stroke with object
      objectCaching: false,
      transparentCorners: false,
      cornerColor: 'black',
    });

    UtilsCanvas.add( c, polygon );
  }


// var polygonCount = 1;
// var startDrawingPolygon;
// var ArrayLength;
// var addTexture = false;
// var circleCount = 1;
// var fillColor = "rgba(46, 240, 56, 0.5)";
// var circle = null;

// function done() {
//   const canvas = valGlobal.canvas;

//   startDrawingPolygon = false;
//   ArrayLength = circleCount;
//   circleCount = 1;
//   //var tempCount = 0;
//   var objects = canvas.getObjects();
//   var points = [];
//   for (var i = 0; objects.length > i; i++) {
//     if (objects[i].polygonNo === polygonCount) {
//       points.push({
//         x: objects[i].left,
//         y: objects[i].top
//       });
//       canvas.renderAll();
//     }
//   }
//   console.log(points)
//   window["polygon" + polygonCount] = new fabric.Polygon(points, {
//     fill: fillColor,
//     PolygonNumber: polygonCount,
//     name: "Polygon",
//     selectable: false,
//     noofcircles: ArrayLength,
//     objectCaching: false
//   });
//   canvas.add(window["polygon" + polygonCount]);
//   canvas.sendToBack(window["polygon" + polygonCount])
//   canvas.requestRenderAll();
//   polygonCount++;
// }

// function Addpolygon() {
//   startDrawingPolygon = true;
// }


// useEffect(() => {
  
//   const canvas = valGlobal.canvas;
//   canvas.on('object:moving', function(option) {
//     var object = option.target;
//     canvas.forEachObject(function(obj) {
//       if (obj.name == "Polygon") {
//         if (obj.PolygonNumber == object.polygonNo) {
//           var points = window["polygon" + object.polygonNo].get("points");
//           points[object.circleNo - 1].x = object.left;
//           points[object.circleNo - 1].y = object.top;
//           window["polygon" + object.polygonNo].set({
//             points: points
//           });
//         }
//       }
//     })
//     canvas.renderAll();
//   });
  
//   canvas.on('mouse:down', function(option) {
  
//     if (option.target && option.target.name == "draggableCircle") {
//       return;
//     } else {
//       if (addTexture) {
//         console.log(option);
//       }
//       if (startDrawingPolygon) {
//         var pointer = canvas.getPointer(option.e);
//         circle = new fabric.Circle({
//           left: pointer.x,
//           top: pointer.y,
//           radius: 7,
//           hasBorders: false,
//           hasControls: false,
//           polygonNo: polygonCount,
//           name: "draggableCircle",
//           circleNo: circleCount,
//           fill: "rgba(0, 0, 0, 0.5)",
//           hasRotatingPoint: false,
//           originX: 'center',
//           originY: 'center'
//         });
//         canvas.add(circle);
//         circleCount++;
//       }
//     }
//   });

//   return () => {
//   }
// }, [])

  return (
    <div style={{padding:10}}>
      <BoxPanel title="Bend">
        {/* https://codepen.io/durga598/pen/gXQjdw */}

        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          size="medium"
          // startIcon={<AddIcon/>}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={addBend}
          fullWidth
          >
            Add Bend
        </Button>

        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          size="medium"
          // startIcon={<AddIcon/>}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={editBend}
          fullWidth
          >
            Edit Bend points
        </Button>

        {/* <button onClick={Addpolygon}>Add Polygon Points</button>
        <button onClick={done}>Create Polygon</button> */}

        <br/>
        Quantidade de Dobras
        <br/>

        <select style={{width:'100%'}}>
          <option> Nenhuma</option>
          <option> 1 (Uma) dobra</option>
          <option> 2 (Duas) dobras</option>
          <option> 3 (Três) dobras</option>
        </select>

        <br/>

        Tipo
        <br/>

        <select style={{width:'100%'}}>
          <option> Lateral</option>
          <option> Oblíquoa</option>
        </select>

        <br/>

        Bloquear
        <br/>

      </BoxPanel>

      
    </div>
  );
}
