import React from "react";

// the magic
import Magic from "../../structure/layouts/the-big-fucking-magic/Magic";

// icon
import HomeIcon from '@material-ui/icons/Home';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import { Palette } from "@material-ui/icons";
import ToysIcon from '@material-ui/icons/Toys';
import StarsIcon from '@material-ui/icons/Stars';
import CropIcon from '@material-ui/icons/Crop';
import ProcessIcon from "./../../assets/personalSVG/panel-process.svg";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

// componets
import Home from './../../structure/pages/home/';
import Clients from "../../structure/pages/clients/";
import Brands from "../../structure/pages/brands/";
import Colors from "../../structure/pages/colors/";
import Collections from "../../structure/pages/collections/";
import Boards from "../../structure/pages/boards/";
import Materials from "../../structure/pages/materials/";
import MaterialsIcon from "./../../assets/personalSVG/panel-material.svg";
import Process from "../../structure/pages/process/";
import Calculator from "../../structure/pages/calculator/";

const routers = [
  {
    label: "Home",
    path: "/",
    component: <Home/>,
    hiddenMenu: false,
    icon: <HomeIcon/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Clients",
    path: "/clients",
    component: <Clients/>,
    hiddenMenu: false,
    icon: <StarsIcon/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Brand",
    path: "/client/brand/:id",
    component: <Brands/>,
    hiddenMenu: true,
    icon: <StarsIcon/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Colors",
    path: "/colors",
    component: <Colors/>,
    hiddenMenu: false,
    icon: <Palette/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Collections Book",
    path: "/collections",
    component: <Collections/>,
    hiddenMenu: false,
    icon: <CollectionsBookmarkIcon/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Boards",
    path: "/collection/:id",
    component: <Boards/>,
    hiddenMenu: true,
    icon: <CheckBoxOutlineBlankIcon/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Products Item",
    path: "/collection/board/:id",
    component: <Boards/>,
    hiddenMenu: true,
    icon: <CheckBoxOutlineBlankIcon/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Products",
    path: "/products",
    component: null,
    hiddenMenu: false,
    icon: <CheckBoxOutlineBlankIcon/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Materials",
    path: "/materials",
    component: <Materials/>,
    hiddenMenu: false,
    icon: <img src={MaterialsIcon} style={{width:22}}/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  // {
  //   label: "Material Categories",
  //   path: "/materials-categories",
  //   component: <MaterialCategories/>,
  //   hiddenMenu: false,
  //   icon: <StyleIcon/>,
  //   owner: false, // owner company
  //   admin: false, // authenticate admin
  //   onlyDev: false, // gnnc
  // },
  {
    label: "Process",
    path: "/process",
    component: <Process/>,
    hiddenMenu: false,
    icon: <img src={ProcessIcon} style={{width:22}}/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Calculator",
    path: "/calculator",
    component: <Calculator/>,
    hiddenMenu: false,
    icon: <LocalAtmIcon/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "Shape Cutter",
    path: "/shape-cutter",
    component: null,
    hiddenMenu: false,
    icon: <CropIcon/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
  {
    label: "The Magic",
    path: "/the-magic",
    component: <Magic/>,
    hiddenMenu: false,
    icon: <ToysIcon/>,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
  },
];
export default routers;
