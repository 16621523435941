import React from "react";
import { Tooltip } from "@material-ui/core";
import { useGlobal } from "../../../../resources/setting/global";
import UtilsObject from "../../../../resources/utils/UtilsObject";

// icons
import rotateRight from "./../../../../assets/personalSVG/rotate-right.svg";
import rotateLeft from "./../../../../assets/personalSVG/rotate-left.svg";
import angleRuler from "./../../../../assets/personalSVG/angle-ruler.svg";

const style = {
  button: {
    minWidth: 30,
    height: 25,
    backgroundColor: "transparent",
    border: "0",
    float: "left",
    padding: 0,
    margin: 0,
		cursor: 'pointer',
  },
  icon: {
    height: 17,
    color: "#777777",
    fill: "#777777",
  },
  division: {
    marginLeft: 20,
  },
};

export default function MenuRotate() {

  const { valGlobal } = useGlobal();

  const rotate = ( value ) => {

    switch ( value ) {
      case 'left':
        UtilsObject.rotateLeft( valGlobal.canvas );
      break;
      case 'right':
        UtilsObject.rotateRight( valGlobal.canvas );
      break;
      case 'angleZero':
        UtilsObject.rotate( valGlobal.canvas, undefined, 0 );
      break;
        default: console.log('');
      break;
    }
  };

  return (
    <React.Fragment>
				<Tooltip title="Rotate Left" placement="top">					
					<button type="button" style={style.button} onClick={()=>rotate('left')}>
						<img src={rotateLeft} style={style.icon}/>
					</button>
				</Tooltip>
				<Tooltip title="Rotate Right" placement="top">					
					<button type="button" style={style.button} onClick={()=>rotate('right')}>
						<img src={rotateRight} style={style.icon}/>
					</button>
				</Tooltip>
				<Tooltip title="Angle ⦣ 0°" placement="top">					
					<button type="button" style={style.button} onClick={()=>rotate('angleZero')}>
						<img src={angleRuler} style={style.icon}/>
					</button>
				</Tooltip>
    </React.Fragment>
  );
}
