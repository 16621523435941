import {
  Badge,
  Breadcrumbs,
  Container,
  Grid,
  IconButton,
  Link as LinkBread,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import {
  Delete,
  Edit,
} from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import ApiServer from "../../../../resources/conn/ApiServer";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import ModalDialog from "../../../../resources/component/general/modal/ModalDialog";
import MaterialsUpdate from './../formulary/Update';
import MaterialsCreate from "./../formulary/Create";
import UpdateMaterialProcessRel from "../formulary/UpdateMaterialProcessRel";
import UtilsArray from "../../../../resources/utils/UtilsArray";

// const useStyles = makeStyles((theme) => ({
//   breadcrumbsBar: {
//     padding: 15,
//   },  
// }));

export default function MaterialsListSimple(props) {

  // const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openMaterialProcessRel,setOpenMaterialProcessRel] = useState(false);

  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [filter, setFilter] = useState('');

  const listage = () => {
    new ApiServer()
      .get("materials", "get")
      .then((e) => {
        if (e.statusText === "OK" && e.data !== undefined ) {
          // maybe more fast
          // https://www.npmjs.com/package/performant-array-to-tree
          setList(UtilsArray.sortByHierarchyAndName(e.data));
          setListFilter(UtilsArray.sortByHierarchyAndName(e.data));
          // setProcessTree(ArrayToTree(e.data));
        }else{
          setList([]);
          setListFilter([]);
        }
      })
      .catch((err) => {
        console.log(err.statusText, err);
      });
  };

  const onNew = () => {
    setOpenNew( true ); 
  };

  const onEdit = ( event, { id } ) => {
    event.preventDefault();
    event.stopPropagation();
    setId( id );
    setOpenEdit( true ); 
  };

  const onRelProcess = ( event, { id, name } ) => {
    event.preventDefault();
    event.stopPropagation();
    setId( id );
    setName( name );
    setOpenMaterialProcessRel( true ); 
  };

  const onDelete = ( event, { id } ) => {
    if(!window.confirm('Delete item?')) return;
    new ApiServer().delete( 'materials', `delete/${id}` ).then(( res )=>{
      console.log( res );
      if( res.statusText === 'OK' ){
        listage();
        enqueueSnackbar('Deleted',{variant:'success'});
      }
    }).catch((err)=>{
      enqueueSnackbar('Error',{variant:'error'});
    });
  };

  const onChangeFilter = ( filter ) => {
    if( !filter ) setListFilter( list ); 
    setListFilter(
      list.filter((el) => String(el.name).toLowerCase().indexOf( String(filter).toLowerCase() ) > -1 )
    );
  };

  useEffect(() => {
    listage();
    GlobalEvents.on('updateMaterials',listage);
    return ()=>{
      GlobalEvents.off('updateMaterials',listage);
    }
  }, []);

  return (
    <React.Fragment>
      <Container component="main">

        <Grid container style={{padding:15}}>
          <Grid item xs={6}>
            <Breadcrumbs aria-label="breadcrumb" style={{margin:5}}>
              <LinkBread color="inherit" href="/">
                Home
              </LinkBread>
              <Typography color="textPrimary">
                Materials
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid xs={6} container justify="flex-end">
            <IconButton aria-label="add" onClick={()=>onNew()} title="Add">
              <AddIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{marginTop:5}}>
            <TextField 
              InputLabelProps={{ shrink: true }} shrink
              autoComplete="materialsfilter"
              name="materials_filter"
              variant="outlined"
              fullWidth
              label="Search"
              size="small"
              onChange={(event)=>{ setFilter(event.target.value); onChangeFilter(event.target.value) }}
              value={filter}
            />
          </Grid>
        </Grid>

        <List dense={true}>
          {listFilter.map((el) => (
            <ListItem style={{marginLeft:parseInt(20*el.depth)}} key={el.id}>
              <ListItemAvatar>
                <img src={process.env.REACT_APP_IMAGE_HOST && el.file_name? process.env.REACT_APP_IMAGE_HOST + '100/' + el.file_name : '/assets/images/image-not-found.png'} alt="Material MP"
                style={{maxWidth:'50px', maxHeight:'50px', borderRadius:'7px', marginRight:'20px'}}/>
              </ListItemAvatar>
              <ListItemText
                primary={el.name}
                secondary={`${el.hex && `color: ${el.hex} | `} ${el.code && `code: ${el.code} | `} ${el.description && `description: ${el.description} | `}`}
              />
              <ListItemSecondaryAction>

                <IconButton aria-label="process" onClick={(event)=>onRelProcess(event,el)} title="Material x Process">
                  <Badge badgeContent={el.rels} color="primary">
                    {/* <Badge badgeContent={4} color="primary"> */}
                    {/* <span style={{fontSize:10}}>{ el.rels || '' }</span> */}
                    <SwapCallsIcon /> 
                    {/* </Badge> */}
                  </Badge>
                </IconButton>

                <IconButton aria-label="edit" onClick={(event)=>onEdit(event,el)} title="Edit">
                  <Edit />
                </IconButton>

                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(event)=>onDelete(event,el)}
                  title="Delete">
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <ModalDialog open={openNew} setOpen={(v)=>setOpenNew(v)} title='Create'>
          <MaterialsCreate data={{}} setOpen={(v)=>{ if(v === false) setOpenNew(v) }}/>
        </ModalDialog>

        <ModalDialog open={openEdit} setOpen={(v)=>setOpenEdit(v)} title='Update'>
          <MaterialsUpdate data={{id:id}} setOpen={(v)=>{ if(v === false) setOpenEdit(v) }}/>
        </ModalDialog>

        <ModalDialog open={openMaterialProcessRel} setOpen={(v)=>setOpenMaterialProcessRel(v)} title={name}>
          <UpdateMaterialProcessRel data={{id:id,name:name}} setOpen={(v)=>{ if(v === false) setOpenMaterialProcessRel(v) }}/>
        </ModalDialog>


        {/* <Nestable 
            collapsed={false}
            items={processTree} 
            renderItem={renderItem2} 
            // ref={el => this.refNestable = el}        
        /> */}

      </Container>
    </React.Fragment>
  );
}
