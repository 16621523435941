import React from 'react';
import BoxPanel from '../../../../resources/component/general/views/BoxPanel';

export default function PanelLibrary() {

  return (
    <div style={{padding:10}}>
      <BoxPanel title="Library">
        <label>Collection Components</label>
      </BoxPanel>
    </div>
  );
}
