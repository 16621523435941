import React, { useEffect, useState } from "react";
import { useGlobal } from "../../../../../../resources/setting/global";
import Effects from "../../../../../../resources/fabricjs/Effects";
import SliderInput from "../../../../../../resources/component/general/inputs/SliderInput";
import UtilsObject from "../../../../../../resources/utils/UtilsObject";

const styles = {
  controlls: {
    display: 'block', 
    paddingLeft: 10,
    paddingRight: 10,
    // background: '#eeeeee', 
    // borderRadius: 7,
  }
}

const BoxGamma = (props) => {
  
  // global variables
  const {valGlobal} = useGlobal();
  // filters values
  const [red, setRed] = useState(1);
  const [green, setGreen] = useState(1);
  const [blue, setBlue] = useState(1);

  // apply filter
  const apply = ( setting ) => {
    new Effects().Gamma( valGlobal.canvas, props.values.index, setting );
  }

  // update this component
  const updateComponents = () => {

    UtilsObject.getActive( valGlobal.canvas ).then( object => {

      if (object.hasOwnProperty('filters') === false) return;      
      object.filters = object.filters || [];
      object.filters.forEach( el => {
        if(el.name === props.values.name){
          // set values
          setRed(props.values.gamma[0]);
          setGreen(props.values.gamma[1]);
          setBlue(props.values.gamma[2]);
        }
      });

    }).catch(()=>{});

  };

  // binding
  useEffect(() => {
    const settings = {
      ...props.values,
      gamma: [red,green,blue],
    }
    apply(settings);
  },[red,green,blue]);

  useEffect(() => {
    updateComponents();
  },[]);

  return (
    <div style={styles.controlls}>
      <SliderInput value={red} min={0.2} max={2.2} step={0.001} default={1} title="Red" onChange={(e)=>setRed(e.value)} isInt={false} decimal={2}/>
      <SliderInput value={green} min={0.2} max={2.2} step={0.001} default={1} title="Green" onChange={(e)=>setGreen(e.value)} isInt={false} decimal={2}/>
      <SliderInput value={blue} min={0.2} max={2.2} step={0.001} default={1} title="Blue" onChange={(e)=>setBlue(e.value)} isInt={false} decimal={2}/>
    </div>
  );
}
export default BoxGamma;