import React, { useState } from "react";
import { Button, Checkbox, FormControlLabel, Grid, makeStyles, Typography } from "@material-ui/core";
import { fabric } from "fabric";

import { useGlobal } from "../../../../../../resources/setting/global";
import UtilsObject from "../../../../../../resources/utils/UtilsObject";
import UtilsCanvas from "../../../../../../resources/utils/UtilsCanvas";
import BoxPanel from "../../../../../../resources/component/general/views/BoxPanel";
import SliderInput from "../../../../../../resources/component/general/inputs/SliderInput";
import { CheckBox, PlusOne } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  buttonCreate: {
    fontSize: 12,
    padding: 7,
    textTransform: "none",
    marginTop: 5,
  },
}));

export default function BoxInnerShadow( props ) {

  const classes = useStyles();
  const {valGlobal} = useGlobal();

  // values
  const [blur,setBlur] = useState(5);
  const [color,setColor] = useState('#000000');
  const [alpha,setAlpha] = useState(0.5);
  const [offset,setOffset] = useState(0);

  const onChangeColor = () => {
    setColor( color === '#000000' ? '#ffffff' : '#000000' );
  }

  const createInnerShadow = () => {

    // phase 2 - criar o retangulo proporcional na mesma posicao, textura e caracteristicas
    UtilsObject.getActive( valGlobal.canvas ).then( object => {

      const {typeName} = object;
      if( typeName === 'SVG' || typeName === 'Group' && typeName === 'Line' ){
        window.alert('Engine no allow SVG, Group and Line. Sorry. Try \"Create (A)\"');
        return;
      }

      const c = valGlobal.canvas;
      const uc = new UtilsCanvas();
      // save angle zero
      uc.rotateReset( c );
      // zoom to 1:1
      uc.zoomReset( c ); 

      //------------------------------------
      const _color   =`rgba(0,0,0,${alpha})`;
      const _blur    = blur;
      const _offsetX = 0; // no change, zero ever
      const _offsetY = 0; // no change, zero ever
      //------------------------------------
      let offsetOut = 10; // no change

      const setAngle = ( elements, angle, multiplier ) => {
        if( angle > 0 ){
          const group = new fabric.Group(elements);
          group.rotate( angle === undefined ? 0 : angle * multiplier ); // 1|-1
          group.destroy()
          //c.renderAll();
        }
      }

      const { angle, scaleX, scaleY } = object;
      // set to angle = 0
      setAngle( [object], angle, -1 );
      // set to scale 1
      if( scaleX !== 1 || scaleY !== 1 ){
        offsetOut = 0;        
        object.set({
          scaleX: 1,
          scaleY: 1,
        });
      }
  
      const { width, height, left, top } = object;
      const rect = new fabric.Rect({
        typeName: 'Rect',
        width: width + ( offsetOut * 2 ), 
        height: height + ( offsetOut * 2 ), 
        left: left - offsetOut,
        top: top - offsetOut,
        // originX: 'left',
        // originY: 'top',
        fill: "black",
        shadow: {
          color: _color,
          blur: _blur,
          offsetX: _offsetX,
          offsetY: _offsetY,
        },
      })  
      // rect.setCoords();
  
      // phase 3 - apply inverted mask 
  
      var clipOfObject = UtilsObject.clone( object );
      console.log('>>>>>',clipOfObject);
      rect.clipPath = clipOfObject;
      rect.clipPath.set({
        typeName: 'ClipPath',
        inverted: true, // the magic
        left: ( rect.clipPath.width / 2 ) * -1,
        top: ( rect.clipPath.height / 2 ) * -1,
      });

      console.log('>>>>',rect.clipPath);
    
      // phase 4 - apply another mask to maker inner shadow
  
      var clipOfObjectFinal = UtilsObject.clone( object );
      const clipFinal = new fabric.Group([rect]);
      clipFinal.key = UtilsCanvas.key();
      clipFinal.typeName = 'GroupShadow';

      object.responsive = [{ object: clipFinal, key: clipFinal.key, properties: ['width','height','left','top','angle']}]

      clipFinal.clipPath = clipOfObjectFinal;
      clipFinal.clipPath.set({
        typeName: 'ClipPath',
        left: ( rect.clipPath.width / 2 ) * -1,
        top: ( rect.clipPath.height / 2 ) * -1,
      });

      UtilsCanvas.add( c, clipFinal);
  
      if( scaleX !== 1 || scaleY !== 1 ){
        [object,clipFinal].forEach( (el) => {
          el.set({
            scaleX: scaleX,
            scaleY: scaleY,
          });
        });
      }

      setAngle( [object,clipFinal], angle, 1 );

      valGlobal.canvas.history.register();

    });

    // shadow: {
    //   color: "rgba(100,100,100,.9)",
    //   blur: 15,
    //   offsetX: 0,
    //   offsetY: 0,
    // },

    // valGlobal.canvas.getActiveObject().on('event:moved', function(event){
    //   const mySelf = event.target;
    //   console.log('>>',mySelf.left,mySelf.get('left'));
    //   clipFinal.add({
    //     left: mySelf.left,
    //     top: mySelf.top,
    //     width: mySelf.width,
    //     height: mySelf.height,
    //   });
    // })

  }


  // https://jsfiddle.net/jrekw5og/141/
  // https://stackoverflow.com/questions/37378088/inset-shadow-on-html5-canvas-image/37380488#37380488

  function createObjectInnerShadow2(){
   
    const object = valGlobal.canvas.getActiveObject();

    // set first object
    if( object === undefined || object === null ){
      window.alert('Select a object');
      return;
    }

    // inner shadow no allow transparency, back color
    const backData = {
      fill: object.fill,
      alpha: object.alpha,
      shadow: object.shadow,
      scaleX: object.scaleX,
      scaleY: object.scaleY,
      widthShadow: object.getBoundingRect().width,
      heightShadow: object.getBoundingRect().height,
    };

    // inner shadow no allow transparency, set alpha 1
    object.set({
      fill:'#FFFFFF',
      alpha: 1,
      shadow: null,
      scaleX: 1,
      scaleY: 1,
    });

    var data = object.toDataURL({
      enableRetinaScaling: true,
      format: "png", // png | jpg
      // quality: 1, // only jpg
      multiplier: 1, // scale, default 1
    });

    var img = document.createElement("img");
    img.onload = function(){ createANewCanva( this, backData ) };
    img.src = data;

  }

  function createANewCanva ( img, backData) {

    const c = valGlobal.canvas;
    const uc = new UtilsCanvas();
    // save angle zero
    uc.rotateReset( c );
    // zoom to 1:1
    uc.zoomReset( c ); 

    var _alpha = alpha;
    var _color = '#000000';
    var distance = blur / 2;
    var _offsetX = offset; // 0 - 2
    var _offsetY = offset; // 0 - 2

      // the size of the shadow depends on the size of the target,
      // then I will create extra "walls" around the picture to be sure
      // tbat the shadow will be correctly filled (with the same intensity everywhere)
      // (it's not obvious with this image, but it is when there is no space at all between the image and its border)
      var _offset = 50 + distance;
      var hole = document.createElement("canvas");
      var holeContext = hole.getContext("2d");
      hole.width = img.width + _offset*2;
      hole.height = img.height + _offset*2;

      // first, I draw a big black rect
      holeContext.fillStyle = "#000000";
      holeContext.fillRect(0,0,hole.width,hole.height);
      
      // then I use the image to make an hole in it
      holeContext.globalCompositeOperation = "destination-out";
      holeContext.drawImage(img,_offset,_offset);
      
      // I create a new canvas that will contains the shadow of the hole only
      var shadow = document.createElement("canvas");
      var shadowContext = shadow.getContext("2d");
      shadow.width = img.width;
      shadow.height = img.height;
      shadowContext.filter = "drop-shadow("+_offsetX+"px "+_offsetY+"px "+distance+"px "+_color+" ) ";
      shadowContext.drawImage(hole,-_offset,-_offset);
      shadowContext.globalCompositeOperation = "destination-out";
      shadowContext.drawImage(hole,-_offset,-_offset);

      shadow.typeName = 'Image';
      
      // now, because the default-shadow filter is really to soft, I normalize the shadow 
      // then I will be sure that the alpha-gradient of the shadow will start at "shadowAlpha" and end at 0
      // normalizeAlphaShadow(shadow,_alpha);
      
      // // Finally, I create another canvas that will contain the image and the shadow over it
      // var result = document.createElement("canvas");
      // result.width = img.width;
      // result.height = img.height;
      // var context = result.getContext("2d");
      // context.drawImage(img,0,0)
      // context.drawImage(shadow,0,0);
      
      // // and that's it !
      // document.body.appendChild(result);

      // ----------------------------------------------------------------------------------------
      // now send to fabric js
      const object = c.getActiveObject();
      object.set(backData);

      // var dataBase = result.toDataURL("image/png");
      var imgInstance = new fabric.Image(shadow, {
        typeName: 'Image',
        left: object.getBoundingRect().left,
        top: object.getBoundingRect().top,
      });
      imgInstance.typeName = 'Image';
      imgInstance.scaleToWidth( backData.widthShadow );
      UtilsCanvas.add( c, imgInstance );

      valGlobal.canvas.history.register();

      // ----------------------------------------------------------------------------------------

 }
 
  function normalizeAlphaShadow(c,_alpha){

    var imageData = c.getContext("2d").getImageData(0,0,c.width,c.height);
    var pixelData = imageData.data;
    var i,len = pixelData.length;
    var max = 0;
    for(i=3;i<len;i+=4) if(pixelData[i]>max) max = pixelData[i];
    
    max = (255/max) * _alpha;
    for(i=3;i<len;i+=4) pixelData[i] *= max;
    
    c.getContext("2d").putImageData(imageData,0,0)
  
  }

  return (
    <BoxPanel title="Inner Shadow">
      <Grid container spacing={1}>

        <Grid item xs={12} style={{textAlign:'left'}}
          onClick = {onChangeColor} 
          >
          <FormControlLabel 
            control={<CheckBox checked = {false} 
            fullWidth
            style = {{fontSize: 20, paddingLeft: 9, display: 'block', }}
            name = "color" />}
            label={<Typography variant="body2" style = {{ fontSize: 12, display: 'block', marginLeft: 10 }}>{ color === '#000000' ? 'Black Color' : 'White Color' }</Typography>}
          />
        </Grid>

        <Grid item xs={12} style={{textAlign:'left'}}>
          <SliderInput value={alpha} min={0.1} max={1} step={0.1} default={0.5} title="Alpha" onChange={(e)=>setAlpha(e.value)} isInt={false} decimal={1}/>
          <SliderInput value={blur} min={0} max={12} step={0.1} default={5} title="Blur / Distance" onChange={(e)=>setBlur(e.value)} isInt={false} decimal={1}/>
          <SliderInput value={offset} min={-5} max={5} step={0.1} default={0} title="Offset" onChange={(e)=>setOffset(e.value)} isInt={false} decimal={1}/>
        </Grid>

          <Grid item xs={12} style={{textAlign:'left'}}>

            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              className={classes.buttonCreate}
              startIcon={<PlusOne fontSize="small" />}
              onClick={()=>{ createObjectInnerShadow2() }}
            >
              Create (A)
            </Button>

            {/* <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              className={classes.buttonCreate}
              startIcon={<PlusOne fontSize="small" />}
              onClick={()=>{ createInnerShadow() }}
            >
              Create (B)
            </Button> */}

          </Grid>
        </Grid>
    </BoxPanel>
  );
};
