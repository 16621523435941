import React, { Component } from 'react';
import lzwCompress from 'lzwcompress';
import cjson from 'compressed-json';
import jsonpack from 'jsonpack/main';

export default class UtilsCompress extends Component {
  
  compress_1 = ( json ) => {
    // string to object
    const object = typeof json === 'object' ? json : JSON.parse(json) ;
    // to compress objects
    const compressed = lzwCompress.pack(object);
    // to uncompress
    const restored = JSON.stringify(lzwCompress.unpack(compressed));
    // log
    console.log('restored',restored);
    console.log('compressed',compressed);
    console.log('JSON.stringify(compressed)',JSON.stringify(compressed));
    return JSON.stringify(compressed);
  } 

  compress_2 = ( json ) => {
    // Convert json object
    // const compressedObject = cjson.compress(json)
    // const restoredObject = cjson.decompress(compressedObject)
    // to compress objects
    const compressed = cjson.compress.toString(json);
    // to uncompress
    const restored = cjson.decompress.fromString(compressed);
    // log
    console.log('restored',restored);
    console.log('compressed',compressed);
    console.log('JSON.stringify(compressed)',JSON.stringify(compressed));
    return compressed;
  }

  compress_3 = ( json ) => {
    // to compress objects
    const compressed = jsonpack.pack(json);
    // to uncompress
    const restored = jsonpack.unpack(compressed);
    // log
    console.log('restored',restored);
    console.log('compressed',compressed);
    console.log('JSON.stringify(compressed)',JSON.stringify(compressed, null, 2));
    return compressed;
  }

}