import React from 'react';
import { useGlobal } from '../../../setting/global';
import If from '../../../utils/If';
import LittleTitle from './LittleTitle';

const BoxPanel = (props) => {
  const {valGlobal} = useGlobal();
  return (
    <div style={valGlobal.structure.tableCell.paper}>
      <If value={props.hasOwnProperty('title')}> 
        <LittleTitle> {props.title} </LittleTitle>
      </If>
      {props.children}
    </div>
  )
}
export default BoxPanel;