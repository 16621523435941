import {
  Breadcrumbs,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Link as LinkBread,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Delete,
  Edit,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {Link, useLocation} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import ApiServer from "../../../../resources/conn/ApiServer";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import ModalDialog from "../../../../resources/component/general/modal/ModalDialog";
import CollectionsCreate from "./../formulary/Create";
import CollectionsUpdate from "./../formulary/Update";

const useStyles = makeStyles((theme) => ({
  breadcrumbsBar: {
    padding: 15,
  },
  primaryName: {
    border: 0,
    textDecoration:null,
    color: '#000000',
  },
  secondaryName: {
    textDecoration:null,
    color: '#777777',
  },
  card: {
    maxWidth: 500,
    backgroundColor: '#fc4600',
  },
  cardText: {
    color: '#ffffff',
  },
  media: {
    height: 180,
    backgroundColor: '#333333',
  },
}));

export default function CollectionsListSimple(props) {

  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  const [openEdit, setOpenEdit] = useState(false);
  const [openNew, setOpenNew] = useState(false);

  const [id, setId] = useState(null);
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [filter, setFilter] = useState('');

  let getLocation = useLocation();
  console.log(getLocation);

  const listage = () => {
    new ApiServer()
      .get("collections", "get")
      .then((e) => {
        if (e.statusText === "OK" && e.data !== undefined ) {
          setList(e.data);
          setListFilter(e.data);
        }else{
          setList([]);
          setListFilter([]);
        }
      })
      .catch((err) => {
        console.log(err.statusText, err);
      });
  };

  const onEdit = ( event,{ id, name } ) => {

    event.preventDefault();
    event.stopPropagation();

    setId( id );
    setOpenEdit( true ); 
  };

  const onNew = () => {
    setOpenNew( true ); 
  };

  const onDelete = ( event, {id} ) => {

    event.preventDefault();
    event.stopPropagation();

    if(!window.confirm('Delete item?')) return;
    new ApiServer().delete( 'collections', `delete/${id}` ).then(( res )=>{
      console.log( res );
      if( res.statusText === 'OK' ){
        listage();
        enqueueSnackbar('Deleted',{variant:'success'});
      }
    }).catch((err)=>{
      enqueueSnackbar('Error',{variant:'error'});
    });
  };

  const onChangeFilter = ( filter ) => {
    if( !filter ) setListFilter( list ); 
    setListFilter(
      list.filter((el) => String(el.collection_name).toLowerCase().indexOf( String(filter).toLowerCase() ) > -1 )
    );
  };

  useEffect(() => {
    listage();
    GlobalEvents.on('updateCollections',listage);
    return ()=>{
      GlobalEvents.off('updateCollections',listage);
    }
  }, []);

  return (
    <React.Fragment>
      <Container component="main">

        <Grid container style={{padding:15}}>
          <Grid item xs={6}>
            <Breadcrumbs aria-label="breadcrumb" style={{margin:5}}>
              <LinkBread color="inherit" href="/">
                Home
              </LinkBread>
              <Typography color="textPrimary">
                Collections Book
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid xs={6} container justify="flex-end">
            <IconButton aria-label="add" onClick={()=>onNew()} title="Add">
              <AddIcon fontSize="large" />
            </IconButton>
            {/* <Button color="primary" variant="outlined">
              Add Collection
            </Button> */}
          </Grid>
          <Grid item xs={12} style={{marginTop:5}}>
            <TextField 
              InputLabelProps={{ shrink: true }} shrink
              autoComplete="collectionsfilter"
              name="collections_filter"
              variant="outlined"
              fullWidth
              label="Search"
              size="small"
              onChange={(event)=>{ setFilter(event.target.value); onChangeFilter(event.target.value) }}
              value={filter}
              />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{padding:15}}>
          {listFilter.map((el) => (
            <Grid item xs={12} sm={6} md={4}>
              <Link
                to={{
                  pathname: `/collection/${el.id}`,
                  collection_id: `${el.id}`,
                  client_id: `${el.client_id}`,
                  }}
                style={{ textDecoration: 'none' }}
              >
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={process.env.REACT_APP_IMAGE_HOST && el.file_name? process.env.REACT_APP_IMAGE_HOST + '200/' + el.file_name : '/assets/images/image-not-found.png'}
                      title={el.board_name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2" className={classes.cardText}>
                        {el.collection_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                        {el.year}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  {/* <CardActions>

                    <IconButton aria-label="edit" onClick={(e)=>onEdit(e, el)} title="Edit">
                      <Edit />
                    </IconButton>

                    <IconButton edge="end" aria-label="delete" onClick={(e)=>onDelete(e, el)} title="Delete">
                      <Delete />
                    </IconButton>

                    <Button size="small" color="primary">
                      Edit
                    </Button>
                    <Button size="small" color="primary">
                      Delete
                    </Button>

                  </CardActions> */}
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>

        <List dense={true}>
          {listFilter.map((el) => (
            <Link
              to={{
                pathname: `/collection/${el.id}`,
                collection_id: `${el.id}`,
                client_id: `${el.client_id}`,
              }}
              style={{ textDecoration: 'none' }}
            >
              <ListItem style={{marginLeft:parseInt(20*el.depth)}} key={el.id} button>
                <ListItemAvatar>
                  <img src={process.env.REACT_APP_IMAGE_HOST && el.file_name? process.env.REACT_APP_IMAGE_HOST + '100/' + el.file_name : '/assets/images/image-not-found.png'} alt="Collection"
                  style={{maxWidth:'50px', maxHeight:'50px', borderRadius:'7px', marginRight:'20px'}}/>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="body" component="body" className={classes.primaryName}>{el.collection_name}</Typography>}
                  secondary={`year: ${el.year || ''}`}
                />
                <ListItemSecondaryAction>

                  <IconButton aria-label="edit" onClick={(event)=>onEdit(event,el)}
                  title="Edit">
                    <Edit />
                  </IconButton>

                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(event)=>onDelete(event,el)}
                    title="Delete">
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Link>
          ))}
        </List>

        <ModalDialog open={openEdit} setOpen={(v)=>setOpenEdit(v)} title='Update'>
          <CollectionsUpdate data={{id:id}} setOpen={(v)=>{ if(v === false) setOpenEdit(v) }}/>
        </ModalDialog>

        <ModalDialog open={openNew} setOpen={(v)=>setOpenNew(v)} title='Add'>
          <CollectionsCreate data={{}} setOpen={(v)=>{ if(v === false) setOpenNew(v) }}/>
        </ModalDialog>

        {/* <Nestable 
            collapsed={false}
            items={processTree} 
            renderItem={renderItem2} 
            // ref={el => this.refNestable = el}        
        /> */}

      </Container>
    </React.Fragment>
  );
}
