import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton, Tooltip } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import router from './../../../resources/router/router';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuRouter( props ) {
  
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
    right: false,
  });
  const anchor = "left";

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const changeRoute = ( el ) => {
    setState({ ...state, [anchor]: false });
  }

  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
        <List>
          {router.map((el, index) => {
            if( el.hiddenMenu === true) return (null);
            return (
              <Link to={el.path} key={index} style={{color:'#444444', textDecoration: 'none'}} onClick={()=>changeRoute( el )}>
                <ListItem button key={index}>
                  <ListItemIcon>{el.icon}</ListItemIcon>
                  <ListItemText primary={el.label} />
                </ListItem>
              </Link>
          )})}
        </List>
      <Divider />
    </div>
  );

  // React.useEffect(() => {
  //   readeLayers();
  //   //GlobalEvents.on("changeActiveObject", readeLayers );
  //   return () => {
  //     //GlobalEvents.off("changeActiveObject", readeLayers );
  //   };
  // }, [valGlobal]);

  return (
    <React.Fragment>
      <Tooltip title="Menu" placement="bottom">
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(anchor, true)}
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <SwipeableDrawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        {list(anchor)}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
