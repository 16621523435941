import React from 'react';
import { SnackbarProvider } from 'notistack';
import Dashboard from '../../structure/layouts/dashboard/Dashboard';
import GlobalProvider from '../../resources/setting/global';

function App() {

  return (
    <React.Fragment>
      <SnackbarProvider maxSnack={5}>
        <GlobalProvider>
          <Dashboard></Dashboard>
        </GlobalProvider>
      </SnackbarProvider>
    </React.Fragment>
  )
}
export default App; 

  //  if (window.matchMedia("(max-width: 700px)").matches) {
  //   /* The viewport is less than, or equal to, 700 pixels wide */
  // } else {
  //   /* The viewport is greater than 700 pixels wide */
  // }

  // var x = window.matchMedia("(max-width: 700px)")
  // myFunction(x) // Call listener function at run time
  // x.addListener(myFunction) // Attach listener function on state changes