import React, { useEffect } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import UtilsCanvas from "../../../../resources/utils/UtilsCanvas";
import { useGlobal } from "../../../../resources/setting/global";

// icons
import canvasRotationRight from './../../../../assets/personalSVG/canvas-rotation-right.svg';
import SliderInput from "../../../../resources/component/general/inputs/SliderInput";

const style = {
  button: {
    minWidth: 30,
    height: 25,
    backgroundColor: "transparent",
    border: "0",
    padding: 0,
    margin: 0,
		cursor: 'pointer',
    fontSize: 12,
  },
  icon: {
    height: 17,
    color: "#777777",
    fill: "#777777",
  },
  division: {
  },
};

export default function MenuCanvas(props) {

  const { valGlobal } = useGlobal();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [zoomValue, setZoomValue] = React.useState(1);
  const [angleValue, setAngleValue] = React.useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const rotate = (angle) => { 
    new UtilsCanvas().rotate( valGlobal.canvas, angle);
    setAngleValue( valGlobal.canvas.clipPath.angle );
    GlobalEvents.dispatch('resizeCanvas');
  }

  const reset = () => {
    new UtilsCanvas().rotateReset( valGlobal.canvas );
    setAngleValue( 0 );
    GlobalEvents.dispatch('resizeCanvas');
  }

  const center = () => {
    const c = valGlobal.canvas;
    const z = valGlobal.canvas.getZoom() || 1;
    c.setViewportTransform([
      1,0,0,
      1,0,0
    ]);
    c.zoomToPoint({ x: (c.width/2), y: (c.height/2) }, z);
    setAnchorEl(null);
    GlobalEvents.dispatch('resizeCanvas');
  }

  const zoom = ( v ) => {
    // valGlobal.canvas.setZoom( v );
    const c = valGlobal.canvas;
    if( c === null || c === undefined ) return ; 
    try {
      // zoom to 1:1
      c.setViewportTransform([1,0,0,1,0,0]); 
      // zoom 
      c.zoomToPoint({ x: (c.width/2), y: (c.height/2) }, v);
      setZoomValue( v );
      handleClose();
      GlobalEvents.dispatch('resizeCanvas');
    } catch (error) {}
  }

  const setChangeZoom = ( data ) => {
    const value = !data ? 1 : ( data.hasOwnProperty('value') ? data.value : 1 );
    setZoomValue( value );
    // setAngleValue( valGlobal.canvas.clipPath.angle || 0 );
  }

  useEffect(()=>{
    //valGlobal.working.zoom;
    GlobalEvents.on('changeZoom', setChangeZoom);
    return ()=>{
      GlobalEvents.off('changeZoom', setChangeZoom);
    }
  },[]);

  return (
   <React.Fragment>

      {/* <Tooltip title="Rotate All: -90°" placement="top">					
        <button type="button" style={style.button} onClick={()=>rotate(-90)} >
          <img src={canvasRotationLeft} style={style.icon}/>
        </button>
      </Tooltip> */}
      {/* <Tooltip title="Rotate All: 90°" placement="top">					
        <button type="button" style={{...style.button}} onClick={()=>rotate(90)} >
          <img src={canvasRotationRight} style={style.icon}/>
        </button>
      </Tooltip> */}

      <Grid container>
        <Grid item xs={8}>
          <div style={{paddingRight:10}}>
            <SliderInput disabled={false} value={zoomValue} min={1} max={5} step={0.1} default={1} onChange={(e)=>zoom(e.value)} isInt={false} decimal={1} />
          </div>
        </Grid>
        <Grid item xs={4}>
         <Tooltip title="Zoom" placement="top" style={style.division}>
            <Button aria-controls="zoom-canvas" aria-haspopup="true" onClick={handleClick} style={{...style.button,padding:0,margin:0,}} >
              {(zoomValue*100)>>0}% {angleValue>>0}°
            </Button>
          </Tooltip>
          <Menu
            id="zoom-canvas"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{padding:0,margin:0,}}
          >
            <MenuItem disabled value=""> Zoom </MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>zoom(3)}>300%</MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>zoom(2.5)}>250%</MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>zoom(2)}>200%</MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>zoom(1.5)}>150%</MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>zoom(1.2)}>120%</MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>zoom(1)}>100%</MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>zoom(.75)}>75%</MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>zoom(.50)}>50%</MenuItem>
            <MenuItem disabled value=""> Rotate </MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>reset()}>Reset: ⦣ 0°</MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>rotate(-90)}>Left: -90°</MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>rotate(90)}>Right: 90°</MenuItem>
            <MenuItem disabled value=""> Align </MenuItem>
            <MenuItem style={{fontSize:13}} onClick={()=>center()}>Center</MenuItem>
          </Menu>
        </Grid>
      </Grid>

    </React.Fragment>
  );
}
