import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ElementColors from "../../../../resources/component/restrict/elements/ElementsColors";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import UtilsColor from "../../../../resources/utils/UtilsColor";
import ButtonLayers from "../panels/boxes/layers/ButtonLayers";

const style = { 
	button: {
		backgroundColor: 'transparent',
		border: 0,
		padding: 0,
		margin: 0,
		'&:hover':{
			backgroundColor: '#777777',
		},
		':hover':{
			backgroundColor: '#777777',
		},
	},
	icon: {
		height: 17,
		color: '#777777',
		fill: '#777777',
	},
  circle: {
    borderColor: '#cccccc',
    borderWidth: '2px',
    borderStyle: "solid",
    borderRadius: '25px',
    width: '50px',
    height: '50px',
    textAlign: "center",
    verticalAlign: "middle",
    cursor:'pointer',
    marginBottom: 5,
    fontSize:10,
  }
}

export default function SectionRight( props ) {

  const [panelRightIsOpen, setPanelRightIsOpen] = useState( false );
  const [panelRightName, setPanelRightName] = useState( false );
  const [activeObject, setActiveObject] = useState( null );

  const panelRight = (panel) => {
    const isOpen = !panelRightIsOpen;
    GlobalEvents.dispatch('panelRight',{ open:isOpen, set:panel });
    setPanelRightIsOpen( isOpen ); // boolean
    setPanelRightName( panel ); // stroke | fill | ... 
  }

  const changeActiveObject = (event) => {
    if(event && event.hasOwnProperty('activeObject')){
      if(event.activeObject){
        setActiveObject( event.activeObject );
      }
    }
  }

  const ButtonCircle = (prop) => {
    return (
      <Tooltip title={prop.title} placement="left">
        <button
          onClick={() => panelRight(prop.property)}
          style={{ ...style.circle, 
          borderWidth: panelRightName === prop.property ? 7 : 2 ,
          borderColor: activeObject ? ( String(activeObject[prop.property]).indexOf('#') === 0 || String(activeObject[prop.property]).indexOf('rgb') === 0 ? (( activeObject[prop.property] === '#FFFFFF' ? '#cccccc' : activeObject[prop.property] ) + 'CC') : '#CCCCCCcc' ) : '#cccccc',
          backgroundColor: activeObject ? ( String(activeObject[prop.property]).indexOf('#') === 0 || String(activeObject[prop.property]).indexOf('rgb') === 0 ? (( activeObject[prop.property] === '#FFFFFF' ? '#FFFFFF' : activeObject[prop.property] ) + 'CC') : '#FFFFFF' ) : '#FFFFFF',
          }}
        >
          <div style={{color: new UtilsColor().invertColorBW( activeObject ? activeObject[prop.property] : '#ffffff'),}}>
            {prop.label}
          </div>
        </button>
      </Tooltip>
    );
  }

  useEffect(() => {
    //setActiveObject( props.activeObject ? props.activeObject : null );
  }, [props]);

  // global event active object
  useEffect(()=>{
    GlobalEvents.on('changeActiveObject', changeActiveObject)
    return () => {
      GlobalEvents.off('changeActiveObject', changeActiveObject);
    }
  },[])

  return (
    <React.Fragment>

      <ButtonCircle property="fill" title="Fill" label="Fill"/>
      <ButtonCircle property="stroke" title="Stroke" label="Strk"/>
      <ButtonLayers/>

      <div style={{marginTop:10}}>
        <ElementColors />
      </div>

    </React.Fragment>
  );
}
