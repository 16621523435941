import React, {useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Backdrop, CircularProgress, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Delete } from '@material-ui/icons';
import ApiServer from "../../../../resources/conn/ApiServer";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import If from "../../../../resources/utils/If";
import UtilsFile from '../../../../resources/utils/UtilsFile';
import UtilsArray from '../../../../resources/utils/UtilsArray';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
    minWidth: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  input: {
    display: 'none',
  },
}));

export default function ProcessCreate(props) {

  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const [submitEnabled,setSubmitEnabled] = useState(true);
  const [name, setName] = useState('');
  const [hex, setHex] = useState('');
  const [file_name, setFileName] = useState('');
  const [ext, setExt] = useState('');
  const [description, setDescription] = useState('');
  const [depth, setDepth] = useState(0);
  const [parentId, setParentId] = useState(0);
  const [parentName, setParentName] = useState('');

  const [processList, setProcessList] = useState([]);

  // ------------------------------------------------------------------ file upload
  const [fileUpload, setFileUpload] = useState(null);
  const [fileUploadName, setFileUploadName] = useState('');
  const [fileUploadSize, setFileUploadSize] = useState('');
  const [fileUploadLoad, setFileUploadLoad] = useState(0);
  const [fileUploadSubmitEnabled, setFileUploadSubmitEnabled] = useState(true);
  // ------------------------------------------------------------------ file upload

  // ------------------------------------------------------------------ file upload

  const formFileClear = (event) => {
    setFileUpload(null);
    setFileUploadName('');
    setFileUploadSize('');
    setFileUploadLoad(0);
    setFileUploadSubmitEnabled(true);
  }

  const onChangeFile = (event) => {

    const f = event.target.files[0];

    if( f === undefined ) return;

    const ext = '.' + f.name.split('.').pop(); // extension 
    const nname = String(f.name).substr(0,15); // new name reduce
    const fileUploadPath = 'process';

    setExt(ext);

    setFileUpload( f );
    setFileUploadName( nname + ext );
    setFileUploadSize( UtilsFile.formatBytes(f.size,1) );

    setSubmitEnabled(false);
    setFileUploadSubmitEnabled(false);

    const form = new FormData();
    form.append('file',f);
    form.append('size',f.size);

    new ApiServer().post( 'upload', fileUploadPath , form, (e)=>{

      setFileUploadLoad( e.percent );
      if(e.percent === 100){
        setSubmitEnabled(true);
        setFileUploadSubmitEnabled(true);
      }

    }).then((e)=>{
      console.log(e);
      if( fileUploadLoad === 100 ){
        setSubmitEnabled(true);
        setFileUploadSubmitEnabled(true);
      }
      setFileName(fileUploadPath+'/' + e.data.filename);

    }).catch((e)=>{
      enqueueSnackbar('Error upload file',{variant:'error'});
      setSubmitEnabled(true);
      setFileUploadSubmitEnabled(true);
      formFileClear();

      setFileName(null);
      setExt(null);
    });

  }
  // ------------------------------------------------------------------ file upload
  
  const listProcess = () => {
   
    new ApiServer().get( 'process', 'get-to-select' ).then(( e )=>{

      console.log( e.statusText, e);
      if( e.statusText === 'OK' ){
        setProcessList( UtilsArray.sortByHierarchyAndName(e.data) );
      }

    }).catch((err)=>{

      console.log( err.statusText, err);

    });

  };

  const onSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();

    if(submitEnabled === false) return;

    setSubmitEnabled( false );
    setLoading( true );

    const data = {
      parent_id: parentId || 0 ,
      name: String(name).trim() ,
      hex: String(hex).trim() ,
      file_name: file_name || '',
      ext: ext || '',
      description: description || '',
      depth: depth || 0 ,
    }

    new ApiServer().post( 'process', 'create', data ).then(( e )=>{

      if(e.statusText==='OK'){
        GlobalEvents.dispatch('updateProcess');
        enqueueSnackbar('Create a new!', {variant:'success'});
        clearAll();

        // ToolDialog close
        if(props.hasOwnProperty('setOpen')){
          props.setOpen(false);
        }
        
      }
      listProcess();
      setSubmitEnabled( true );
      setLoading( false );
    
    }).catch((err)=>{

      console.log( err.statusText, err);
      enqueueSnackbar('Error!', {variant:'error'});
      setSubmitEnabled( true );
      setLoading( false );

    });

  };

  const onChangeDethClear = () => {
    setDepth( 0 );
    setParentName( '' );
    setParentId( 0 );
  }

  const onChangeDeth = ({ depth, name, id }) => {
    setDepth( depth + 1 );
    setParentName( name );
    setParentId( id );
  }

  const onChange = (event) => {

    if(event === undefined) return;

    const { value, name } = event.target;

    switch (name) {
      case 'name':        setName( value ); break;
      case 'hex':         setHex( value ); break;
      case 'file_name':   setFileName( value ); break;
      case 'ext':         setExt( value ); break;
      case 'description': setDescription( value ); break;
      case 'parent_id':   setParentId( value ); break;
      default: console.log('Nenhum');
        break;
    }
  };

  const clearAll = () => {
    setSubmitEnabled(true);
    setName('');
    setHex('');
    setDescription('');
    setParentId(0);
    setParentName('');
    setDepth(0);
    formFileClear();
  }

  useEffect(()=>{
    listProcess();
  },[]);

  return (
    <Container component="main" maxWidth="xs">
      <div>
        <form className={classes.form} noValidate autoComplete="off" >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="parent_id" InputLabelProps={{ shrink: true }} shrink style={{backgroundColor:'#ffffff'}} >Parent</InputLabel>
                <Select
                  // onClick={()=>listProcess()}
                  variant="outlined"
                  labelId="parent_id"
                  name="parent_id"
                  value={parentId}
                  onChange={onChange}
                  label="Parent"
                  fullWidth
                >
                  <MenuItem value={null} onClick={()=>onChangeDethClear()}>
                    <em>None</em>
                  </MenuItem>
                  {processList.map(( el, index )=>(
                    <MenuItem value={el.id} key={index} 
                    onClick={()=>onChangeDeth(el)} 
                    onChange={()=>onChangeDeth(el)}
                    style={{ fontWeight: el.depth ? 300 : 500 }}
                    >
                      <div style={{paddingLeft: el.depth * 15 }}>
                        {el.name}
                      </div>
                    </MenuItem>
                  ))}
                </Select>              
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField 
                InputLabelProps={{ shrink: true }} shrink
                autoComplete="processname"
                name="name"
                variant="outlined"
                required
                fullWidth
                label="Process Name"
                autoFocus
                onChange={onChange}
                value={name}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField 
                InputLabelProps={{ shrink: true }} shrink
                autoComplete="processdescription"
                name="description"
                variant="outlined"
                fullWidth
                label="Process Description"
                onChange={onChange}
                value={description}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container xs={12} sm={12} spacing={2}>
                <Grid item xs={10} sm={10}>
                  <TextField 
                    InputLabelProps={{ shrink: true }} shrink
                    autoComplete="colorhex"
                    name="hex"
                    variant="outlined"
                    fullWidth
                    label="Hex (#FFF)"
                    onChange={onChange}
                    value={hex}
                    inputProps={{ maxLength: 9 }}
                  />
                </Grid>
                <Grid item xs={2} sm={2} alignContent="center" alignItems="center">
                <div style={{ width:50, height:50, marginRight:20, backgroundColor:(hex.length>6?hex:'#eeeeee'), }}></div>
                </Grid>
              </Grid>
            </Grid>
            <If value={fileUpload===null}>
              <Grid item xs={12} sm={12}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="file"
                  // multiple
                  type="file"
                  onChange={onChangeFile}
                />
                <label htmlFor="file">
                  <Button variant="contained" component="span" disableElevation={true} fullWidth style={{padding:7}} disabled={!fileUploadSubmitEnabled}>
                  upload file 
                  </Button>
                </label>
              </Grid>
              <LinearProgress variant="determinate" value={fileUploadLoad} fullWidth />
            </If>
            <If value={fileUpload !== null}>
              <Grid container spacing={1} xs={12} sm={12} style={{fontSize:'12px'}}>
                <Grid item xs={7} sm={7} style={{paddingLeft:15}}>
                {fileUploadLoad === 0 || fileUploadLoad === 100 ? '' : (fileUploadLoad + '% - ')} 
                {fileUploadName}
                </Grid>
                <Grid item xs={3} sm={3}>
                {fileUploadSize} 
                </Grid>
                <Grid item xs={2} sm={2} style={{paddingRight:5,textAlign:'right',cursor:'pointer'}}>
                  <Delete onClick={()=>formFileClear()}/>
                </Grid>
              </Grid>
            </If>            
            {parentName===''?null:(
            <Grid item xs={12} sm={12}>
                <Paper style={{padding:'10px 15px',backgroundColor:'#eeeeee',color:'#555555'}}>
                  <code>
                  + {parentName} <br/>
                  &nbsp;&nbsp; └─ {name} <br/>
                  </code>
                </Paper>
            </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <Button
                disabled={!submitEnabled}
                type="no_submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Create
              </Button>

            </Grid>
          </Grid>

        </form>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}