import React from "react";
import { Tooltip } from "@material-ui/core";
import { useGlobal } from "../../../../resources/setting/global";
import UtilsObject from "../../../../resources/utils/UtilsObject";
import UtilsCanvas from "../../../../resources/utils/UtilsCanvas";

// icons
import bringFront from './../../../../assets/fontawesome/svgs/regular/bring-front.svg';
import bringForward from './../../../../assets/fontawesome/svgs/regular/bring-forward.svg';
import sendBackward from './../../../../assets/fontawesome/svgs/regular/send-backward.svg';
import sendBack from './../../../../assets/fontawesome/svgs/regular/send-back.svg';

const style = {
  button: {
    minWidth: 30,
    height: 25,
    backgroundColor: "transparent",
    border: "0",
    float: "left",
    padding: 0,
    margin: 0,
		cursor: 'pointer',
  },
  icon: {
    height: 17,
    color: "#777777",
    fill: "#777777",
  },
  division: {
    marginLeft: 20,
  },
};

export default function MenuOrder() {

  const { valGlobal } = useGlobal();

  const sendTo = ( value ) => {
    switch ( value ) {
      case 1: UtilsObject.bringToFront( valGlobal.canvas ); break;
      case 2: UtilsObject.bringForward( valGlobal.canvas ); break;
      case 3: UtilsObject.sendBackwards( valGlobal.canvas ); break;
      case 4: UtilsObject.sendToBack( valGlobal.canvas ); break;
      default: console.log(''); break;
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Front" placement="top">
        <button type="button" style={style.button} onClick={()=>sendTo(1)}>
          <img src={bringFront} style={style.icon} />
        </button>
      </Tooltip>
      <Tooltip title="Up +1" placement="top">
        <button type="button" style={style.button} onClick={()=>sendTo(2)}>
          <img src={bringForward} style={style.icon} />
        </button>
      </Tooltip>
      <Tooltip title="Down -1" placement="top">
        <button type="button" style={style.button} onClick={()=>sendTo(3)}>
          <img src={sendBackward} style={style.icon} />
        </button>
      </Tooltip>
      <Tooltip title="Back" placement="top">
        <button type="button" style={style.button} onClick={()=>sendTo(4)}>
          <img src={sendBack} style={style.icon} />
        </button>
      </Tooltip>
    </React.Fragment>
  );
}
