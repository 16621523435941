import React, { useEffect, useState } from "react";
import { useGlobal } from "../../../../../../resources/setting/global";
import Effects from "../../../../../../resources/fabricjs/Effects";
import SliderInput from "../../../../../../resources/component/general/inputs/SliderInput";
import UtilsObject from "../../../../../../resources/utils/UtilsObject";

const styles = {
  controlls: {
    display: 'block', 
    paddingLeft: 10,
    paddingRight: 10,
    // padding: 10, 
    // background: '#eeeeee', 
    // borderRadius: 7,
  }
}

const BoxBrightness = (props) => {
  
  // global variables
  const {valGlobal} = useGlobal();
  // filters values
  const [brightness, setBrightness] = useState(0);

  // apply filter
  const apply = ( setting ) => {
    new Effects().Brightness( valGlobal.canvas, props.values.index, setting );
  }

  // update this component
  const updateComponents = () => {

    UtilsObject.getActive( valGlobal.canvas ).then( object => {

      if (object.hasOwnProperty('filters') === false) return;      
      object.filters = object.filters || [];
      object.filters.forEach( el => {
        if(el.name === props.values.name){
          // set values
          setBrightness(props.values.brightness);
        }
      });

    }).catch(()=>{});

  };

  // binding
  useEffect(() => {
    if(brightness){
      const settings = {
        ...props.values,
        brightness: brightness,
      }
      apply(settings);
    }
  },[brightness]);

  useEffect(() => {
    updateComponents();
  },[]);

  return (
    <div style={styles.controlls}>
      <SliderInput value={brightness} min={-0.5} max={0.5} step={0.01} default={0} onChange={(e)=>setBrightness(e.value)} isInt={false} decimal={2}/>
    </div>
  );
}
export default BoxBrightness;