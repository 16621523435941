import React, { useEffect, useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { fabric } from "fabric";

import { useGlobal } from "../../../../../../resources/setting/global";
import { Delete } from "@material-ui/icons";
import UtilsObject from "../../../../../../resources/utils/UtilsObject";
import BoxPanel from "../../../../../../resources/component/general/views/BoxPanel";
import SliderInput from "../../../../../../resources/component/general/inputs/SliderInput";
import GlobalEvents from "../../../../../../resources/event/GlobalEvents";
import UtilsCanvas from "../../../../../../resources/utils/UtilsCanvas";

const useStyles = makeStyles((theme) => ({
  buttonCreate: {
    fontSize: 11,
    padding: 5,
    textTransform: "none",
    marginTop: 10,
  },
}));

export default function BoxDropShadow( props ) {

  const classes = useStyles();
  const {valGlobal} = useGlobal();

  // const [color,setColor] = useState('#000000');
  const [alpha, setAlpha] = useState(1);
  const [blur, setBlur] = useState(7);
  const [offsetX, setOffsetX] = useState(1);
  const [offsetY, setOffsetY] = useState(1);

  const updateValues = () => {
    const c = valGlobal.canvas;
    UtilsObject.getActive( c ).then( object => {

      object.set({
        shadow: new fabric.Shadow({
          color: `rgba(0,0,0,${alpha})`, 
          nonScaling: false, 
          affectStroke: true,
          blur: blur, 
          alpha: alpha,
          offsetX: offsetX, 
          offsetY: offsetY,
        })
      });

      UtilsCanvas.renderAll( c );
      c.history.register();
  
    }).catch(clearAll);
  }

  const removeDropShadow = () => {
    const c = valGlobal.canvas;
    UtilsObject.getActive( c ).then( object => {
      object.set({
        shadow: null
      });
      UtilsCanvas.renderAll( c );
      c.history.register();
    });
  }

  const clearAll = () => {
    setBlur(7)
    setAlpha(1);
    setOffsetX(1);
    setOffsetY(1);
  }

  const setValue = ( event ) => {
    const c = valGlobal.canvas;
    UtilsObject.getActive( c, event && event.activeObject ).then( object => {

      // (static) reOffsetsAndBlur
      // Regex matching shadow offsetX, offsetY and blur (ex: "2px 2px 10px rgba(0,0,0,0.2)", "rgb(0,255,0) 2px 2px") 

      if( object.hasOwnProperty('shadow') && object.shadow.hasOwnProperty('color')){
        const { blur, alpha, offsetX, offsetY } = object.shadow;
        setBlur( blur )
        setAlpha( alpha );
        setOffsetX( offsetX );
        setOffsetY( offsetY );    
      }

    }).catch(clearAll);
  }  

  useEffect(()=>{
    setValue();
    GlobalEvents.on("changeActiveObject", setValue );
    return () => {
      GlobalEvents.off("changeActiveObject", setValue );
    };
  },[]);
  

  return (
    <BoxPanel title="Drop Shadow">
      <Grid container spacing={1}>

      {/* 

      angle + distance = x y

      https://www.construct.net/en/forum/construct-2/how-do-i-18/calculate-position-xy-using-78314

      convert x y to angle

      https://stackoverflow.com/questions/15994194/how-to-convert-x-y-coordinates-to-an-angle#15994225
      
      https://stackoverflow.com/questions/23598547/draw-a-line-from-x-y-with-a-given-angle-and-length

      https://stackoverflow.com/questions/35271222/getting-the-angle-from-a-direction-vector

      https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/atan2
      
      */}

        <Grid item xs={12} style={{textAlign:'left'}}>
          <SliderInput value={blur} min={0} max={50} step={0.1} default={20} title="Blur" onChange={(e)=>{setBlur(e.value);updateValues()}} isInt={false} decimal={1}/>
          <SliderInput value={alpha} min={0.1} max={1} step={0.1} default={1} title="Alpha" onChange={(e)=>{setAlpha(e.value);updateValues()}} isInt={false} decimal={1}/>
          <SliderInput value={offsetX} min={-10} max={10} step={0.1} default={1} title="Offset X" onChange={(e)=>{setOffsetX(e.value);updateValues()}} isInt={false} decimal={1}/>
          <SliderInput value={offsetY} min={-10} max={10} step={0.1} default={1} title="Offset Y" onChange={(e)=>{setOffsetY(e.value);updateValues()}} isInt={false} decimal={1}/>
        </Grid>

          <Grid item xs={12} style={{textAlign:'left'}}>

            <Button
              variant="outlined"
              color="secondary"
              size="small"
              fullWidth
              className={classes.buttonCreate}
              startIcon={<Delete fontSize="small" />}
              onClick={removeDropShadow}
            >
              Remove
            </Button>

          </Grid>
        </Grid>
    </BoxPanel>
  );
};
