import React, { useState } from "react";
import "./Magic.css";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress, makeStyles, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { AccountCircle } from "@material-ui/icons";
import { useGlobal } from "../../../resources/setting/global";
import GlobalEvents from "../../../resources/event/GlobalEvents";
import SectionIcons from "./sections/SectionIcons";
import SectionTop from "./sections/SectionTop";
import If from "../../../resources/utils/If";
import Canvas from "../../../resources/fabricjs/Canvas";
import PanelBrands from "./panels/PanelBrands";
import PanelProcess from "./panels/PanelProcess";
import PanelLibrary from "./panels/PanelLibrary";
import PanelMaterial from "./panels/PanelMaterial";
import PanelText from "./panels/PanelText";
import PanelFinishing from "./panels/PanelFinishing";
import PanelSettings from "./panels/PanelSettings";
import PanelFilters from "./panels/PanelFilters";
import Panel2 from "./panels/Panel2";
import Panel3 from "./panels/Panel3";
import Panel6 from "./panels/Panel6";
import MenuToolBig from "./contextMenu/MenuToolBig";
import MenuAlign from "./contextMenu/MenuAlign";
import MenuOrder from "./contextMenu/MenuOrder";
import MenuRotate from "./contextMenu/MenuRotate";
import MenuClipboard from "./contextMenu/MenuClipboard";
import MenuFlipAndFit from "./contextMenu/MenuFlipAndFit";
import PlusTool from "./panels/boxes/buttons/PlusTool";
import BoxColorFill from "./panels/boxes/color/BoxColorFill";
import BoxColorStroke from "./panels/boxes/color/BoxColorStroke";
import SectionRight from "./sections/SectionRight";
import SectionBottom from "./sections/SectionBottom";
import MenuIcon from '@material-ui/icons/Menu';
import PanelTechnicalReport2 from "./panels/PanelTechnicalReport2";
import ElementOpacity from "../../../resources/component/restrict/elements/ElementOpacity";
import ElementDimension from "../../../resources/component/restrict/elements/ElementDimension";

const useStyles = makeStyles((theme) => ({

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  menuButton: {
    marginRight: theme.spacing(0),
    width: 35,
    height: 35,
    marginTop:11,
    marginLeft:1,
  },
  menuIcon: {
    marginTop:5,
  },
  paper: {
    padding: 10,
    //textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(0),
    height: '100%',
  },
  menuItem: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		fontSize: 12,
		fontWeight: 400,
  },
  menuItemSpacing: {
    padding: 10,
    paddingBottom: 25,
  }
}));

const initialState = {
  mouseX: null,
  mouseY: null,
};

function Magic() {
  //-----------------------------------------------------------------------------------------
  //const [sett, setSett] = useState({});
  const { valGlobal, setGlobal } = useGlobal();
  //-----------------------------------------------------------------------------------------

  const classes = useStyles();
  const [loading,setLoading] = React.useState(false);
  const [percent,setPercent] = React.useState(0);

  //const [canvas, setCanvas] = useState(undefined);
  const [panel, setPanel] = useState( undefined );
  const [panelsHeight, setPanelsHeight] = useState( 100 );
  const [panelRightName, setPanelRightName] = useState( '' );
  
  const [state, setState] = React.useState(initialState);

  const openPanelRight = (e) => {
    setPanelRightName( e.set );
  }

  const setLoadingData = (data) => {
    setLoading(data.loading);
    setPercent(data.percent);
  }

  const resizePanelsHeight = () => {
    setPanelsHeight( 
      window.innerHeight - 
      valGlobal.structure.tableLines.top.height - 
      valGlobal.structure.tableLines.bottom.height );
  };

  const handleClick = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = ( event ) => {
    setState( initialState );
  };

  const changePanel = ( pnl ) => {
    setPanel( pnl ? pnl : 'brands' );
    handleClose();
  }

  React.useEffect(()=>{
    resizePanelsHeight();
    window.addEventListener("resize", resizePanelsHeight);
    GlobalEvents.on('panelRight', openPanelRight)
    return () => {
      window.removeEventListener("resize", resizePanelsHeight);
      GlobalEvents.off('panelRight', openPanelRight)
    }
  },[])

  return (
    <React.Fragment>

      <table
        id="StructureCanvas"
        style={valGlobal.structure.table}
        >
        <tbody>
          <tr style={valGlobal.structure.tableLines.top}>
            <td align="center" valign="middle">

              <Tooltip title="Menu" placement="bottom">
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                  // onClick={toggleDrawer(anchor, true)}
                >
                  <Link to="/" style={{color:'#444444', textDecoration: 'none'}}>  
                    {<MenuIcon className={classes.menuIcon} />}
                  </Link>
                </IconButton>
              </Tooltip>

            </td>
            <td>
              <div style={valGlobal.places.logoDiv}> 

                {/* {Config.name}  */}
                <Typography variant="body2" component="body2" gutterBottom 
                style={{fontSize:20,fontWeight:300, marginLeft:0}}>
                  {process.env.REACT_APP_NAME}
                  {/* <Button variant="outlined" color="primary" href="/">
                    Back
                  </Button> */}
                  {/* <Link to="/" style={{color:'#444444', textDecoration: 'none'}} onClick={()=>changeRoute( false )}>  
                    <Button variant="outlined" color="primary" size="small" style={{padding:1,marginLeft:20}} >
                      Back
                    </Button>
                  </Link> */}
                </Typography>
                {/* <small>{process.env.REACT_APP_VERSION}</small> */}
              </div>
            </td>
            <td style={{textAlign:'center',maxHeight:50}}>
              <SectionTop setLoading={(v)=>setLoading(v)}/>
            </td>
            <td colSpan="2">
              <div style={{display:'block',borderRadius:7,paddingRight:10,margin:5,}}>              
                <Grid container style={{textAlign:'right'}}>
                  <Grid item sm={9}>
                    {/* https://boringavatars.com/ */}
                    <strong style={{color:'#444444'}}>User Name</strong>
                    <div style={{color:'#777777'}}>Designer</div>
                  </Grid>
                  <Grid item sm={3}>
                    <AccountCircle />
                  </Grid>
                </Grid>
              </div>
            </td>
          </tr>
          <tr style={valGlobal.structure.tableLines.middle}>
            <td style={valGlobal.structure.tableColumns.icons} id="CanvasIcons">
              <div style={valGlobal.places.iconsDiv}>
                <SectionIcons
                  changePanelClick={(n) => setPanel(n)}
                  changePanelOver={(n) => setPanel(n)}
                />
              </div>
            </td>
            <td
              style={valGlobal.structure.tableColumns.panel}
              id="CanvasPanelX"
              valign="top"
            >

              <div style={{overflow:'scroll',overflowX:'hidden',height:panelsHeight+'px'}}>

                <If value={panel ===  'brands' || !panel}>
                  <PanelBrands />
                </If>
                <If value={panel === 'process'}>
                  <PanelProcess />
                </If>
                <If value={panel === 'library'}>
                  <PanelLibrary />
                </If>
                <If value={panel === 'material'}>
                  <PanelMaterial />
                </If>
                <If value={panel === 'text'}>
                  <PanelText />
                </If>
                <If value={panel === 'settings'}>
                  <PanelSettings />
                </If>
                <If value={panel === 'filters'}>
                  <PanelFilters />
                </If>
                <If value={panel === 'finishing'}>
                  <PanelFinishing />
                </If>

                <If value={panel === 2}>
                  <Panel2 />
                </If>
                <If value={panel === 3}>
                  <Panel3 />
                </If>
                <If value={panel === 6}>
                  {/* <Panel6 setLoading={(v)=>setLoading(v)} /> */}
                  <Panel6 />
                </If>
                <If value={panel === 'technicalReport'}>
                  <PanelTechnicalReport2 />
                </If>

              </div>

            </td>
            <td style={valGlobal.structure.tableColumns.main} id="CanvasBox" onContextMenu={handleClick}>

              <MenuToolBig/>
              <PlusTool/>
                
              <div style={{...valGlobal.places.mainDiv,backgroundColor:valGlobal.colors.backgroundColorOut||'transparent'}}>
                <Canvas
                  id="canvas"
                  width={valGlobal.sizes.widthStage}
                  height={valGlobal.sizes.heightStage}
                  setGlobal={(g) => setGlobal(g)}
                  valGlobal={valGlobal}
                  style={{float:'left'}}
                />
              </div>

              <Menu
                keepMounted
                open={state.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  state.mouseY !== null && state.mouseX !== null
                    ? { top: state.mouseY, left: state.mouseX }
                    : undefined
                }>
                <MenuItem onClick={() => changePanel('settings')} className={classes.menuItem} >Setting &nbsp;<strong>Object</strong></MenuItem>
                <MenuItem onClick={() => changePanel('brands')} className={classes.menuItem} >Change &nbsp;<strong>Brands</strong></MenuItem>
                <MenuItem onClick={() => changePanel('library')} className={classes.menuItem} >Open &nbsp;<strong>Library</strong></MenuItem>
                <MenuItem onClick={() => changePanel('process')} className={classes.menuItem} >Set &nbsp;<strong>Process</strong></MenuItem>
                <MenuItem onClick={() => changePanel('filters')} className={classes.menuItem} >Apply &nbsp;<strong>Filters</strong></MenuItem>
                <MenuItem onClick={() => changePanel('finishing')} className={classes.menuItem} ><strong>Finishing</strong></MenuItem>
                <Divider/>
                <div onClick={(e) => handleClose()}>
                  {[<MenuAlign/>,<MenuOrder/>,<MenuRotate/>,<MenuClipboard/>,<MenuFlipAndFit/>].map( (el,index) => (
                    <div className={classes.menuItemSpacing} key={index}>
                      {el}
                    </div>
                  ))}
                </div>
              </Menu>

            </td>
            {/* <td style={panelRightIsOpen ? valGlobal.structure.tableColumns.panelRight : {display:'none'}} valign="top"> */}
            <td style={valGlobal.structure.tableColumns.panelRight} valign="top">

              {/* <CustomScroll
              // heightRelativeToParent="100px"
              minScrollHandleHeight="250px"
              heightRelativeToParent="300px" 
              > */}

                { panelRightName === 'fill' ? (<BoxColorFill/>) : null }
                { panelRightName === 'stroke' ? (<BoxColorStroke/>) : null }
                {/* { panelRightName === 'layers' ? (<BoxLayers/>) : null } */}

              {/* </CustomScroll> */}

            </td>
            <td
              style={valGlobal.structure.tableColumns.export}
              id="CanvasExports"
              valign="top"
            >
              <div style={valGlobal.places.exportsDiv}>
                <SectionRight />
              </div>
            </td>
          </tr>
          <tr style={valGlobal.structure.tableLines.bottom}>
            <td></td>
            <td>
              <center>
                1, 2, 3...
              </center>
            </td>
            <td colSpan="2">
              <SectionBottom/>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress style={{position:'absolute'}} color="inherit" />
        <label style={{fontSize:10}}>{percent ? `${percent} %` : ''}</label>
      </Backdrop>

    </React.Fragment>
  );
}

export default Magic;
