import React, {useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Backdrop, CircularProgress, LinearProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ApiServer from "../../../../resources/conn/ApiServer";
import GlobalEvents from "../../../../resources/event/GlobalEvents";

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
    minWidth: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  input: {
    display: 'none',
  },
}));

export default function ColorsUpdate(props) {

  const classes = useStyles();

  // form status
  const {enqueueSnackbar} = useSnackbar();
  const [loading,setLoading] = React.useState(false);
  const [submitEnabled,setSubmitEnabled] = useState(true);

  // form input
  const [id,setId] = useState('');
  const [colorName,setColorName] = useState('');
  const [code,setCode] = useState('');
  const [hex,setHex] = useState('');
  const [pantone,setPantone] = useState('');

  const getId = ( v ) => {

    setId( v );
    setLoading( true );

    new ApiServer().get( 'colors', 'get/' + v ).then(( e )=>{
      console.log( e.statusText, e);
      if( e.statusText === 'OK' && Array.isArray(e.data) ){
        
        if(e.data.length === 0) {
          setLoading( false );
          enqueueSnackbar('Not found',{variant:'error'});
          return;
        }

        const data = e.data[0];
        setId(data.id);
        setColorName(data.color_name);
        setCode(data.code);
        setHex(data.hex);
        setPantone(data.pantone);

        setSubmitEnabled( true );
        setLoading( false );

      }
    
    }).catch((err)=>{
      console.log( err.statusText, err);
      setSubmitEnabled( true );
      setLoading( false );
    });

  };

  const onSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();

    if(submitEnabled === false) return;

    setSubmitEnabled( false );

    const data = {
      color_name: String(colorName).trim(),
      code: String(code).trim() || '',
      hex: String(hex).trim() || '',
      pantone: String(pantone).trim() || '',
    }

    console.log('UPDATE');
    new ApiServer().put( 'colors', 'update/' + id, data ).then(( e )=>{

      if(e.statusText==='OK'){
        GlobalEvents.dispatch('updateColors');
        enqueueSnackbar('Update!', {variant:'success'});
        clearAll();

        // ToolDialog close
        if(props.hasOwnProperty('setOpen')){
          props.setOpen(false);
        }
        
      }
      setSubmitEnabled( true );
    
    }).catch((err)=>{

      console.log( err.statusText, err);
      enqueueSnackbar('Error!', {variant:'error'});
      setSubmitEnabled( true );

    });

  };

  const onChange = (event) => {

    if(event === undefined) return;

    const { value, name } = event.target;

    switch (name) {
      case 'colorName':   setColorName( value ); break;
      case 'code':        setCode( value ); break;
      case 'hex':         setHex( String(value).indexOf('#') === 0 ? value : `#${value}` ); break;
      case 'pantone':     setPantone( value ); break;
      default: console.log('Nenhum');
        break;
    }
  };

  const clearAll = () => {
    setSubmitEnabled(true);
    setColorName('');
    setCode('');
    setHex('');
    setPantone('');
  }

  useEffect(()=>{
    if( Number(props.data.id) > 0 ){
      getId( props.data.id );
      setSubmitEnabled( false );
    }
  },[props]);

  return (
    <Container component="main" maxWidth="xs">
      <LinearProgress style={{display: (!loading?'none':'block'), minWidth:200}} />
      <div style={{display: (loading?'none':'block')}}>
        <form className={classes.form} noValidate autoComplete="off" >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField 
                InputLabelProps={{ shrink: true }} shrink
                autoComplete="colorname"
                name="colorName"
                variant="outlined"
                required
                fullWidth
                label="Color Name"
                autoFocus
                onChange={onChange}
                value={colorName}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField 
                InputLabelProps={{ shrink: true }} shrink
                autoComplete="colorcode"
                name="code"
                variant="outlined"
                fullWidth
                label="Code"
                onChange={onChange}
                value={code}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container xs={12} sm={12} spacing={2}>
                <Grid item xs={10} sm={10}>
                  <TextField 
                    InputLabelProps={{ shrink: true }} shrink
                    autoComplete="colorhex"
                    name="hex"
                    variant="outlined"
                    fullWidth
                    label="Hex (#FFF)"
                    onChange={onChange}
                    value={hex}
                    inputProps={{ maxLength: 9 }}
                  />
                </Grid>
                <Grid item xs={2} sm={2} alignContent="center" alignItems="center">
                <div style={{ width:50, height:50, marginRight:20, backgroundColor:(hex.length>6?hex:'#eeeeee'), }}></div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField 
                InputLabelProps={{ shrink: true }} shrink
                autoComplete="colorpantone"
                name="pantone"
                variant="outlined"
                fullWidth
                label="Pantone"
                onChange={onChange}
                value={pantone}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                disabled={!submitEnabled}
                type="no_submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Update
              </Button>
            </Grid>
          </Grid>

        </form>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}