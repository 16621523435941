import React, { useEffect, useState } from "react";
import { useGlobal } from "../../../../../../resources/setting/global";
import Effects from "../../../../../../resources/fabricjs/Effects";
import UtilsObject from "../../../../../../resources/utils/UtilsObject";
import SliderInput from "../../../../../../resources/component/general/inputs/SliderInput";
import { Button } from "@material-ui/core";

const styles = {
  controlls: {
    padding: 10, 
    display: 'block', 
    background: '#eeeeee', 
    borderRadius: 7,
  },
  button: {
    padding: '2px 0',
    textTransform: "none",
    // backgroundColor: '#2a82db',
    marginBottom: 1,
    fontWeight: 400,
    fontSize: 11,
  },
}

const BoxEmboss = (props) => {
  
  // global variables
  const {valGlobal} = useGlobal();
  // filters values
  const [matrix, setMatrix] = useState([1,1,1,1,.7,-1,-1,-1,-1]);

  const [matrix11, setMatrix11] = useState(0);
  const [matrix12, setMatrix12] = useState(0);
  const [matrix13, setMatrix13] = useState(0);

  const [matrix21, setMatrix21] = useState(0);
  const [matrix22, setMatrix22] = useState(0);
  const [matrix23, setMatrix23] = useState(0);

  const [matrix31, setMatrix31] = useState(0);
  const [matrix32, setMatrix32] = useState(0);
  const [matrix33, setMatrix33] = useState(0);

  // apply filter
  const apply = ( setting ) => {
    new Effects().Emboss( valGlobal.canvas, props.values.index, setting );
  }

  // update this component
  const updateComponents = () => {

    UtilsObject.getActive( valGlobal.canvas ).then( object => {

      if (object.hasOwnProperty('filters') === false) return;      
      object.filters = object.filters || [];
      object.filters.forEach( el => {
        if(el.name === props.values.name){
          // set values
          console.log(props.values);
          setMatrix(props.values.matrix);
        }
      });

    }).catch(()=>{});

  };

  const sliderMatrix = ( arr ) => {    
    setMatrix11(arr[0]);
    setMatrix12(arr[1]);
    setMatrix13(arr[2]);
    setMatrix21(arr[3]);
    setMatrix22(arr[4]);
    setMatrix23(arr[5]);
    setMatrix31(arr[6]);
    setMatrix32(arr[7]);
    setMatrix33(arr[8]);
  }

  const personalMatrix = ( value ) => {
    sliderMatrix( value );
    setMatrix( value );
  }

  useEffect(() => {
    const settings = {
      ...props.values,
      matrix: [
        matrix11,matrix12,matrix13,
        matrix21,matrix22,matrix23,
        matrix31,matrix32,matrix33,
      ],
    }
    apply(settings);
  },[
    matrix11,matrix12,matrix13,
    matrix21,matrix22,matrix23,
    matrix31,matrix32,matrix33,
  ]);

  useEffect(() => {
    const settings = {
      ...props.values,
      matrix: matrix,
    }
    apply(settings);
  },[matrix]);

  useEffect(() => {    
    updateComponents();
  },[]);

  return (
    <div style={styles.controlls}>

      <Button onClick={()=>personalMatrix([0,-1,0,-1,5,-1,0,-1,0])} variant="outlined" size="small" color="primary" size="small" style={styles.button} fullWidth> Sharpen lite </Button>
      <Button onClick={()=>personalMatrix([0,-2,0,-2,9,-2,0,-2,0])} variant="outlined" size="small" color="primary" size="small" style={styles.button} fullWidth> Sharpen hard </Button>
      <Button onClick={()=>personalMatrix([1,1,1,1,.9,-1,-1,-1,-1])} variant="outlined" size="small" color="primary" size="small" style={styles.button} fullWidth> Emboss 1 </Button>
      <Button onClick={()=>personalMatrix([.9,.9,.9,.9,.3,-.7,-.7,-.8,-.8])} variant="outlined" size="small" color="primary" size="small" style={styles.button} fullWidth> Emboss 2 </Button>
      <Button onClick={()=>personalMatrix([1,0,0,0,.8,0,0,0,-1])} variant="outlined" size="small" color="primary" size="small" style={styles.button} fullWidth> Emboss 3 </Button>
      <Button onClick={()=>personalMatrix([.5,1,1,1,.5,-1,-1,-0.5,-0.5])} variant="outlined" size="small" color="primary" size="small" style={styles.button} fullWidth> Emboss 4 </Button>
      <Button onClick={()=>personalMatrix([-2,-1,0,-1,1,1,0,1,2])} variant="outlined" size="small" color="primary" size="small" style={styles.button} fullWidth> Emboss 5 </Button>
      <Button onClick={()=>personalMatrix([-1,-1,0,-1,.8,1,0,1,1])} variant="outlined" size="small" color="primary" size="small" style={styles.button} fullWidth> Emboss 6 </Button>

      <SliderInput value={matrix11} min={-2} max={2} step={0.01} default={0} onChange={(e)=>setMatrix11(e.value)} isInt={false} decimal={2}/>
      <SliderInput value={matrix12} min={-2} max={2} step={0.01} default={0} onChange={(e)=>setMatrix12(e.value)} isInt={false} decimal={2}/>
      <SliderInput value={matrix13} min={-2} max={2} step={0.01} default={0} onChange={(e)=>setMatrix13(e.value)} isInt={false} decimal={2}/>

      <SliderInput value={matrix21} min={-2} max={2} step={0.01} default={0} onChange={(e)=>setMatrix21(e.value)} isInt={false} decimal={2}/>
      <SliderInput value={matrix22} min={-2} max={2} step={0.01} default={0} onChange={(e)=>setMatrix22(e.value)} isInt={false} decimal={2}/>
      <SliderInput value={matrix23} min={-2} max={2} step={0.01} default={0} onChange={(e)=>setMatrix23(e.value)} isInt={false} decimal={2}/>

      <SliderInput value={matrix31} min={-2} max={2} step={0.01} default={0} onChange={(e)=>setMatrix31(e.value)} isInt={false} decimal={2}/>
      <SliderInput value={matrix32} min={-2} max={2} step={0.01} default={0} onChange={(e)=>setMatrix32(e.value)} isInt={false} decimal={2}/>
      <SliderInput value={matrix33} min={-2} max={2} step={0.01} default={0} onChange={(e)=>setMatrix33(e.value)} isInt={false} decimal={2}/>

    </div>
  );
}
export default BoxEmboss;