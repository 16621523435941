import React from "react";
import { Tooltip } from "@material-ui/core";
import { useGlobal } from "../../../../resources/setting/global";
import UtilsObject from "../../../../resources/utils/UtilsObject";
import UtilsCanvas from "../../../../resources/utils/UtilsCanvas";
import ClipPath from "../../../../resources/fabricjs/ClipPath";

// icons
import flipHorizontal 	from "./../../../../assets/personalSVG/flip-horizontal.svg";
import flipVertical 		from "./../../../../assets/personalSVG/flip-vertical.svg";
import normalSize 			from "./../../../../assets/personalSVG/normal-size.svg";
import fitHorizontal 		from "./../../../../assets/personalSVG/fit-horizontal.svg";
import fitVertical 			from "./../../../../assets/personalSVG/fit-vertical.svg";
import fitAllSizes 			from "./../../../../assets/personalSVG/fit-all-sizes.svg";

const style = {
  button: {
    minWidth: 30,
    height: 25,
    backgroundColor: "transparent",
    border: "0",
    float: "left",
    padding: 0,
    margin: 0,
		cursor: 'pointer',
  },
  icon: {
    height: 17,
    color: "#777777",
    fill: "#777777",
  },
  division: {
    marginLeft: 20,
  },
};

export default function MenuFlipAndFit(props) {

  const { valGlobal } = useGlobal();

	const flipX = () => {
		UtilsObject.flipX( valGlobal.canvas )
	}

	const flipY = () => {
		UtilsObject.flipY( valGlobal.canvas )
	}

	const setFitHorizontal = () => {
		const c = valGlobal.canvas;
		UtilsObject.getActive( c ).then( object => {

      const {typeName} = object;
      if( typeName === 'Image' || typeName === 'Rect' || typeName === 'Square'  ) {

        const cp = new ClipPath().getClipPath( c );
        object.set({
          width: cp.width,
          left: UtilsCanvas.setPositionLeft( c, 0 ),
        });
        UtilsCanvas.renderAll( c );  

      }

		})
	}

	const setFitVertical = () => {
		const c = valGlobal.canvas;
		UtilsObject.getActive( c ).then( object => {

      const {typeName} = object;
      if( typeName === 'Image' || typeName === 'Rect' || typeName === 'Square'  ) {

        const cp = new ClipPath().getClipPath( c );
        object.set({
          height: cp.height,
          top: UtilsCanvas.setPositionTop( c, 0 ),
        });
        UtilsCanvas.renderAll( c );
      
      }

		})
	}

	const setFitAllSizes = () => {
		const c = valGlobal.canvas;
		UtilsObject.getActive( c ).then( object => {

      const {typeName} = object;
      if( typeName === 'Image' || typeName === 'Rect' || typeName === 'Square'  ) {

        const cp = new ClipPath().getClipPath( c );
        object.set({
          scaleX: 1,
          scaleY: 1,
          width: cp.width,
          height: cp.height,
          left: UtilsCanvas.setPositionLeft( c, 0 ),
          top: UtilsCanvas.setPositionTop( c, 0 ),
        });
        UtilsCanvas.renderAll( c );
  
      }

		})
	}

	const setNormalSize = () => {
		const c = valGlobal.canvas;
		UtilsObject.getActive( c ).then( object => {
			if(object.typeName === 'Image'){
				object.set({scale:1,scaleX:1,scaleY:1});
				UtilsCanvas.renderAll( c );
			}
		})
	}

  return (
   <React.Fragment>
      <Tooltip title="Flip Horizontal" placement="top">					
        <button type="button" style={{...style.button}} onClick={()=>flipX()}>
          <img src={flipHorizontal} style={style.icon}/>
        </button>
      </Tooltip>
      <Tooltip title="Flip Vertical" placement="top">					
        <button type="button" style={style.button} onClick={()=>flipY()}>
          <img src={flipVertical} style={style.icon}/>
        </button>
      </Tooltip>
      <Tooltip title="Fit Horizontal" placement="top">					
        <button type="button" style={style.button} onClick={setFitHorizontal}>
          <img src={fitHorizontal} style={style.icon}/>
        </button>
      </Tooltip>
      <Tooltip title="Fit Vertical" placement="top">					
        <button type="button" style={style.button} onClick={setFitVertical}>
          <img src={fitVertical} style={style.icon}/>
        </button>
      </Tooltip>
      <Tooltip title="Fit Horizontal and Vertical" placement="top">					
        <button type="button" style={style.button} onClick={setFitAllSizes}>
          <img src={fitAllSizes} style={style.icon}/>
        </button>
      </Tooltip>
      <Tooltip title="Normal Size (only images)" placement="top">					
        <button type="button" style={style.button} onClick={setNormalSize}>
          <img src={normalSize} style={style.icon}/>
        </button>
      </Tooltip>
    </React.Fragment>
  );
}
