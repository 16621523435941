import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SaveIcon from '@material-ui/icons/Save';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import Presentation from '../../../../client/Presentation';

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  exampleWrapper: {
    position: 'relative',
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

const actions = [
  { icon: <OndemandVideoIcon />, name: 'Presentation' },
  { icon: <SaveIcon />, name: 'Save' },
];

export default function PlusTool() {

  const classes = useStyles();
  const [direction, setDirection] = React.useState('left');
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const [openPresentation,setOpenPresentation] = useState(false);
  

  // const handleDirectionChange = (event) => {
  //   setDirection(event.target.value);
  // };

  // const handleHiddenChange = (event) => {
  //   setHidden(event.target.checked);
  // };

  const handleClose = ( name ) => {
    if( name === 'Save'){
      setOpenPresentation(true);
    }
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      {/* <Presentation setOpen={openPresentation}/> */}
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          hidden={hidden}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction={direction}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => handleClose(action.name)}
            />
          ))}
        </SpeedDial>
      </div>
  );
}