import React, {useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Backdrop, CircularProgress, LinearProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Delete } from '@material-ui/icons';
import ApiServer from "../../../../resources/conn/ApiServer";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import If from "../../../../resources/utils/If";
import UtilsFile from '../../../../resources/utils/UtilsFile';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
    minWidth: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  input: {
    display: 'none',
  },
}));

export default function BrandsUpdate(props) {

  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);

  const [id, setId] = useState(null);
  const [clientId, setClientId] = useState(0);
  const [label, setLabel] = useState('');
  const [fileName, setFileName] = useState('');

  // ------------------------------------------------------------------ file upload
  const [fileUpload, setFileUpload] = useState(null);
  const [fileUploadName, setFileUploadName] = useState('');
  const [fileUploadSize, setFileUploadSize] = useState('');
  const [fileUploadLoad, setFileUploadLoad] = useState(0);
  const [fileUploadSubmitEnabled, setFileUploadSubmitEnabled] = useState(true);
  // ------------------------------------------------------------------ file upload


  // ------------------------------------------------------------------ file upload

  const formFileClear = (event) => {
    setFileUpload(null);
    setFileUploadName('');
    setFileUploadSize('');
    setFileUploadLoad(0);
    setFileUploadSubmitEnabled(true);

    setFileName(null);
  }

  const onChangeFile = (event) => {

    const f = event.target.files[0];

    if( f === undefined) return;

    const ext = '.' + f.name.split('.').pop(); // extension 
    const nname = String(f.name).substr(0,15); // new name reduce
    const fileUploadPath = 'brands';

    setFileUpload( f );
    setFileUploadName( nname + ext );
    setFileUploadSize( UtilsFile.formatBytes(f.size,1) );

    setSubmitEnabled(false);
    setFileUploadSubmitEnabled(false);

    const form = new FormData();
    form.append('file',f);
    form.append('size',f.size);

    new ApiServer().post( 'upload', fileUploadPath , form, (e)=>{

      setFileUploadLoad( e.percent );
      if(e.percent === 100){
        setSubmitEnabled(true);
        setFileUploadSubmitEnabled(true);
      }

    }).then((e)=>{
      console.log(e);
      if( fileUploadLoad === 100 ){
        setSubmitEnabled(true);
        setFileUploadSubmitEnabled(true);
      }
      setFileName(fileUploadPath+'/' + e.data.filename);

    }).catch((e)=>{
      enqueueSnackbar('Error upload file',{variant:'error'});
      setSubmitEnabled(true);
      setFileUploadSubmitEnabled(true);
      formFileClear();

      setFileName(null);
    });

  }
  // ------------------------------------------------------------------ file upload

  const getId = ( v ) => {

    setId( v );
    setLoading( true );

    new ApiServer().get( 'brands', 'get/' + v ).then(( e )=>{
      console.log( e.statusText, e);
      if( e.statusText === 'OK' && Array.isArray(e.data) ){
        
        if(e.data.length === 0) {
          setLoading( false );
          enqueueSnackbar('Not found',{variant:'error'});
          return;
        }

        const data = e.data[0];
        setId(data.id);
        setClientId(data.client_id);
        setLabel(data.label);
        setFileName(data.file_name);

        setSubmitEnabled( true );
        setLoading( false );

      }
    
    }).catch((err)=>{
      console.log( err.statusText, err);
      setSubmitEnabled( true );
      setLoading( false );
    });

  };

  const onSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();

    if(submitEnabled === false) return;

    setSubmitEnabled( false );

    const data = {
      label: String(label).trim(),
      client_id: Number(clientId),
      file_name: String(fileName).trim() || '',
    }

    console.log('UPDATE');
    new ApiServer().put( 'brands', 'update/' + id, data ).then(( e )=>{

      if(e.statusText==='OK'){
        GlobalEvents.dispatch('updateBrands');
        enqueueSnackbar('Update!', {variant:'success'});
        clearAll();

        // ToolDialog close
        if(props.hasOwnProperty('setOpen')){
          props.setOpen(false);
        }
        
      }
      setSubmitEnabled( true );
    
    }).catch((err)=>{

      console.log( err.statusText, err);
      enqueueSnackbar('Error!', {variant:'error'});
      setSubmitEnabled( true );

    });

  };

  const onChange = (event) => {

    if(event === undefined) return;

    const { value, name } = event.target;

    switch (name) {
      case 'label':     setLabel( value ); break;
      case 'client_id': setClientId( value ); break;
      case 'file_name': setFileName( value ); break;
      default: console.log('Nenhum');
        break;
    }
  };

  const clearAll = () => {
    setSubmitEnabled(true);
    setId(null);
    setClientId(0);
    setLabel('');
    setFileName('');
  }

  const clearOldFile = () => {
    setFileName('');
    formFileClear();
  }

  useEffect(()=>{
    if( Number(props.data.id) > 0 ){
      getId( props.data.id );
      setSubmitEnabled( false );
    }
  },[props]);

  return (
    <Container component="main" maxWidth="xs">
      <LinearProgress style={{display: (!loading?'none':'block'), minWidth:200}} />
      <div style={{display: (loading?'none':'block')}}>
        <form className={classes.form} noValidate autoComplete="off" >
          <Grid container spacing={2}>
            {/* start old name file */}
            <If value={String(fileName).length<6}>
              <If value={fileUpload === null}>
                <Grid item xs={12} sm={12}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="upload_file"
                    // multiple
                    type="file"
                    onChange={onChangeFile}
                  />
                  <label htmlFor="upload_file">
                    <Button variant="contained" component="span" disableElevation={true} fullWidth style={{padding:7}} disabled={!fileUploadSubmitEnabled}>
                    upload file
                    </Button>
                  </label>
                </Grid>
                <LinearProgress variant="determinate" value={fileUploadLoad} fullWidth />
              </If>
              <If value={fileUpload !== null}>
                <Grid container spacing={1} xs={12} sm={12} style={{fontSize:'12px'}}>
                  <Grid item xs={7} sm={7} style={{paddingLeft:15}}>
                  {fileUploadLoad === 0 || fileUploadLoad === 100 ? '' : (fileUploadLoad + '% - ')} 
                  {fileUploadName}
                  </Grid>
                  <Grid item xs={3} sm={3}>
                  {fileUploadSize} 
                  </Grid>
                  <Grid item xs={2} sm={2} style={{paddingRight:5,textAlign:'right',cursor:'pointer'}}>
                    <Delete onClick={()=>formFileClear()}/>
                  </Grid>
                </Grid>
              </If>
            </If>
            {/* end old name file */}
            <If value={String(fileName).length>6}>
              <Grid container spacing={1} xs={12} sm={12} style={{fontSize:'12px'}}>
                <Grid item xs={10} sm={10} style={{paddingLeft:15,paddingTop:7}}>
                {fileName || 'Not found'}
                </Grid>
                <Grid item xs={2} sm={2} style={{paddingRight:5,textAlign:'right',cursor:'pointer'}}>
                  <Delete onClick={()=>clearOldFile()}/>
                </Grid>
              </Grid>
            </If>
            <Grid item xs={12} sm={12}>
              <TextField 
                InputLabelProps={{ shrink: true }} shrink
                autoComplete="brandsname"
                name="label"
                variant="outlined"
                fullWidth
                label="Label"
                autoFocus
                onChange={onChange}
                value={label || ''}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                disabled={!submitEnabled}
                type="no_submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Update
              </Button>
            </Grid>
          </Grid>

        </form>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}