import React from "react";
import SelectMaterial from './../../../../resources/component/testing/SelectMaterial';
import InitialScreen from './../../../../resources/component/testing/InitialScreen';
import TechnicalReportPDF from './../../../../resources/component/testing/TechnicalReportPDF';

export default class Panel2 extends React.Component {
  
  render() {
    return (
      <React.Fragment>
        <div style={{padding:10}}>
          {/* <SimpleBackdrop></SimpleBackdrop>
          <IntegrationNotistack></IntegrationNotistack> */}
          <SelectMaterial/>
          <InitialScreen/>
          <TechnicalReportPDF/>
        </div>
      </React.Fragment>
    );
  }
}