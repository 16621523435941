import React, { useEffect, useState } from "react";
import GlobalEvents from "../../../event/GlobalEvents";
import UtilsArray from "../../../utils/UtilsArray";

const PIXEL_TO_MILLIMETER = 0.2645833333;
const MILLIMETER_TO_PIXEL = 3.7795275591;

const styles = {
  box: { 
    width: '10px', 
    height: '10px', 
    borderRadius: 4, 
    marginRight: 2, 
    float: 'left', 
    backgroundColor: '#ffffff', 
    border: `1px solid '#eeeeee'}` 
  }
}

export default function ElementColors() {

  // const [activeObject, setActiveObject] = useState(null);
  const [listColors, setListColors] = useState([]);

  // const changeActiveObject = (event) => {
  //   setTimeout(() => {
  //     if (event && event.hasOwnProperty("activeObject")) {
  //       if (event.activeObject !== null) {
  //         setActiveObject(event.activeObject);
  //       } else {
  //         setActiveObject(null);
  //       }
  //     } else {
  //       setActiveObject(null);
  //     }
  //   }, 1000);
  // };


  const readColors = (e) => {
    if (!e.canvas) return;
    e = e || {};

    const objects = e.canvas._objects || [];
    // const key = e.hasOwnProperty('key') ? e.key : null ;
    const fill = e.activeObject ? e.activeObject.fill : null;
    // let keyArr = undefined ;
    let fillArr = undefined;
    setListColors([]);
    let colors = [];
    objects.forEach((el) => {
      if (!el.hasOwnProperty("_objects")) {
        // keyArr = el.hasOwnProperty('key') ? el.key : undefined ;
        fillArr = el.hasOwnProperty("fill") ? el.fill : undefined;
        const f = String(el.fill).toLowerCase();
        if (
          f &&
          f.indexOf("#") === 0 &&
          f !== "#eeeeee" &&
          f !== "#ffffff" &&
          f !== "#dddddd" &&
          f.length > 5
        ) {
          colors.push({
            color: el.fill,
            name: "",
            isActive: fill === fillArr ? true : false,
          });
        }
      }
    });
    // const unique = colors.filter((value, index, self) => self.indexOf(value) === index);
    const unique = UtilsArray.unique(colors, ["color"]);
    setListColors(unique);
  };

  // global event active object
  useEffect(() => {
    GlobalEvents.on("changeActiveObject", readColors);
    return () => {
      GlobalEvents.off("changeActiveObject", readColors);
    };
  }, []);

  return (
    <div>
      {listColors.map( el => (
        <span style={{...styles.box, backgroundColor: el.color, border: `1px solid ${el.isActive ? '#000000' : '#777777'}`}}
          title={el.color}>
        </span>
      ))}
    </div>
  );
}
