import React from "react";
import { Grid } from "@material-ui/core";

// icons
import MenuHistory from "../contextMenu/MenuHistory";
import MenuZoom from "../contextMenu/MenuZoom";
import ElementData from "../../../../resources/component/restrict/elements/ElementData";

export default function SectionBottom() {

  // const { valGlobal } = useGlobal();

  // const removeitem = () => {
  //   UtilsCanvas.remove(valGlobal.canvas, valGlobal.canvas.getActiveObjects());
  // };

  // const removeall = () => {
  //   if (window.confirm("Remove all elements and create a new file?")) {
  //     new UtilsCanvas().clear(valGlobal.canvas);
  //   }
  // };

  return (
    <Grid container>
      <Grid item sm={8} md={8} lg={8}>
				<ElementData/>
			</Grid>
      <Grid item sm={4} md={4} lg={4}>
				<Grid container>
					<Grid item xs={3} sm={3} md={3}>
						<MenuHistory />
					</Grid>
					<Grid item xs={9} sm={9} md={9}>
						<MenuZoom />
					</Grid>
				</Grid>
			</Grid>
    </Grid>
  );
}
