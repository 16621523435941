import { Container, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import MenuBar from "../../layouts/dashboard/MenuBar";
import ColorsListSimple from "./collection/ListSimple";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    marginBottom: 100,
  },
}));

export default function Colors( props ) {

  const classes = useStyles();

  return (
    <React.Fragment>
      <MenuBar/>
      <Container component="main" maxWidth="md">
        <Grid container className={classes.paper} >
          <Grid item xs={12}>
            <ColorsListSimple/>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );

};
