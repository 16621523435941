import React from "react";
import { Tooltip } from "@material-ui/core";
import { useGlobal } from "../../../../resources/setting/global";
import UtilsObject from './../../../../resources/utils/UtilsObject';

// icons
// https://seekicon.com/free-icon/rotate-right-icon_3

// align objects moving
// https://jsfiddle.net/aphillips8/31qbr0vn/1/

// try
// https://stackoverflow.com/questions/47408816/object-alignment-in-fabric-js?rq=1

// align selected objects
// https://jsfiddle.net/rekrah/xxrqbhph/

// align center of artboard
// https://jsfiddle.net/rekrah/9k9hd49u/

// grid
// https://jsfiddle.net/squeral/m4rYZ/

const style = {
	button: {
    minWidth: 30,
		height: 25,
		backgroundColor: 'transparent',
		border: '0',
		float: 'left',
		padding: 0,
		margin: 0,
		cursor: 'pointer',
	},
	icon: {
		height: 17,
		color: '#777777',
		fill: '#777777',
	},
	division: {
		marginLeft: 20,
	}
}

export default function MenuAlign(props) {

	const { valGlobal } = useGlobal();

  /*
  align objects group
  TODO
  */
  //https://jsfiddle.net/rekrah/xxrqbhph/

	const align = ( direction ) => {
		UtilsObject.align( valGlobal.canvas, direction );
	}

	const alignment = ( direction, v ) => {
    var activeObj = valGlobal.canvas.getActiveObject() || valGlobal.canvas.getActiveGroup();
    if (direction !== '' && activeObj) {
      if( v === 0)
      process_align(direction, activeObj);
      else
      process_align2(direction, activeObj);
      activeObj.setCoords();
      // valGlobal.canvas.requestRenderAll();
    } else {
      alert('Please select a item');
      return false;
    }
  }

  /* Align the selected object */
function process_align(val, activeObj) {
  
  const bound = activeObj.getBoundingRect()

  switch (val) {

    case 'left':
      activeObj.set({
        left: activeObj.left - bound.left 
      });
      break;
    case 'right':
      activeObj.set({
        left: valGlobal.canvas.width - bound.width/2
      });
      break;
    case 'top':
      activeObj.set({
        top: activeObj.top - bound.top
      });
      break;
    case 'bottom':
      activeObj.set({
        top: valGlobal.canvas.height - bound.height/2
      });
      break;
    case 'center':
      activeObj.set({
        left: valGlobal.canvas.width / 2
      });
      break;
    case 'middle':
      activeObj.set({
        top: valGlobal.canvas.height / 2
      });
      break;
  }
}




/* Align the selected object */
function process_align2( val, activeObj) {
  let left;
  let top;

  switch (val) {

    case 'left':
      if(activeObj.angle <= 90) {
        left = activeObj.aCoords.tl.x - activeObj.aCoords.bl.x;
      }
      if(activeObj.angle > 90 && activeObj.angle <= 180) {
        left = activeObj.aCoords.tl.x - activeObj.aCoords.br.x;
      }
      if(activeObj.angle > 180 && activeObj.angle <= 270) {
        left = activeObj.aCoords.tl.x - activeObj.aCoords.tr.x;
      }
      if(activeObj.angle > 270) {
        left = 0;
      }
      activeObj.set({
        left: left
      });
      break;
    case 'right':
      if(activeObj.angle <= 90) {
        left = activeObj.aCoords.tl.x + (valGlobal.canvas.width - activeObj.aCoords.tr.x);
      }
      if(activeObj.angle > 90 && activeObj.angle <= 180) {
        left = valGlobal.canvas.width;
      }
      if(activeObj.angle > 180 && activeObj.angle <= 270) {
        left = activeObj.aCoords.tl.x + (valGlobal.canvas.width - activeObj.aCoords.bl.x);
      }
      if(activeObj.angle > 270) {
        left = activeObj.aCoords.tl.x + (valGlobal.canvas.width - activeObj.aCoords.br.x);
      }
      activeObj.set({
        left: left
      });
      break;
    case 'top':
      if(activeObj.angle <= 90) {
        top = 0;
      }
      if(activeObj.angle > 90 && activeObj.angle <= 180) {
        top = activeObj.aCoords.tl.y - activeObj.aCoords.bl.y;
      }
      if(activeObj.angle > 180 && activeObj.angle <= 270) {
        top = activeObj.aCoords.tl.y - activeObj.aCoords.br.y;
      }
      if(activeObj.angle > 270) {
        top = activeObj.aCoords.tl.y - activeObj.aCoords.tr.y;
      }
      activeObj.set({
        top: top
      });
      break;
    case 'bottom':
      if(activeObj.angle <= 90) {
        top = activeObj.aCoords.tl.y + (valGlobal.canvas.height - activeObj.aCoords.br.y);
      }
      if(activeObj.angle > 90 && activeObj.angle <= 180) {
        top = activeObj.aCoords.tl.y + (valGlobal.canvas.height - activeObj.aCoords.tr.y);
      }
      if(activeObj.angle > 180 && activeObj.angle <= 270) {
        top = valGlobal.canvas.height;
      }
      if(activeObj.angle > 270) {
        top = activeObj.aCoords.tl.y + (valGlobal.canvas.height - activeObj.aCoords.bl.y);
      }
      activeObj.set({
        top: top
      });
      break;
    case 'centerH':
      activeObj.viewportCenterH();
      break;
    case 'centerV':
      activeObj.viewportCenterV();
      break;
  }
}

// fabric.Object.prototype.getAbsolute = function(key) {
//   if (this.group) {
//     if (key === 'top') {
//       return this.calcTransformMatrix()[5];
//     } else if (key === 'left') {
//       return this.calcTransformMatrix()[4];
//     } else if (key === 'angle') {
//       return this.angle + this.group.angle;
//     } else if (key === 'scaleX') {
//       return this.scaleX * this.group.scaleX;
//     } else if (key === 'scaleY') {
//       return this.scaleY * this.group.scaleY;
//     }
//   }
//   return this[key];
// };

	return (
		<React.Fragment>

      <Tooltip title="Align Left" placement="top">
        <button typebtn="button" style={style.button} onClick={()=>align('left')} >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M4 3c.41 0 .75.34.75.75v16.5a.75.75 0 1 1-1.5 0V3.75c0-.41.34-.75.75-.75zm5 3h9a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2zm0 7h4a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-1c0-1.1.9-2 2-2zm0-5.5a.5.5 0 0 0-.5.5v1c0 .28.22.5.5.5h9a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9zm0 7a.5.5 0 0 0-.5.5v1c0 .28.22.5.5.5h4a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H9z"></path>
          </svg>
        </button>
      </Tooltip>
      <Tooltip title="Align Center" placement="top">
        <button type="button" style={style.button} onClick={()=>align('center')} >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M11.25 13v-2H7a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h4.25V3.75a.75.75 0 1 1 1.5 0V6H17a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-4.25v2H14a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1.25v2.25a.75.75 0 1 1-1.5 0V18H10a2 2 0 0 1-2-2v-1c0-1.1.9-2 2-2h1.25zM7 7.5a.5.5 0 0 0-.5.5v1c0 .28.22.5.5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H7zm3 7a.5.5 0 0 0-.5.5v1c0 .28.22.5.5.5h4a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-4z"></path>
          </svg>
        </button>
      </Tooltip>
      <Tooltip title="Align Right" placement="top">
        <button type="button" style={style.button} onClick={()=>align('right')} >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M20 3a.75.75 0 0 0-.75.75v16.5a.75.75 0 1 0 1.5 0V3.75A.75.75 0 0 0 20 3zm-5 3H6a2 2 0 0 0-2 2v1c0 1.1.9 2 2 2h9a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm0 7h-4a2 2 0 0 0-2 2v1c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2zm0-5.5c.28 0 .5.22.5.5v1a.5.5 0 0 1-.5.5H6a.5.5 0 0 1-.5-.5V8c0-.28.22-.5.5-.5h9zm0 7c.28 0 .5.22.5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1c0-.28.22-.5.5-.5h4z"></path>
          </svg>
        </button>
      </Tooltip>
      <Tooltip title="Align Top" placement="top">
        <button type="button" style={style.button} onClick={()=>align('top')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M21 4c0 .41-.34.75-.75.75H3.75a.75.75 0 0 1 0-1.5h16.5c.41 0 .75.34.75.75zM11 9v9a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2zm7 0v4a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2zM9.5 9a.5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5v9c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5V9zm7 0a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v4c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5V9z"></path>
          </svg>
        </button>
      </Tooltip>
      <Tooltip title="Align Middle" placement="top">
        <button type="button" style={style.button} onClick={()=>align('middle')} >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M11 11.25h2V7c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2v4.25h2.25a.75.75 0 1 1 0 1.5H18V17a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-4.25h-2V14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1.25H3.75a.75.75 0 1 1 0-1.5H6V10c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2v1.25zM16.5 7a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v10c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5V7zm-7 3a.5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5v4c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5v-4z"></path>
          </svg>
        </button>
      </Tooltip>
      <Tooltip title="Align Bottom" placement="top">
        <button type="button" style={style.button} onClick={()=>align('bottom')} >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M21 20c0 .41-.34.75-.75.75H3.75a.75.75 0 1 1 0-1.5h16.5c.41 0 .75.34.75.75zM11 6v9a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2zm7 5v4a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-4c0-1.1.9-2 2-2h1a2 2 0 0 1 2 2zM9.5 6a.5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5v9c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5V6zm7 5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v4c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5v-4z"></path>
          </svg>
        </button>
      </Tooltip>
      
      {process.env.NODE_ENV === 'developer'?(
        <>
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("left",0)}>L</button>
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("center",0)}>C</button>
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("right",0)}>R</button>
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("top",0)}>T</button>
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("middle",0)}>M</button>
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("bottom",0)}>B</button>
          |
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("left",1)}>L</button>
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("center",1)}>C</button>
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("right",1)}>R</button>
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("top",1)}>T</button>
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("middle",1)}>M</button>
          <button style={{fontSize:8,width:10}} onClick={()=>alignment("bottom",1)}>B</button>
        </>
      ):''}

		</React.Fragment>
	)

}