import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, InputBase, Paper } from "@material-ui/core";
import { fade, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SearchIcon from '@material-ui/icons/Search';
import { useGlobal } from "../../../../../../resources/setting/global";
import BoxPanel from "../../../../../../resources/component/general/views/BoxPanel";
import ApiServer from "../../../../../../resources/conn/ApiServer";
import UtilsCanvas from "../../../../../../resources/utils/UtilsCanvas";
import GlobalEvents from "../../../../../../resources/event/GlobalEvents";
import Pattern from "../../../../../../resources/fabricjs/Pattern";
import SliderInput from "../../../../../../resources/component/general/inputs/SliderInput";

const useStyles = makeStyles((theme) => ({
  appBar: {
    //position: 'relative',
  },
  toolBar: {
    // padding: '0px 15px',
    minHeight: 40,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  parent: {
    color: '#555555',
    fontSize: 12,
    paddingBottom: 3,
  },
  dialog: {
    marginTop: 45,
    backgroundColor: '#eeeeee',
    flex: 1,
  },
  dialogContainer: {
    paddingTop: 20,
    backgroundColor: '#eeeeee',
    flex: 1,
  },
  container: {
    padding: 10
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  gridList: {
    flex: 1,
    padding: 20,
  },
  paper: {
    padding: theme.spacing(2),
    //textAlign: 'center',
    // color: theme.palette.text.secondary,
    //whiteSpace: 'nowrap',
    marginBottom: theme.spacing(0),
    fontSize: 14,
    fontWeight: 400,
    color: '#000000',
    cursor: 'pointer',
  },
  button: {
    paddingTop: 6,
    paddingBottom: 4,
    textTransform: "none",
    backgroundColor: '#2a82db',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BoxPatternMP = (props) => {
  
  const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST;
  const {valGlobal} = useGlobal();

  // open list
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [filter, setFilter] = useState('');

  // pattern
  const [url,setUrl] = useState('');
  const [name,setName] = useState('');
  const [scale,setScale] = useState(1);
  const [angle,setAngle] = useState(0);
  const [padding,setPadding] = useState(0);
  const [offsetX,setOffsetX] = useState(0);  
  const [offsetY,setOffsetY] = useState(0);  

  const getMaterials = () => {
    //new ApiServer().get( 'materials', 'get-images',)
    new ApiServer().get( 'materials', 'get-images' ).then((e)=>{
      if (e.statusText === "OK" && e.data !== undefined ) {
        setList(e.data);
        setListFilter(e.data);
      }else{
        setList([]);
        setListFilter([]);
      }
  });
  }

  const onChangeFilter = ( filter ) => {
    setFilter( filter );
    if( !filter ) setListFilter( list ); 
    setListFilter(
      list.filter((el) => String(el.name).toLowerCase().indexOf( String(filter).toLowerCase() ) > -1 )
    );
  };

  const getMaterialFromList = (el) => {
    setUrl(el.file_name);
    setName(el.name);
    handleClose();
  }

  const handleClickOpen = () => {
    setOpen(true);
    getMaterials();
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ---------------------------------------------------------- angle
  const onChangeAngleSlider = ( e ) => {
    setAngle( e.value );
    // se o angulo maior que zero, entao torna o padding 0
    if( e.value > 0 )
      setPadding( 0 );
  };

  // ---------------------------------------------------------- set
  const setPattern = () => {

    let mp = valGlobal.canvas.item(1);

    if( mp === null || mp === undefined ) {
      setUrl('');
      setName('');
      return;
    }

    if( !url || typeof url !== 'string' ) return;

    const object = valGlobal.canvas._objects[1];
  
    new Pattern().add( valGlobal.canvas, {
      object: object,
      isFill: true,
      name: name,
      url: url,
      scale: scale,
      angle: angle,
      padding: padding,
      offsetX: offsetX,
      offsetY: offsetY,
      //filters: [],
    });

    // pre-set document
    object.document = object.document || {};
    object.document.pattern = object.document.pattern || {};
    object.document.pallete = object.document.pallete || {};
    
    // isFill: true,
    object.set({
      document: {
        ...object.document,
        pallete: {
          ...object.document.pallete,
          fill: {
            name: false,
            code: false,
            hex: false,
          },
        },
      },
    });

    valGlobal.canvas.history.registerTimeOut();

  };

  const getPattern = () => {

    let mp = valGlobal.canvas.item(1);

    if( mp === null || mp === undefined ) {
      setUrl('');
      setName('');
      return;
    }

    if( mp.typeName !== 'RectMP') {
      UtilsCanvas.addRectMP( valGlobal.canvas );
    }

    if( 
      !mp.hasOwnProperty('document') || 
      !mp.document.hasOwnProperty('pattern') || 
      !mp.document.pattern.hasOwnProperty('fill') || 
      !mp.document.pattern.fill.hasOwnProperty('name') || 
      !mp.document.pattern.fill.name ) {
      // resetValues( false );
      return;
    }

    const values = mp.document.pattern.fill;

    console.log('------------------------------');
    console.log('---CHANGE-PATTERN IS APPLY----');
    console.log('------------------------------');

    console.table(values);

    setUrl( values.url || '' );
    setName( values.name || '' );
    setScale( values.scale || 0 );
    setAngle( values.angle || 0 );
    setPadding( values.padding || 0 );
    setOffsetX( values.offsetX || 0 );
    setOffsetY( values.offsetY || 0 );
    
  }

  useEffect(()=>{
    setPattern();
  },[scale,angle,padding,offsetX,offsetY,url]);

  useEffect(()=>{
    getPattern();
    GlobalEvents.on('changeActiveObject', getPattern);
    return ()=>{
      GlobalEvents.off('changeActiveObject', getPattern);
    }
  },[]);

  return (
    <BoxPanel title="Raw Material">

        <div style={{width:'100%',height:'100px',borderRadius:7,backgroundColor:'#dddddd',backgroundImage:`url(${IMAGE_HOST + '200/' + url})`,marginBottom:'10px'}}>
          <div style={{paddingTop:30,textAlign:'center'}}>
            <Button variant="contained" color="primary" onClick={handleClickOpen} className={classes.button}>
              Material
            </Button>
          </div>
        </div>

        <React.Fragment>
          <SliderInput value={scale} min={0.1} max={1} step={0.01} default={1} title="Scale" onChange={(e)=>setScale(e.value)} isInt={false} decimal={2}/>
          <SliderInput value={angle} min={0} max={180} title="Angle" onChange={onChangeAngleSlider} isInt={true}/>
          <SliderInput value={offsetX} min={-200} max={200} default={0} title="Offset X" onChange={(e)=>setOffsetX(e.value)}/>
          <SliderInput value={offsetY} min={-200} max={200} default={0} title="Offset Y" onChange={(e)=>setOffsetY(e.value)}/>
          <SliderInput value={padding} min={-100} max={0} default={0} title="Padding" onChange={(e)=>setPadding(e.value)}/>
        </React.Fragment>

        <div>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
            <AppBar position="fixed" className={classes.appBar} color="default" elevation={0} style={{boxShadow: '0 1px 2px rgb(100,100,100,0.3)'}}>
              <Toolbar className={classes.toolBar}>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Box variant="h6" className={classes.title}>
                  Material
                </Box>

                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon/>
                  </div>
                  <InputBase
                    placeholder="Search…"
                    value={filter}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e)=>onChangeFilter(e.target.value)}
                  />
                </div>
                {/* <Typography variant="h6" className={classes.title}>
                  Material
                </Typography> */}
                {/* <Button autoFocus color="inherit" onClick={handleClose}>
                  save
                </Button> */}
              </Toolbar>
            </AppBar>
            <div className={classes.dialogContainer}>
              <Container component="main" maxWidth="md" className={classes.container}>
                <Grid container spacing={3}>

                  <Grid item xs={12} sm={12} style={{display: url ? 'block' : 'none' }}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={0}>
                        <Grid item >
                          {typeof url === 'string' && (
                            <img src={IMAGE_HOST + '100/' + url} alt={name} style={{cursor:'pointer',height:'50px'}} />
                          )}
                        </Grid>
                        <Grid item style={{textAlign:'right',fontSize:'25px',paddingTop:7,paddingLeft:20}}>
                          {name}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  {listFilter.map((el) => (
                    <Grid item xs={12} sm={6} md={4} key={el.id}>
                      <Paper className={classes.paper} onClick={()=>getMaterialFromList(el)}>
                        <Grid container spacing={0}>
                          <Grid item xs={3}>
                            {el.file_name ? (
                              <img src={IMAGE_HOST + '100/' + el.file_name} alt={el.name} style={{cursor:'pointer',height:50,maxWidth:50}} />
                            ): null}
                          </Grid>
                          <Grid item xs={8}>
                            {el.name}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>                
              </Container>
            </div>
          </Dialog>
        </div>

      </BoxPanel>
    );
}
export default BoxPatternMP;