import {
  Breadcrumbs,
  Container,
  Grid,
  IconButton,
  Link as LinkBread,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Delete,
  Edit,
} from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import ApiServer from "../../../../resources/conn/ApiServer";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import ModalDialog from "../../../../resources/component/general/modal/ModalDialog";
import ColorsUpdate from "./../formulary/Update";
import ColorsCreate from "./../formulary/Create";

// const useStyles = makeStyles((theme) => ({
//   breadcrumbsBar: {
//     padding: 15,
//   },  
// }));

export default function ColorsListSimple() {

  // const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [id, setId] = useState(null);
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [filter, setFilter] = useState('');

  const listage = () => {
    new ApiServer()
      .get("colors", "get")
      .then((e) => {
        if (e.statusText === "OK" && e.data !== undefined ) {
          setList(e.data);
          setListFilter(e.data);
        }else{
          setList([]);
          setListFilter([]);
        }
      })
      .catch((err) => {
        console.log(err.statusText, err);
      });
  };

  const onEdit = ( event, { id } ) => {
    event.preventDefault();
    event.stopPropagation();
    setId( id );
    setOpenEdit( true ); 
  };

  const onNew = () => {
    setOpenNew( true ); 
  };

  const onDelete = ({id}) => {
    if(!window.confirm('Delete item?')) return;
    new ApiServer().delete( 'colors', `delete/${id}` ).then(( res )=>{
      console.log( res );
      if( res.statusText === 'OK' ){
        listage();
        enqueueSnackbar('Deleted',{variant:'success'});
      }
    }).catch((err)=>{
      enqueueSnackbar('Error',{variant:'error'});
    });
  };

  const onChangeFilter = ( filter ) => {
    if( !filter ) setListFilter( list ); 
    setListFilter(
      list.filter((el) => String(el.color_name).toLowerCase().indexOf( String(filter).toLowerCase() ) > -1 )
    );
  };

  useEffect(() => {
    listage();
    GlobalEvents.on('updateColors',listage);
    return ()=>{
      GlobalEvents.off('updateColors',listage);
    }
  }, []);

  return (
    <React.Fragment>
      <Container component="main">

        <Grid container style={{padding:15}}>
          <Grid item xs={6}>
            <Breadcrumbs aria-label="breadcrumb" style={{margin:5}}>
              <LinkBread color="inherit" href="/">
                Home
              </LinkBread>
              <Typography color="textPrimary">
                Colors
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid xs={6} container justify="flex-end">
            <IconButton aria-label="add" onClick={()=>onNew()} title="Add">
              <AddIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{marginTop:5}}>
            <TextField 
              InputLabelProps={{ shrink: true }} shrink
              autoComplete="materialsfilter"
              name="materials_filter"
              variant="outlined"
              fullWidth
              label="Search"
              size="small"
              onChange={(event)=>{ setFilter(event.target.value); onChangeFilter(event.target.value) }}
              value={filter}
            />
          </Grid>
        </Grid>

        <List dense={true}>
          {listFilter.map((el) => (
            <ListItem style={{marginLeft:parseInt(20*el.depth)}} key={el.id}>
              <ListItemAvatar>
                <div style={{ width:50, height:50, marginRight:20, backgroundColor:(el.hex?el.hex:'#eeeeee'), }}></div>
              </ListItemAvatar>
              <ListItemText
                primary={el.color_name}
                secondary={`${el.hex || ''} | pantone ${el.pantone || ''} | code: ${el.code || ''}`}
              />
              <ListItemSecondaryAction>

                <IconButton aria-label="edit" onClick={ event => onEdit( event, el )}
                title="Edit">
                <Edit />
                </IconButton>

                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={ event => onDelete( event, el )}
                  title="Delete">
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <ModalDialog open={openNew} setOpen={(v)=>setOpenNew(v)} title='Create'>
          <ColorsCreate data={{}} setOpen={(v)=>{ if(v === false) setOpenNew(v) }}/>
        </ModalDialog>

        <ModalDialog open={openEdit} setOpen={(v)=>setOpenEdit(v)} title='Update'>
          <ColorsUpdate data={{id:id}} setOpen={(v)=>{ if(v === false) setOpenEdit(v) }}/>
        </ModalDialog>

        {/* <Nestable 
            collapsed={false}
            items={processTree} 
            renderItem={renderItem2} 
            // ref={el => this.refNestable = el}        
        /> */}

      </Container>
    </React.Fragment>
  );
}
