import React from 'react';
import Axios from 'axios';

export default class ApiServer extends React.Component {

  // Upload file
  // -------------------------------------------------------------------------------
  // new ApiServer().post( 'upload/materials', '', form, ( e ) => setLoaded( e.loaded ) )
  // .then( ( res ) => { // then print response status
  
  //   console.log( res.statusText );

  //   console.log( 'destination:', res.data.destination );
  //   console.log( 'filename:', res.data.filename );
  //   console.log( 'mimetye:', res.data.mimetye );
  //   console.log( 'path:', res.data.path );
  //   console.log( 'size:', res.data.size );

  // }).catch( err => console.log( err ) );
  // -------------------------------------------------------------------------------
  
  // const myPromise = new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve('foo');
  //   }, 300);
  // });
  
  // myPromise
  //   .then(handleResolvedA, handleRejectedA)
  //   .then(handleResolvedB, handleRejectedB)
  //   .then(handleResolvedC, handleRejectedC);

  // Promise.all[].then(( response )=>{
  //   response[0];
  //   response[1];
  // })

  host = ( table, action ) => {
    action = action ? `${action}/` : '' ;
    //console.log( process.env.REACT_APP_API_HOST + `${table}/${action}` );
    const host = process.env.REACT_APP_API_HOST;
    host || ( host = 'https://server.gnnc.com.br/api/' );
    return `${host}${table}/${action}`;
  } 

  token = () => {
    return 'token';
  }

  // sample image to download
  // https://static-cse.canva.com/blob/142356/removing-background-images_Unsplash.bad043c2.jpeg

  // https://masteringjs.io/tutorials/axios/get-query-params
  // https://github.com/axios/axios

  get = async ( table, action, data, options ) => {

    data = data || {}; 
    
    // no options
    if ( options === undefined ) {
      return await Axios.get( this.host( table, action ), { params: data } );
    }
    // options set
    if ( typeof options === 'object' ) {
      return await Axios.get( this.host( table, action ), { params: { ...data, ...options } || {} } );
    }
    // options is function callback
    if ( typeof options === 'function' || options instanceof Function ) {
      return await Axios.get( this.host( table, action ), {
        params: data,
        // receive two parameter endpoint url ,form data 
        onDownloadProgress: progressEvent => {
          const percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          options({
            loading: (percentage === 100 || percentage === 0) ? false : true ,
            loaded: ProgressEvent.loaded,
            total: ProgressEvent.total,
            percent: Number(ProgressEvent.total) < 50 ? 0 : parseInt(percentage),
           });
        }
      }).finally(()=>{
        setTimeout(()=>{
          options({ 
            loading: false ,
            loaded: 0,
            total: 0,
            percent: 100,
          });  
        },1000)
      });
    }

  }

  post = async ( table, action, data, options ) => {

    // no options
    if ( options === undefined ) {
      return await Axios.post( this.host( table, action ), data || {} );
    }
    // options set
    if ( typeof options === 'object' ) {
      return await Axios.post( this.host( table, action ), data || {}, options );
    }
    // options is function callback
    if ( typeof options === 'function' || options instanceof Function ) {
      return await Axios.post( this.host( table, action ), data || {}, {
        // receive two parameter endpoint url ,form data 
        onUploadProgress: ProgressEvent => {
          const percentage = Math.round( ProgressEvent.loaded / ProgressEvent.total * 100 );
          options({ 
            loading: (percentage === 100 || percentage === 0) ? false : true ,
            loaded: ProgressEvent.loaded,
            total: ProgressEvent.total,
            percent: Number(ProgressEvent.total) < 50 ? 0 : parseInt(percentage),
          });
        }
      }).finally(()=>{
        setTimeout(()=>{
          options({ 
            loading: false ,
            loaded: 0,
            total: 0,
            percent: 100,
          });  
        },1000)
      });
    }

    // options: 
    // {
    //   // receive two parameter endpoint url ,form data 
    //   onUploadProgress: ProgressEvent => {
    //     if( callbackProgress !== undefined ){
    //       callbackProgress({
    //         loaded: ( ProgressEvent.loaded / ProgressEvent.total * 100 )
    //       });
    //     }
    //   }
    // }

    // .then(( response )=>{

    //   console.log( response  );
    //   if( response  .status === 200 ){
    //     alert( 'tudo certo' );
    //     return {...response, status: 'success' };
    //   } else {
    //     alert( 'code: ' + response  .status );
    //     return {...response, status: 'maybe' };
    //   }

    // }).catch( (err) => { 
    //   return {...err, status: 'error'};
    // });

  }

  put = async ( table, action, data ) => {
    action = action ? action : 'update' ;
    return await Axios.put( this.host( table, action ), data || {} );
  }

  delete = async ( table, action, data ) => {
    data = data || {};
    action = action ? action : 'delete' ;
    return await Axios.delete( this.host( table, action ), {
      headers: {
        Authorization: this.token()
      },
      data: {
        ...data
      }
    });
  }



}