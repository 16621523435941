import React, { useState } from 'react';
import BoxPanel from '../../../../resources/component/general/views/BoxPanel';
import { useGlobal } from '../../../../resources/setting/global';
// import { ntc } from '@cosmicice/namethatcolor';
import TechnicalReportPDF from '../../../../resources/component/testing/TechnicalReportPDF';
import UtilsArray from '../../../../resources/utils/UtilsArray';
import UtilsCanvas from '../../../../resources/utils/UtilsCanvas';

const PIXEL_TO_MILLIMETER = 0.2645833333;
// const MILLIMETER_TO_PIXEL = 3.7795275591;

export default function PanelTechnicalReport() {

  const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST;
  const {valGlobal} = useGlobal();
  const [report, setReport] = useState([]);
  const [arrayReport, setArrayReport] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const readDocument = () => {

    if(!valGlobal.canvas) return;
    
    let listInit;
    listInit = [...valGlobal.canvas._objects];

    listInit.forEach( el => {
      if( el.typeName === 'Bend'){
        UtilsCanvas.bringToFrontBend( valGlobal.canvas, el );
        return;
      }
    });

    listInit = listInit.splice(1);
    listInit = listInit.reverse();

    const listShow = listInit.map( el => {

      // pattern fill
      let hasPatternFillData = (
        !el.hasOwnProperty('document') || !el.document ? false : (
          !el.document.hasOwnProperty('pattern') || !el.document.pattern ? false : (
            !el.document.pattern.hasOwnProperty('fill') || !el.document.pattern.fill ? false : (
              !el.document.pattern.fill.hasOwnProperty('name') || !el.document.pattern.fill.name ? false : true 
            )
          )
        )
      );
      // pattern stroke
      let hasPatternStrokeData = (
        !el.hasOwnProperty('document') || !el.document ? false : (
          !el.document.hasOwnProperty('pattern') || !el.document.pattern ? false : (
            !el.document.pattern.hasOwnProperty('stroke') || !el.document.pattern.stroke ? false : (
              !el.document.pattern.stroke.hasOwnProperty('name') || !el.document.pattern.stroke.name ? false : true 
            )
          )
        )
      );
      // pallete fill
      let hasPalleteFillData = (
        !el.hasOwnProperty('document') || !el.document ? false : (
          !el.document.hasOwnProperty('pallete') || !el.document.pallete ? false : (
            !el.document.pallete.hasOwnProperty('fill') || !el.document.pallete.fill ? false : (
              !el.document.pallete.fill.hasOwnProperty('name') || !el.document.pallete.fill.name ? false : true 
            )
          )
        )
      );
      // pallete stroke
      let hasPalleteStrokeData = (
        !el.hasOwnProperty('document') || !el.document ? false : (
          !el.document.hasOwnProperty('pallete') || !el.document.pallete ? false : (
            !el.document.pallete.hasOwnProperty('stroke') || !el.document.pallete.stroke ? false : (
              !el.document.pallete.stroke.hasOwnProperty('name') || !el.document.pallete.stroke.name ? false : true 
            )
          )
        )
      );

      // ntc
      // const fillName = typeof el.fill === 'string' && el.fill ? ntc.name(el.fill) : '';
      // const strokeName = typeof el.stroke === 'string' && el.stroke ? ntc.name(el.stroke) : '';
      // console.log(color.color); // Closest RGB Value
      // console.log(color.name); // Color Name
      // console.log(color.isExact); // True if exact color match

      return {
        typeName: el.typeName,
        // | pattern
        // fill
        hasPatternFillData:   hasPatternFillData ? ( el.document.pattern.fill.name ? el.document.pattern.fill.name : false ) : false ,
        patternFillName:      hasPatternFillData ? el.document.pattern.fill.name : false ,
        patternFillUrl:       hasPatternFillData ? ( el.document.pattern.fill.url ? el.document.pattern.fill.url : false ) : false ,
        // stroke
        hasPatternStrokeData: hasPatternStrokeData ? ( el.document.pattern.stroke.name ? el.document.pattern.stroke.name : false ) : false ,
        patternStrokeName:    hasPatternStrokeData ? el.document.pattern.stroke.name : false ,
        patternStrokeUrl:     hasPatternStrokeData ? ( el.document.pattern.stroke.url ? el.document.pattern.stroke.url : false ) : false ,
        // | document
        // fill
        hasPalleteFillData:  hasPalleteFillData ? ( el.document.pallete.fill.name ? el.document.pallete.fill.name : false ) : false ,
        palleteFillName:     hasPalleteFillData ? el.document.pallete.fill.name : false ,
        palleteFillUrl:      hasPalleteFillData ? ( el.document.pallete.fill.url ? el.document.pallete.fill.url : false ) : false ,
        palleteFillCode:     hasPalleteFillData ? ( el.document.pallete.fill.code ? el.document.pallete.fill.code : false ) : false ,
        palleteFillHex:      hasPalleteFillData ? ( el.document.pallete.fill.hex ? el.document.pallete.fill.hex : false ) : false ,
        // stroke
        hasPalleteStrokeData:hasPalleteStrokeData ? ( el.document.pallete.stroke.name ? el.document.pallete.stroke.name : false ) : false ,
        palleteStrokeName:   hasPalleteStrokeData ? el.document.pallete.stroke.name : false ,
        palleteStrokeUrl:    hasPalleteStrokeData ? ( el.document.pallete.stroke.url ? el.document.pallete.stroke.url : false ) : false ,
        palleteStrokeCode:   hasPalleteStrokeData ? ( el.document.pallete.stroke.code ? el.document.pallete.stroke.code : false ) : false ,
        palleteStrokeHex:    hasPalleteStrokeData ? ( el.document.pallete.stroke.hex ? el.document.pallete.stroke.hex : false ) : false ,
        // | color
        // fill
        fill: el.fill ? (
          typeof el.fill !== 'object' ? (
            `${String(el.fill).toUpperCase()})`
            ) : 'Texture'
        ) : false,
        // stroke
        stroke: el.stroke ? (
          typeof el.stroke !== 'object' ? (
            `${String(el.stroke).toUpperCase()}`
            ) : 'Texture'
        ) : false,
        strokeWidth: el.strokeWidth ? (
          typeof el.strokeWidth !== 'object' ? (
            el.strokeWidth
            ) : 0
        ) : false,
        width: el.width * el.scaleX,
        height: el.height * el.scaleY,
        // width: Number((el.width * el.scaleX) * PIXEL_TO_MILLIMETER).toFixed(2) + 'mm',
        // height: Number((el.height * el.scaleY) * PIXEL_TO_MILLIMETER).toFixed(2) + 'mm',
      }
    })
    setReport(listShow);
    reportToArray(listShow);
    setIsReady(true);
  }

  const reportToArray = (list) => {
    let listClone = [...list];
    listClone.reverse();
    let arr = [];
    let prex = '';
    listClone.forEach( (el,index) => {

      prex = index === 0 && el.typeName === 'RectMP' ? 'Base: ' : '';
     
      {/* FILL */}
      {/* pattern */}
      const fA = el.hasPatternFillData && (el.patternFillName && el.patternFillName );
      {/* pallete */}
      const fB = el.hasPalleteFillData && (
        el.palleteFillName && `${el.palleteFillName ? el.palleteFillName : '' } ${el.palleteFillCode ? el.palleteFillCode : '' } ${el.palleteFillHex ? el.palleteFillHex : '' }`
      );
      arr.push( fA && fB ? `${fA} ${fB}` : ( fA ? `${prex} ${fA}` : ( fB ? `${prex} ${fB}` : '')));

      {/* STROKE */}
      {/* pattern */}
      const sA = el.hasPatternStrokeData && (el.patternStrokeName && el.patternStrokeName );
      {/* pallete */}
      const sB = el.hasPalleteStrokeData && (
        el.palleteStrokeName && `${el.palleteStrokeName ? el.palleteStrokeName : '' } ${el.palleteStrokeCode ? el.palleteStrokeCode : '' } ${el.palleteStrokeHex ? el.palleteStrokeHex : '' }`
      );
      arr.push( sA && sB ? `${sA} ${sB}` : ( sA ? sA : ( sB ? sB : '')));

      //if( el.typeName === 'RectMP' ){
        arr.push( 
          'Dimensions: ' +
          'w ' + Number(el.width * PIXEL_TO_MILLIMETER).toFixed(2) + 'mm x ' +
          'h ' + Number(el.height * PIXEL_TO_MILLIMETER).toFixed(2) + 'mm' 
        );
      //}

      if( el.typeName === 'Bend' ){
        arr.push( 
          'Bend: ' + 
          Number(el.width * PIXEL_TO_MILLIMETER).toFixed(2) + 'mm x ' +
          Number(el.height * PIXEL_TO_MILLIMETER).toFixed(2) + 'mm' 
        );
      }
      
    });
    
    arr = Array.from(new Set(arr));
    arr = arr.filter( el => String(el).trim() !== '' );
    setArrayReport(arr);
  }  

  React.useEffect(()=>{
    setIsReady(false);
    readDocument();
  },[valGlobal]);

  return (
    <div style={{padding:10}}>

      <BoxPanel title="Document PDF">
        <TechnicalReportPDF textReport={arrayReport}/>
      </BoxPanel>

      <BoxPanel title="Technical Report">
        <ul style={{padding:'0 0 0 10px', paddingRight:0}}>
          {isReady ? arrayReport.map( el => (<li>{el}</li>)) : 'Loading...'}
        </ul>
      </BoxPanel>
    </div>
  );
}
