import React, { useEffect, useState } from "react";
import { useGlobal } from "../../../../../../resources/setting/global";
import Effects from "../../../../../../resources/fabricjs/Effects";
import SliderInput from "../../../../../../resources/component/general/inputs/SliderInput";
import UtilsObject from "../../../../../../resources/utils/UtilsObject";

const styles = {
  controlls: {
    padding: 10, 
    display: 'block', 
    background: '#eeeeee', 
    borderRadius: 7,
  }
}

const BoxSharpen = (props) => {
  
  // global variables
  const {valGlobal} = useGlobal();
  // filters values
  const [matrix, setMatrix] = useState([]);

  // apply filter
  const apply = ( setting ) => {
    new Effects().Sharpen( valGlobal.canvas, props.values.index, setting );
  }

  // update this component
  const updateComponents = () => {

    UtilsObject.getActive( valGlobal.canvas ).then( object => {

      if (object.hasOwnProperty('filters') === false) return;      
      object.filters = object.filters || [];
      object.filters.forEach( el => {
        if(el.name === props.values.name){
          // set values
          setMatrix(props.values.matrix);
        }
      });

    }).catch(()=>{});

  };

  useEffect(() => {
    if(matrix){
      const settings = {
        ...props.values,
        matrix: 
        [  0, -1,  0,
          -1,  5, -1,
           0, -1,  0 ],
      }
      apply(settings);
    }
  },[matrix]);

  useEffect(() => {    
    updateComponents();
  },[]);

  return (
    <div style={styles.controlls}>
      {/* <SliderInput value={matrix} min={0} max={700} step={0.01} default={0} title="matrix" onChange={(e)=>setMatrix(e.value)} isInt={false} decimal={2}/> */}
    </div>
  );
}
export default BoxSharpen;