import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Page, Text, View, Document, StyleSheet, Link, PDFViewer, Image } from '@react-pdf/renderer';
import { makeStyles } from '@material-ui/core';
import Exports from '../../fabricjs/Exports';
import { useGlobal } from '../../setting/global';

const PIXEL_TO_MILLIMETER = 0.2645833333;
// const MILLIMETER_TO_PIXEL = 3.7795275591;

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  viewHorizontal: {
    padding: 10,
    flexDirection: 'row',
  },
  viewVertical: {
    padding: 10,
    flexDirection: 'column',
  },
  section: {
    padding: 10,
  },
  sectionImage: {
    padding: 10,
  },
  sectionTechnical: {
    padding: 10,
    flexGrow: 1,
  },
  h1: {
    paddingLeft: 20,
    paddingTop: 10,
    fontSize: '30px',
    color: '#777777',
  },
  tecnnicalText: {
    padding: 10,
    minWidth: 200,
    fontSize: 9,
    color: '#333333',
  },
  titleTechnical: {
    fontSize: '14px',
    fontWeight: 700,
    paddingBottom: '15px',
  },
  textTechnical: {
    fontSize: '12px',
    paddingBottom: '5px',
  },
  rowTechnical: {
    padding: '5px',
  }
});

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '87vh',
    maxHeight: '87vh',
  },
}));

export default function TechnicalReportPDF(props) {

  const exports = new Exports();
  const classes = useStyles();
  const {valGlobal} = useGlobal();  
  const [open, setOpen] = React.useState(false);
  const [base64, setBase64] = React.useState('');
  const [base64Width, setBase64Width] = React.useState('');
  const [base64Height, setBase64Height] = React.useState(''); 
  const [textReport, setTextReport] = React.useState(''); 
  const [loadImages, setLoadImages] = React.useState(false);
  const scroll = 'paper'; // paper|body

  const handleClickOpen = () => {
    canvasToBase64()
    setOpen(true);
  };

  const handleClose = () => {
    setLoadImages(false);
    setOpen(false);
  };

  const canvasToBase64 = () => {
    const c = valGlobal.canvas;
    if( !c ) return;
    exports.toBase64( c ).then( ({data,width,height}) => {
      setBase64Width( width * PIXEL_TO_MILLIMETER + 'mm' );
      setBase64Height( height * PIXEL_TO_MILLIMETER + 'mm');
      setTimeout( ()=>{
        setBase64( data );
        setTimeout( ()=>{
          setLoadImages(true);
        },100);
      },1000);
    });
  }

  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Text style={styles.h1}>TECNOBLU</Text>
        <View style={styles.viewHorizontal}>
          <View style={{...styles.sectionImage, width:(base64Width-1)}}>
            <Image src={base64} style={{width:base64Width,height:base64Height}}/>
          </View>
          <View style={styles.sectionTechnical}>
            <Text style={styles.titleTechnical}>
              Technical Report
            </Text>
            <Text style={styles.textTechnical}>
              {textReport.map( (el, index) => (
                <View style={styles.rowTechnical}>

                  {/* <span style={{color:'#444444'}}>{index === 0 ? 'Base: ' : ''}</span>
                  <span style={{color:'#444444'}}>{index === 1 ? 'Dimensions: ' : ''}</span> */}

                  { el.indexOf('#') !== -1 ? (
                    <View>
                    <span style={{color:'#444444'}}>Color: </span> 
                      {" "}
                      <span style={{padding: 10, color:(String(el).substr(el.indexOf('#'),7)),backgroundColor:(String(el).substr(el.indexOf('#'),7)) }}>
                        HEX
                      </span>
                      {" "} 
                      {el}
                    </View>
                  ):(
                    <View>
                      {/* <span style={{color:'#444444'}}>{index > 2 ? 'Texture: ' : ''}</span> */}
                      {el}
                    </View>
                  )}
 
                  {"\n\n"}
                </View>
              ))}
            </Text>
            <Text style={styles.textTechnical}>
              <Link src="https://avenuz.gnnc.com.br" style={{color:'blue'}}>Download ASVG file</Link>
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  const Viewer = () => (
    <PDFViewer style={{width: '100%', height:'100%', border: 'none' }}>
      <MyDocument />
    </PDFViewer>
  );

  useEffect(()=>{
    setTextReport(props.textReport || '');
  },[props])

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        PDF File
      </Button>
      <Dialog
        open={open}
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
        fullWidth={true}
        maxWidth={'lg'} 
        classes={{ paper: classes.dialogPaper }}
        >
        {/* <DialogTitle id="alert-dialog-title">
          Novo Produto
        </DialogTitle> */}
        <DialogContent style={{height:'100%'}}>

            { loadImages ? <Viewer/> : `Loading...` }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
