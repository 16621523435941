import React from 'react';
import ReactDOM from 'react-dom';
import App from './structure/layouts/App';
import 'fontsource-roboto';
import './resources/conn/Https';
import './index.css';

const Development = () => {
  if(!process.env) return (<React.Fragment></React.Fragment>);
  const where = process.env.REACT_APP_LOCALNAME; 
  if(where.indexOf('production') > -1 || where.indexOf('server') > -1) return (<React.Fragment></React.Fragment>);
  return (<div style={{bottom:'0px',position:'fixed',padding:'3px 7px',zIndex:99999,fontSize:'11px',backgroundColor:'rgb(255,69,0,.8)',color:'#ffffff'}}>{process.env.REACT_APP_LOCALNAME} | {process.env.REACT_APP_API_HOST}</div>);
}

ReactDOM.render(
    <React.Fragment>
      <Development/>
      <App />
    </React.Fragment>,
  document.getElementById('root')
);