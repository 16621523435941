import { Container, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
		width: '100%',
    padding: theme.spacing(5),
    color: theme.palette.text.secondary,
  },
	title: {
		padding: 0,
		margin: 0,
		marginBottom: 20,
		fontSize: 30,
		fontWeight: 300,
	}
}));

export default function ViewCalculate() {

	const classes = useStyles();

  return (
		<Container component="main" maxWidth="md">
			<Paper className={classes.paper}>
				<Typography nowrap className={classes.title}>
					Calculator: Prices of production
				</Typography>

				<Grid container style={{marginBottom:20, fontSize:13, color:'#333333'}}>
					<Grid item xs={1} sm={1}></Grid>
					<Grid item xs={2} sm={2}>Material Name</Grid>
					<Grid item xs={2} sm={2}>Price Star (1-7)</Grid>
					<Grid item xs={2} sm={2}>Dimension</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={1} sm={1}>
						<img src="https://server.gnnc.com.br/storage/100/materials/3d35a2576512dcc2921828624b05e7.jpeg" style={{maxHeight:50}}/>
					</Grid>
					<Grid item xs={2} sm={2}>Couro Bull Creme</Grid>
					<Grid item xs={2} sm={2}> <StarBorderIcon style={{float:'left'}}/> <div style={{padding:3, float:'left'}}>5</div></Grid>
					<Grid item xs={2} sm={2}>15mm x 19mm</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={1} sm={1}>
						<img src="https://server.gnnc.com.br/storage/100/process/1a854412d7df611145da1b3da8baed.jpeg" style={{maxHeight:50}}/>
					</Grid>
					<Grid item xs={2} sm={2}>Dry-ink: Amarelo</Grid>
					<Grid item xs={2} sm={2}> <StarBorderIcon style={{float:'left'}}/> <div style={{padding:3, float:'left'}}>1</div></Grid>
					<Grid item xs={2} sm={2}>10mm x 11mm</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={1} sm={1}>
						<img src="https://server.gnnc.com.br/storage/100/process/d56f346d06389d7df21a5efb35e6f7.jpeg" style={{maxHeight:50}}/>
					</Grid>
					<Grid item xs={2} sm={2}>Foil: Bronze</Grid>
					<Grid item xs={2} sm={2}> <StarBorderIcon style={{float:'left'}}/> <div style={{padding:3, float:'left'}}>3</div></Grid>
					<Grid item xs={2} sm={2}>15mm x 19mm</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={1} sm={1}>
					- - - - - -
					</Grid>
					<Grid item xs={2} sm={2}>Costura: Linha 12</Grid>
					<Grid item xs={2} sm={2}> <StarBorderIcon style={{float:'left'}}/> <div style={{padding:3, float:'left'}}>1</div></Grid>
					<Grid item xs={2} sm={2}>12mm x 17mm</Grid>
				</Grid>

				<hr style={{margin:'50px 0'}}/>

				<Grid container>
					<Grid item xs={2} sm={2}>1.000 <br/>R$ 2,43</Grid>
					<Grid item xs={2} sm={2}>3.000 <br/>R$ 2,13</Grid>
					<Grid item xs={2} sm={2}>5.000 <br/>R$ 1,83</Grid>
					<Grid item xs={2} sm={2}>10.000 <br/>R$ 1,43</Grid>
					<Grid item xs={2} sm={2}>20.000 <br/>R$ 1,03</Grid>
				</Grid>

			</Paper>
		</Container>
	);
}
