import { Grid, Slider, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const styles = {
  title: {
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		fontSize: 12,
		fontWeight: 400,
    color: '#444444', 
  }
}

const SliderInput = (props) => {
  
  const [disabledInputs, setDisabledInputs] = useState(true); // disabled inputs
  const [title,setTitle] = useState(null);
  const [value,setValue] = useState(null);
  const [isInt,setIsInt] = useState(false);
  const [max,setMax] = useState(null);
  const [min,setMin] = useState(null);
  const [step,setStep] = useState(1);
  const [decimal,setDecimal] = useState(0);

  const dispatchChange = ( v ) => {
    if(props.hasOwnProperty('onChange')){
      props.onChange({ value:v });
    }
  };

  const onChangeSlider = ( event, dist ) => {
    let v = isInt === true ? (dist >> 0) : Number(Number(dist).toFixed(decimal));
    setValue( v );
    dispatchChange( v );
  };

  const [times,setTime] = useState(0);
  const onChangeInput = ( event ) => {
    let v = event.target.value;
    v = v.replace(',','.')
    setValue( (isNaN(v) && v !== '' && v !== '-') ? min : v );
    clearTimeout( times );
    setTime( 0 );
    setTime( setTimeout(()=>{
      let v = isInt === true ? (event.target.value >> 0) : Number(Number(event.target.value).toFixed(decimal));;
      v = v < min ? min : v ;
      v = v > max ? max : v ;
      setValue( v );
      dispatchChange( v );
    },500));
  };

  const {value:propsValue} = props;
  useEffect(()=>{
    setValue(propsValue);
  },[propsValue]);

  useEffect(()=>{
    setDisabledInputs( props.disabled );
  },[props.disabled]);

  useEffect(()=>{
    if(props.hasOwnProperty('disabled')){
      setDisabledInputs( props.disabled );
    }
    if(props.hasOwnProperty('default')){
      setValue( props.default );
    }
    if(props.hasOwnProperty('isInt')){
      if(props.isInt !== undefined){
        setIsInt( props.isInt );
        //setIsInt(Boolean(props.isInt));
      }
    }
    if(props.hasOwnProperty('max')){
      setMax( props.max );
    }
    if(props.hasOwnProperty('min')){
      setMin( props.min );
    }
    if(props.hasOwnProperty('step')){
      setStep( props.step );
    }
    if(props.hasOwnProperty('decimal')){
      setDecimal( props.decimal );
    }
    if(props.hasOwnProperty('title')){
      setTitle( props.title );
    }
  },[]);

return (
  <div>

    {title?
    <label style={styles.title}>{title}</label>
    :null}

    <Grid container spacing={1}>
      <Grid item xs={9} style={{padding:'1px 5px 0px 10px'}}>

        <div>

          {/* <Typography id="continuous-slider" gutterBottom></Typography> */}
          <Slider 
            disabled={disabledInputs}
            value={value} 
            onChange={ onChangeSlider } 
            aria-labelledby="continuous-slider"
            defaultValue={value}
            step={step}
            min={min}
            max={max}
            valueLabelDisplay="auto"
          />

        </div>

          
      </Grid>
      <Grid item xs={3}>
      <TextField value={value}
          disabled={disabledInputs}
          onChange={ onChangeInput } 
          variant="outlined" size="small"
          fullWidth
          inputProps={{ 
            style: {
              textAlign: 'center',
              fontSize: 11,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 5,
              paddingRight: 5,
            } 
          }} />
      </Grid>
    </Grid>
  </div>
  );
}
export default SliderInput;