import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from "@material-ui/core";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useGlobal } from './../../../../../../resources/setting/global';
import UtilsCanvas from '../../../../../../resources/utils/UtilsCanvas';
import layersIcon from './../../../../../../assets/personalSVG/layers-1.svg';
import layersMaster from './../../../../../../assets/personalSVG/layers-master.svg';
import { DeleteOutlined, LockOpenOutlined, LockOutlined } from '@material-ui/icons';
import UtilsObject from '../../../../../../resources/utils/UtilsObject';


const style = {
	button: {
		backgroundColor: 'transparent',
		border: '0',
		padding: 0,
		margin: 0,
	},
	icon: {
		height: 17,
		color: '#777777',
		fill: '#777777',
	},
  circle: {
    borderColor: '#dddddd',
    borderWidth: '2px',
    borderStyle: "solid",
    borderRadius: '30px',
    width: '50px',
    height: '50px',
    textAlign: "center",
    verticalAlign: "middle",
    cursor:'pointer',
    marginBottom: 5,
    fontSize:10,
  }
}

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function ButtonLayers() {

  const classes = useStyles();
  const {valGlobal} = useGlobal();
  const [state, setState] = React.useState({
    right: false,
  });
  const anchor = 'right';
  const [listLayers,setListLayers] = React.useState([]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const setActiveObject = ( el ) => {
    const objects = [...valGlobal.canvas._objects];
    UtilsCanvas.setActiveObject( valGlobal.canvas, objects.reverse()[el.index] );
    //setState({ ...state, [anchor]: false });
    UtilsCanvas.focus();
  }

	const removeItem = ( event, el ) => {
    event.preventDefault();
    event.stopPropagation();
    UtilsCanvas.remove( valGlobal.canvas, el );
	}

  const setLock = ( event, el ) => {
    event.preventDefault();
    event.stopPropagation();
    UtilsObject.setLock( valGlobal.canvas, el, true );
	}

  const setUnlock = ( event, el ) => {
    event.preventDefault();
    event.stopPropagation();
    UtilsObject.setLock( valGlobal.canvas, el, false );
	}

  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {listLayers.map((el) => {
          if( el.typeName === 'RectResize' || el.typeName === 'RectMP' ) return (null);
          return (
            <ListItem button onClick={() => setActiveObject(el)} key={el.key}>
              <div style={{marginRight:20}}>
                <img src={layersIcon} height="16" alt="Layers"/>
              </div>
              <ListItemText primary={el.typeName} 
              //secondary={el.typeName} 
              />
              <LockOutlined size="small" onClick={(event)=>setLock( event, el )}/>
              <LockOpenOutlined size="small" onClick={(event)=>setUnlock( event, el )}/>
              <div style={{marginLeft:5,paddingTop:6}}>
                <DeleteOutlined size="small" style={{color:"#dc143c"}} onClick={(event)=>removeItem( event, el )}/>
              </div>
            </ListItem>
          )
        })}
      </List>
      <Divider />
    </div>
  );

  const filterList = ( list ) => {
    return list.map((el,index)=>{
      return { 
        typeName: el.typeName, 
        width:    el.width, 
        height:   el.height, 
        key:      el.key, 
        index:    index,
        lock:     el.lockMovementX === true ? true : false ,
        text:     el.hasOwnProperty('text') ? el.text : '' ,
        fill:     el.hasOwnProperty('fill') ? el.fill : '',
        stroke:   el.hasOwnProperty('stroke') ? el.stroke : '' ,
      }
    });
  }


  const readeLayers = () => {
    setState({ ...state, [anchor]: true });
    if( valGlobal.canvas === undefined || valGlobal.canvas === null ) return ;
    if( valGlobal.canvas.hasOwnProperty('_objects') === false ) return ;
    const list = [...valGlobal.canvas._objects];
    setListLayers( filterList( list === null ? [] : list.reverse() )) ;
  };

  // React.useEffect(() => {
  //   readeLayers();
  //   //GlobalEvents.on("changeActiveObject", readeLayers );
  //   return () => {
  //     //GlobalEvents.off("changeActiveObject", readeLayers );
  //   };
  // }, [valGlobal]);

  return (
    <React.Fragment>

      <Tooltip title="Layers" placement="left">
        <button type="button" style={{...style.button,...style.circle}} 
        onClick={()=> readeLayers()}>
          <img src={layersMaster} style={{height:36}}/>
        </button>
      </Tooltip>

      <SwipeableDrawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        {list(anchor)}
      </SwipeableDrawer>

    </React.Fragment>
  );
}
