import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function InitialScreen() {

  const [open, setOpen] = React.useState(false);
  const scroll = 'paper'; // paper|body

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
        fullWidth={true}
        maxWidth={'sm'} 
        >
        {/* 
        maxWidth:
        <MenuItem value={false}>false</MenuItem>
        <MenuItem value="xs">xs</MenuItem>
        <MenuItem value="sm">sm</MenuItem>
        <MenuItem value="md">md</MenuItem>
        <MenuItem value="lg">lg</MenuItem>
        <MenuItem value="xl">xl</MenuItem> */}
        <DialogTitle id="alert-dialog-title">
          Novo Produto
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <div>Qual produto</div>
          <div>Qual largura</div>
          <div>Qual altura</div>
          <div>Qual faca</div>
          <div>Qual matéria prima inicial</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
