import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Container,
  Grid,
  IconButton,
  Link as LinkBread,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Delete,
  Edit,
} from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
// import ArrayToTree from "array-to-tree";
// import Nestable from "react-nestable";
import { useSnackbar } from "notistack";
import ApiServer from "../../../../resources/conn/ApiServer";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import ModalDialog from "../../../../resources/component/general/modal/ModalDialog";
import UtilsArray from "../../../../resources/utils/UtilsArray";
import ProcessUpdate from "./../formulary/Update";
import ProcessCreate from "./../formulary/Create";
import ProcessIcon from "./../../../../assets/personalSVG/panel-process.svg";


const useStyles = makeStyles((theme) => ({
  breadcrumbsBar: {
    padding: 15,
  },
}));

export default function ProcessListSimple(props) {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openNew,setOpenNew] = useState(false);
  const [openEdit,setOpenEdit] = useState(false);

  const [id, setId] = useState(null);
  const [processList, setProcessList] = useState([]);
  // const [processTree, setProcessTree] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [filter, setFilter] = useState('');

  const listProcess = () => {
    new ApiServer()
      .get("process", "get")
      .then((e) => {

        if (e.statusText === "OK" && e.data !== undefined ) {

          // maybe more fast
          // https://www.npmjs.com/package/performant-array-to-tree
          setProcessList(UtilsArray.sortByHierarchyAndName(e.data));
          setListFilter(UtilsArray.sortByHierarchyAndName(e.data));
          // setProcessTree(ArrayToTree(e.data));

        }else{

          setProcessList([]);
          setListFilter([]);
          // setProcessTree([]);

        }
      })
      .catch((err) => {
        console.log(err.statusText, err);
      });
  };

  const onNew = () => {
    setOpenNew( true ); 
  };

  const onEdit = ( event, { id } ) => {
    event.preventDefault();
    event.stopPropagation();
    setId( id );
    setOpenEdit( true ); 
  };

  const onDelete = ( event, { id } ) => {
    event.preventDefault();
    event.stopPropagation();
    if(!window.confirm('Delete item?')) return;
    new ApiServer().delete( 'process', `delete/${id}` ).then(( res )=>{
      console.log( res );
      if( res.statusText === 'OK' ){
        listProcess();
        enqueueSnackbar('Deleted',{variant:'success'});
      }
    }).catch((err)=>{
      enqueueSnackbar('Error',{variant:'error'});
    });
  };

  const onChangeFilter = ( filter ) => {
    if( !filter ) setListFilter( processList ); 
    setListFilter(
      processList.filter((el) => String(el.name).toLowerCase().indexOf( String(filter).toLowerCase() ) > -1 )
    );
  };

  useEffect(() => {
    listProcess();
    GlobalEvents.on('updateProcess',listProcess);
    return ()=>{
      GlobalEvents.off('updateProcess',listProcess);
    }
  }, []);

  return (
    <React.Fragment>
      <Container component="main">

        <Grid container className={classes.breadcrumbsBar}>
          <Grid item xs={6}>
            <Breadcrumbs aria-label="breadcrumb" style={{margin:5}}>
              <LinkBread color="inherit" href="/">
                Home
              </LinkBread>
              <Typography color="textPrimary">
                Process
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid xs={6} container justify="flex-end">
            <IconButton aria-label="add" onClick={()=>onNew()} title="Add">
              <AddIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{marginTop:5}}>
            <TextField 
              InputLabelProps={{ shrink: true }} shrink
              autoComplete="materialsfilter"
              name="materials_filter"
              variant="outlined"
              fullWidth
              label="Search"
              size="small"
              onChange={(event)=>{ setFilter(event.target.value); onChangeFilter(event.target.value) }}
              value={filter}
            />
          </Grid>
        </Grid>

        <List dense={true}>
          {listFilter.map((el) => (
            <ListItem style={{marginLeft:parseInt(20*el.depth)}} key={el.id}>
              <ListItemAvatar>
                <img src={ el.parent_id ? (process.env.REACT_APP_IMAGE_HOST && el.file_name? process.env.REACT_APP_IMAGE_HOST + '100/' + el.file_name : '/assets/images/image-not-found.png') : ProcessIcon } alt="Material MP" 
                style={{maxWidth:'50px', maxHeight:'50px', borderRadius:'7px', marginRight:'20px'}}/>
              </ListItemAvatar>
              <ListItemText
                primary={el.name}
                secondary={`${el.description || ''}`}
              />
              <ListItemSecondaryAction>
                <IconButton edge="start" aria-label="edit" onClick={(event)=>onEdit(event,el)}>
                  <Edit />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(event)=>onDelete(event,el)}
                  >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <ModalDialog open={openNew} setOpen={(v)=>setOpenNew(v)} title='Create'>
          <ProcessCreate data={{}} setOpen={(v)=>{ if(v === false) setOpenNew(v) }}/>
        </ModalDialog>

        <ModalDialog open={openEdit} setOpen={(v)=>setOpenEdit(v)} title='Update'>
          <ProcessUpdate data={{id:id}} setOpen={(v)=>{ if(v === false) setOpenEdit(v) }}/>
        </ModalDialog>

        {/* <Nestable 
            collapsed={false}
            items={processTree} 
            renderItem={renderItem2} 
            // ref={el => this.refNestable = el}        
        /> */}

      </Container>
    </React.Fragment>
  );
}
