import React, { useState } from "react";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import { fabric } from "fabric";

import { Button } from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import { useGlobal } from "../../../../resources/setting/global";
import Exports from "../../../../resources/fabricjs/Exports";
import Imports from "../../../../resources/fabricjs/Imports";
import Images from "../../../../resources/fabricjs/Images";
import ApiServer from "../../../../resources/conn/ApiServer";
import ClipPath from "../../../../resources/fabricjs/ClipPath";
import UtilsObject from "../../../../resources/utils/UtilsObject";
import UtilsCanvas from "../../../../resources/utils/UtilsCanvas";
import UtilsCompress from "../../../../resources/utils/UtilsCompress";
import Paragraph from "../../../../resources/fabricjs/Paragraph";
import Shapes from "../../../../resources/fabricjs/Shapes";

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    //whiteSpace: 'nowrap',
    marginBottom: theme.spacing(0),
  },

}));

export default function Panel6( props ) {

  const exports = new Exports();
  const imports = new Imports();

  const classes = useStyles();
  const {valGlobal} = useGlobal();

  const addImageSVG = () => {
    new Images().add(valGlobal.canvas, {
      source: "./assets/images/sample/svg.svg",
      top: 10,
      left: 10,
      relativePosition: true,
    });
  };

  const addImageJPG = () => {
    new Images().add(valGlobal.canvas, {
      source: "assets/images/sample/filter.jpg",
      top: 10,
      left: 10,
      relativePosition: true,
    });
  };

  const addImageData = ( event ) => {
    new Images().upload( valGlobal.canvas, event );
  };

  const setLoading = (data) => {
    if(props.hasOwnProperty('setLoading')){
      if(typeof data === 'boolean'){
        data = {loading:data,percent:data?100:0};
      }
      props.setLoading({loading:data.loading,percent:data.percent});
    }
    //GlobalEvents.dispatch('globalLoading',{loading:true});
  }

  const [json,setJson] = useState('');
  const load = (n) => {
      setLoading(true);
      new ApiServer().get('../storage',`data-old/text-${n}.txt`,{},setLoading).then((e)=>{
      const data = JSON.stringify(e.data);
      setJson(data);
      imports.fromJson( valGlobal.canvas, data );
    });
  }

  const importsJson = () => {
    imports.fromJson( valGlobal.canvas, json );
  }

  const exportsJson = () => {
    exports.toJson( valGlobal.canvas ).then((data)=>{
      // data = new UtilsCompress().compress_1(data);
      // data = new UtilsCompress().compress_2(data);
      // data = new UtilsCompress().compress_3(data);
      setJson(data);
    })
  }

  // technical report
  const exportsSVG = () => {
    exports.toSVGTechnicalReport( valGlobal.canvas, ({data}) => setJson(data) );
  }

  // technical report
  const exportsBase64 = () => {
    exports.toBase64( valGlobal.canvas ).then( ({data}) => setJson(data) );
  }

  const addClipString = () => {
    const s = 'M123.93,127.15c2.94,15.83,21.13,36.91,17.45,53S144.31,220,140,243.75c-1,5.32-1.85,9.87-2.66,14.14a177.7,177.7,0,0,1-25-4.59c7,5.05,16,8.62,23.77,11-2.19,12.2-3.71,24.18-4.55,48.6-1.25,36.89-10.1,48.76-17.94,62.87s-22.3,17.11-37.35,13.52c0,0-17.34,8.2-28.41-4s-16.66-26.65-20.68-51.46c-.3-1.8-.64-3.58-1-5.34,8.65-1.19,20.48-3.37,30.08-7.16a156.64,156.64,0,0,1-31.69.22c-5.05-19.87-13.42-38.44-16.54-61.37-.38-2.73-.69-5.29-1-7.7,0-.08.23-16.91,24.78-21.36,0,0-24.88-2-27.67-15.53a91.85,91.85,0,0,0-2-10.33c-4.44-17.57-2-34.77,5.72-55.07l1.41-3.8c.73-2,1.4-4,2-5.9l.21-.64c3.54.07,15.7-.11,31.44-5.21,0,0-11.56,2.36-29.39-1.39a300.65,300.65,0,0,0,7-31.07C23,89.69,35.77,59.32,50.83,48.94a94.58,94.58,0,0,0,9.55-7.54c2.13-1.93,4-3.77,5.52-5.44.46-24.76,2.8-34.19,2.8-34.19C74.39-2,81,1.38,81,1.38a284.59,284.59,0,0,0-5.29,37.25c25.8,17.52,22.44,35.69,31.67,46.2C117,95.76,121,111.31,123.93,127.15Z';
    new ClipPath().fromSVGString( valGlobal.canvas, s );
  }

  const addRectClip = (w,h) => {
    new ClipPath().addRect( valGlobal.canvas, w, h, 0 );
  }

  const removeClip = () => {
    new ClipPath().remove( valGlobal.canvas );
  }

  const download = () => {
    new Exports().toImageDownload( valGlobal.canvas );
  }

  const addClip = () => {

    const SVGstring = 'M123.93,127.15c2.94,15.83,21.13,36.91,17.45,53S144.31,220,140,243.75c-1,5.32-1.85,9.87-2.66,14.14a177.7,177.7,0,0,1-25-4.59c7,5.05,16,8.62,23.77,11-2.19,12.2-3.71,24.18-4.55,48.6-1.25,36.89-10.1,48.76-17.94,62.87s-22.3,17.11-37.35,13.52c0,0-17.34,8.2-28.41-4s-16.66-26.65-20.68-51.46c-.3-1.8-.64-3.58-1-5.34,8.65-1.19,20.48-3.37,30.08-7.16a156.64,156.64,0,0,1-31.69.22c-5.05-19.87-13.42-38.44-16.54-61.37-.38-2.73-.69-5.29-1-7.7,0-.08.23-16.91,24.78-21.36,0,0-24.88-2-27.67-15.53a91.85,91.85,0,0,0-2-10.33c-4.44-17.57-2-34.77,5.72-55.07l1.41-3.8c.73-2,1.4-4,2-5.9l.21-.64c3.54.07,15.7-.11,31.44-5.21,0,0-11.56,2.36-29.39-1.39a300.65,300.65,0,0,0,7-31.07C23,89.69,35.77,59.32,50.83,48.94a94.58,94.58,0,0,0,9.55-7.54c2.13-1.93,4-3.77,5.52-5.44.46-24.76,2.8-34.19,2.8-34.19C74.39-2,81,1.38,81,1.38a284.59,284.59,0,0,0-5.29,37.25c25.8,17.52,22.44,35.69,31.67,46.2C117,95.76,121,111.31,123.93,127.15Z';
    const object = new fabric.Path(String(SVGstring).trim(), {
      typeName: 'SVG',
      key: UtilsCanvas.key(),
      left: 0,
      top: 0,
      objectCaching: false,
      fill: 'grey',
      // originX: 'left',
      // originY: 'top',
    });

    UtilsCanvas.add( valGlobal.canvas, object);

    // set width,height
    object.set({ 
      width: object.getBoundingRect().width,
      height: object.getBoundingRect().height,
    });
    object.setCoords();

  }

  const createInnerShadow = () => {

    // phase 2 - criar o retangulo proporcional na mesma posicao, textura e caracteristicas
    UtilsObject.getActive( valGlobal.canvas ).then( object => {

      const c = valGlobal.canvas;
      const uc = new UtilsCanvas();
      // save angle zero
      uc.rotateReset( c );
      // zoom to 1:1
      uc.zoomReset( c ); 

      //------------------------------------
      const _color   = "rgba(0,0,0,.9)";
      const _blur    = 20;
      const _offsetX = 0; // no change, zero ever
      const _offsetY = 0; // no change, zero ever
      //------------------------------------
      let offset = 10; // no change

      const setAngle = ( elements, angle, multiplier ) => {
        if( angle > 0 ){
          const group = new fabric.Group(elements);
          group.rotate( angle === undefined ? 0 : angle * multiplier ); // 1|-1
          group.destroy()
          // valGlobal.canvas.requestRenderAll();
        }
      }

      const { angle, scaleX, scaleY } = object;
      // set to angle = 0
      setAngle( [object], angle, -1 );
      // set to scale 1
      if( scaleX !== 1 || scaleY !== 1 ){
        offset = 0;        
        object.set({
          scaleX: 1,
          scaleY: 1,
        });
      }
  
      const { width, height, left, top } = object;
      const rect = new fabric.Rect({
        typeName: 'Rect',
        width: width + ( offset * 2 ), 
        height: height + ( offset * 2 ), 
        left: left - offset,
        top: top - offset,
        // originX: 'left',
        // originY: 'top',
        fill: "black",
        shadow: {
          color: _color,
          blur: _blur,
          offsetX: _offsetX,
          offsetY: _offsetY,
        },
      })  
      // rect.setCoords();
  
      // phase 3 - apply inverted mask 
  
      var clipOfObject = UtilsObject.clone( object );
      console.log('>>>>>',clipOfObject);
      rect.clipPath = clipOfObject;
      rect.clipPath.set({
        typeName: 'ClipPath',
        inverted: true, // the magic
        left: ( rect.clipPath.width / 2 ) * -1,
        top: ( rect.clipPath.height / 2 ) * -1,
      });

      console.log('>>>>',rect.clipPath);
    
      // phase 4 - apply another mask to maker inner shadow
  
      var clipOfObjectFinal = UtilsObject.clone( object );
      const clipFinal = new fabric.Group([rect]);
      clipFinal.key = UtilsCanvas.key();
      clipFinal.typeName = 'GroupShadow';

      object.responsive = [{ object: clipFinal, key: clipFinal.key, properties: ['width','height','left','top','angle']}]

      clipFinal.clipPath = clipOfObjectFinal;
      clipFinal.clipPath.set({
        typeName: 'ClipPath',
        left: ( rect.clipPath.width / 2 ) * -1,
        top: ( rect.clipPath.height / 2 ) * -1,
      });
  
      valGlobal.canvas.add(clipFinal);

      if( scaleX !== 1 || scaleY !== 1 ){
        [object,clipFinal].forEach( (el) => {
          el.set({
            scaleX: scaleX,
            scaleY: scaleY,
          });
        });
      }
      setAngle( [object,clipFinal], angle, 1 );
      // valGlobal.canvas.requestRenderAll();

    });

    // shadow: {
    //   color: "rgba(100,100,100,.9)",
    //   blur: 15,
    //   offsetX: 0,
    //   offsetY: 0,
    // },

    // valGlobal.canvas.getActiveObject().on('event:moved', function(event){
    //   const mySelf = event.target;
    //   console.log('>>',mySelf.left,mySelf.get('left'));
    //   clipFinal.add({
    //     left: mySelf.left,
    //     top: mySelf.top,
    //     width: mySelf.width,
    //     height: mySelf.height,
    //   });
    // })

  }


  // https://jsfiddle.net/jrekw5og/141/
  // https://stackoverflow.com/questions/37378088/inset-shadow-on-html5-canvas-image/37380488#37380488

  function createObjectInnerShadow2(){
   
    const object = valGlobal.canvas.getActiveObject();

    // set first object
    if( object === undefined || object === null ){
      window.alert('add and select a object');
      return;
    }

    var data = object.toDataURL({
      enableRetinaScaling: true,
      format: "png", // png | jpg
      // quality: 1, // only jpg
      multiplier: 1, // scale, default 1
    });

    var img = document.createElement("img");
    img.onload = function(){ createANewCanva(this) };
    img.src = data;

  }

  function createANewCanva (img) {

    const c = valGlobal.canvas;
    const uc = new UtilsCanvas();
    // save angle zero
    uc.rotateReset( c );
    // zoom to 1:1
    uc.zoomReset( c ); 

    var alpha = .5;
    var color = '#000000';
    var distance = 2;
    var offsetX = .5;
    var offsetY = .5;

      // the size of the shadow depends on the size of the target,
      // then I will create extra "walls" around the picture to be sure
      // tbat the shadow will be correctly filled (with the same intensity everywhere)
      // (it's not obvious with this image, but it is when there is no space at all between the image and its border)
      var offset = 50 + distance;
      var hole = document.createElement("canvas");
      var holeContext = hole.getContext("2d");
      hole.width = img.width + offset*2;
      hole.height = img.height + offset*2;

      // first, I draw a big black rect
      holeContext.fillStyle = "#000000";
      holeContext.fillRect(0,0,hole.width,hole.height);
      
      // then I use the image to make an hole in it
      holeContext.globalCompositeOperation = "destination-out";
      holeContext.drawImage(img,offset,offset);
      
      // I create a new canvas that will contains the shadow of the hole only
      var shadow = document.createElement("canvas");
      var shadowContext = shadow.getContext("2d");
      shadow.width = img.width;
      shadow.height = img.height;
      shadowContext.filter = "drop-shadow("+offsetX+"px "+offsetY+"px "+distance+"px "+color+" ) ";
      shadowContext.drawImage(hole,-offset,-offset);
      shadowContext.globalCompositeOperation = "destination-out";
      shadowContext.drawImage(hole,-offset,-offset);

      shadow.typeName = 'Image';
      
      // now, because the default-shadow filter is really to soft, I normalize the shadow 
      // then I will be sure that the alpha-gradient of the shadow will start at "shadowAlpha" and end at 0
      normalizeAlphaShadow(shadow,alpha);
      
      // // Finally, I create another canvas that will contain the image and the shadow over it
      // var result = document.createElement("canvas");
      // result.width = img.width;
      // result.height = img.height;
      // var context = result.getContext("2d");
      // context.drawImage(img,0,0)
      // context.drawImage(shadow,0,0);
      
      // // and that's it !
      // document.body.appendChild(result);

      // ----------------------------------------------------------------------------------------
      // now send to fabric js
      const object = valGlobal.canvas.getActiveObject();
      // var dataBase = result.toDataURL("image/png");
      var imgInstance = new fabric.Image(shadow, {
        typeName: 'Image',
        left: object.getBoundingRect().left,
        top: object.getBoundingRect().top,
      });
      imgInstance.typeName = 'Image';

      UtilsCanvas.add( valGlobal.canvas, imgInstance );
      // ----------------------------------------------------------------------------------------

 }
 
  function normalizeAlphaShadow(c,alpha){

    var imageData = c.getContext("2d").getImageData(0,0,c.width,c.height);
    var pixelData = imageData.data;
    var i,len = pixelData.length;
    var max = 0;
    for(i=3;i<len;i+=4) if(pixelData[i]>max) max = pixelData[i];
    
    max = (255/max) * alpha;
    for(i=3;i<len;i+=4) pixelData[i] *= max;
    
    c.getContext("2d").putImageData(imageData,0,0)
  
  }

  return (
    <React.Fragment>  

      <div style={{padding:10}}>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>

                <input
                  accept="image/*"
                  style={{display:'none'}}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={addImageData}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="outlined" color="primary" component="span" >
                    <PhotoCamera size="small" style={{fontSize:22}} />
                  </Button>
                </label>

                <Button onClick={addImageJPG}>
                  Image
                </Button>
                <Button onClick={addImageSVG}>
                  Image
                </Button>

              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>xs=3</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>xs=3</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>xs=3</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>xs=3</Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper className={classes.paper}>xs=8</Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>xs=4</Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
              <div>Load files</div>
              <button onClick={()=>{ load(5) }}>0</button>
              <button onClick={()=>{ load(4) }}>1</button>
              <button onClick={()=>{ load(3) }}>2</button>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
              <div>Import, Export and Download</div>
              <button onClick={()=>{ importsJson() }}>Imp</button>
              <button onClick={()=>{ exportsJson() }}>Exp</button>
              <button onClick={()=>{ exportsSVG() }}>SVG</button>
              <button onClick={()=>{ exportsBase64() }}>Base64</button>
              <br/>
              <button onClick={()=>{ download() }}>Down</button>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
               <textarea value={json} onChange={(e)=>setJson(e.target.value)} rows="3" style={{width:'100%',maxWidth:150}}></textarea>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div>Clip and Mask</div>
                <button onClick={addClipString}>Clip</button>
                <br/>
                <button onClick={()=>addRectClip(300,200,1)}>Rect</button>
                <button onClick={removeClip}>Remove</button>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div>Clip Inverted</div>
                <button onClick={()=>{ addClip() }}>a</button>
                <button onClick={()=>{ createInnerShadow() }}>InnerShadow</button>                  
                <br/>
                <button onClick={()=>{ createObjectInnerShadow2() }}>2</button>
              </Paper>
            </Grid>
          </Grid>
        </div>

    </React.Fragment>
  );

};
