import React, {useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Backdrop, CircularProgress, LinearProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Delete } from '@material-ui/icons';
import ApiServer from "../../../../resources/conn/ApiServer";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import If from "../../../../resources/utils/If";
import UtilsFile from '../../../../resources/utils/UtilsFile';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
    minWidth: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  input: {
    // opacity: 0,
    // float: 'left',
    display: 'none',
  },
}));

// const styles = {
//   container: {
//     display: 'flex',
//     alignItems: 'flex-start',
//     justifyContent: 'flex-start',
//     width: '100%',
//   },
//   inputFile: {
//     position: 'absolute',
//     zIndex: -1,
//     fontSize: '17px',
//     color: '#000000',
//     opacity: 0.001,
//   },
//   buttonWrap: {
//     position: 'relative',
//     width: '100%',
//     paddingRight: 15,
//     color: '#000000',
//   },
//   button: {
//     display: 'inline-block',
//     backgroundColor: '#dddddd',
//     borderRadius: 7,
//     border: 0,
//     color: '#000000',
//     textAlign: 'center',
//     fontSize: 15,
//     padding: 8,
//     width: '100%',
//     cursor: 'pointer',
//   },
// }

export default function BrandsCreate(props) {

  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);

  const [clientId, setClientId] = useState(0);
  const [label, setLabel] = useState('');
  const [fileName, setFileName] = useState('');

  const {data:propsData} = props; 

  // ------------------------------------------------------------------ file upload
  const [fileUpload, setFileUpload] = useState(null);
  const [fileUploadName, setFileUploadName] = useState('');
  const [fileUploadSize, setFileUploadSize] = useState('');
  const [fileUploadLoad, setFileUploadLoad] = useState(0);
  const [fileUploadSubmitEnabled, setFileUploadSubmitEnabled] = useState(true);

  const formFileClear = (event) => {
    setFileUpload(null);
    setFileUploadName('');
    setFileUploadSize('');
    setFileUploadLoad(0);
    setFileUploadSubmitEnabled(true);
  }

  const onChangeFile = (event) => {

    const f = event.target.files[0];

    if( f === undefined ) return;

    const ext = '.' + f.name.split('.').pop(); // extension 
    const nname = String(f.name).substr(0,15); // new name reduce
    const fileUploadPath = 'brands';

    setFileUpload( f );
    setFileUploadName( nname + ext );
    setFileUploadSize( UtilsFile.formatBytes(f.size,1) );

    setSubmitEnabled(false);
    setFileUploadSubmitEnabled(false);

    const form = new FormData();
    form.append('file',f);
    form.append('size',f.size);

    new ApiServer().post( 'upload', fileUploadPath , form, (e)=>{

      setFileUploadLoad( e.percent );
      if(e.percent === 100){
        setSubmitEnabled(true);
        setFileUploadSubmitEnabled(true);
      }

    }).then((e)=>{
      console.log(e);
      if( fileUploadLoad === 100 ){
        setSubmitEnabled(true);
        setFileUploadSubmitEnabled(true);
      }
      setFileName(fileUploadPath+'/' + e.data.filename);

    }).catch((e)=>{
      enqueueSnackbar('Error upload file',{variant:'error'});
      setSubmitEnabled(true);
      setFileUploadSubmitEnabled(true);
      formFileClear();

      setFileName(null);
    });

  }
  // ------------------------------------------------------------------ file upload

  const setProps = () => {
    if( props && props.hasOwnProperty('data') ){
      if( propsData.hasOwnProperty('client_id') ){
        setClientId( propsData.client_id );
      }
    }
  }

  const onSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();

    if(submitEnabled === false) return;

    setSubmitEnabled( false );
    setLoading( true );

    const data = {
      client_id: Number(clientId),
      label: String(label).trim() || '',
      file_name: String(fileName).trim() || '',
    }

    new ApiServer().post( 'brands', 'create', data ).then(( e )=>{

      if(e.statusText==='OK'){
        GlobalEvents.dispatch('updateBrands');
        enqueueSnackbar('Create a new!', {variant:'success'});
        clearAll();

        // ToolDialog close
        if(props.hasOwnProperty('setOpen')){
          props.setOpen(false);
        }
        
      }
      setSubmitEnabled( true );
      setLoading( false );

    }).catch((err)=>{

      console.log( err.statusText, err);
      enqueueSnackbar('Error!', {variant:'error'});
      setSubmitEnabled( true );
      setLoading( false );

    });

  };

  const onChange = (event) => {

    if(event === undefined) return;

    const { value, name } = event.target;

    switch (name) {
      case 'client_id': setClientId( value ); break;
      case 'label':     setLabel( value ); break;
      case 'file_name': setFileName( value ); break;
      default: console.log('Nenhum');
        break;
    }
  };

  const clearAll = () => {
    setSubmitEnabled(true);
    setClientId(0);
    setLabel('');
    setFileName('');
  }

  useEffect(()=>{
    setProps();
  },[propsData]);

  return (
    <Container component="main" maxWidth="xs">
      <div>
        <form className={classes.form} noValidate autoComplete="off" >
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={12}>
              Collections Book: {collectionId}
            </Grid> */}
            <If value={fileUpload===null}>
              {/* <Grid item xs={12} sm={12}>
                <div style={styles.container}>
                  <div style={styles.buttonWrap}>
                    <label style={styles.button} for="upload">Upload File</label>
                    <input id="upload" type="file" style={styles.inputFile}
                      onChange={onChangeFile}
                      onClick={onChangeFile}
                    />
                  </div>
                </div>
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <div className="file">
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="file"
                    // multiple
                    type="file"
                    onChange={onChangeFile}
                    onClick={onChangeFile}
                  />
                </div>
                <label htmlFor="file">                  
                  <Button variant="contained" component="div" disableElevation={true} fullWidth disabled={!fileUploadSubmitEnabled}>
                  upload file 
                  </Button>
                </label>
              </Grid>
              <LinearProgress variant="determinate" value={fileUploadLoad} fullWidth />
            </If>
            <If value={fileUpload !== null}>
              <Grid container spacing={1} xs={12} sm={12} style={{fontSize:'12px'}}>
                <Grid item xs={7} sm={7} style={{paddingLeft:15}}>
                {fileUploadLoad === 0 || fileUploadLoad === 100 ? '' : (fileUploadLoad + '% - ')} 
                {fileUploadName}
                </Grid>
                <Grid item xs={3} sm={3}>
                {fileUploadSize} 
                </Grid>
                <Grid item xs={2} sm={2} style={{paddingRight:5,textAlign:'right',cursor:'pointer'}}>
                  <Delete onClick={()=>formFileClear()}/>
                </Grid>
              </Grid>
            </If>
            <Grid item xs={12} sm={12}>
              <TextField 
                InputLabelProps={{ shrink: true }} shrink
                autoComplete="brandsname"
                name="label"
                variant="outlined"
                // required
                fullWidth
                label="Label"
                autoFocus
                onChange={onChange}
                value={label}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                disabled={!submitEnabled}
                type="no_submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Create
              </Button>

            </Grid>
          </Grid>

        </form>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </Container>
  );
}