import React, { useEffect, useState } from "react";
import { useGlobal } from "../../../../../../resources/setting/global";
import Effects from "../../../../../../resources/fabricjs/Effects";
import UtilsObject from "../../../../../../resources/utils/UtilsObject";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";

const styles = {
  controlls: {
    paddingLeft: 5,
    paddingRight: 10,
    display: 'block', 
    // background: '#eeeeee', 
    // borderRadius: 7,
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
    minWidth: 120,
  },
  formControlLabel: {
    fontSize:10, 
    paddingLeft:20, 
    display:'block',
  },
  label: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12,
    fontWeight: 400,
    display:'block',
  },
}

const BoxGrayscale = (props) => {
  
  // global variables
  const {valGlobal} = useGlobal();
  // filters values
  const list = [ 'Average', 'Lightness', 'Luminosity' ];
  const [mode, setMode] = useState(0);

  // apply filter
  const apply = ( setting ) => {
    new Effects().Grayscale( valGlobal.canvas, props.values.index, setting );
  }

  // update this component
  const updateComponents = () => {

    UtilsObject.getActive( valGlobal.canvas ).then( object => {

      if (object.hasOwnProperty('filters') === false) return;      
      object.filters = object.filters || [];
      object.filters.forEach( el => {
        if(el.name === props.values.name){
          // set values
          setMode(props.values.mode);
        }
      });

    }).catch(()=>{});

  };

  const onChange = (el) => {
    setMode(el);
  }

  useEffect(() => {
    if(mode){
      const settings = {
        ...props.values,
        mode: mode,
      }
      apply(settings);
    }
  },[mode]);

  useEffect(() => {    
    updateComponents();
  },[]);

  return (
    <div style={styles.controlls}>

      {list.map( ( el ) => (
        <FormControlLabel key={el}
          control={<Checkbox checked={ el.toLowerCase() === mode } 
          onChange={() => onChange(el.toLowerCase())} 
          fullWidth
          style={styles.formControlLabel}
          name={el.toLowerCase()} />}
          label={<Typography variant="body2" style={styles.label}>{el}</Typography>}
        />
      ))}

    </div>
  );
}
export default BoxGrayscale;