import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import Exports from "../../../../resources/fabricjs/Exports";
import Imports from "../../../../resources/fabricjs/Imports";
import { useGlobal } from "../../../../resources/setting/global";
import ApiServer from "../../../../resources/conn/ApiServer";


export default function SectionTop(props) {

  // const exports = new Exports();
  const imports = new Imports();

  const {valGlobal} = useGlobal();
  
  const load = (n) => {
    setLoading(true);
    //new ApiServer().getHttp('../storage',`data-old/text-${n}.txt`,{},setLoading).then((e)=>{
    new ApiServer().get('../storage',`data-old/data-${n}.json`,{},setLoading).then((e)=>{ // data-01.json
      const data = JSON.stringify(e.data);
      imports.fromJson( valGlobal.canvas, data );
      setLoadingClose(false);
    }).finally(()=>{
      setLoadingClose(false);
    });
  }
  const setLoadingClose = () => {
    props.setLoading(false);
    props.setLoading(false);
  }

  const setLoading = (data) => {
    return;
    if(props.hasOwnProperty('setLoading')){
      if(typeof data === 'boolean'){
        data = {loading:data,percent:data?100:0};
      }
      props.setLoading({loading:data.loading,percent:data.percent});
    }
    //GlobalEvents.dispatch('globalLoading',{loading:true});
  }

  return (
    <React.Fragment>
      <Typography variant="body2" component="body2" gutterBottom 
        style={{ fontSize:20, fontWeight: 300, marginLeft: 0, color: '#999999', }}>
          MISS NINA
      </Typography>

      <button onClick={()=>{ load('01') }} style={{marginLeft:10, opacity:0.2}}>1</button>
      <button onClick={()=>{ load('02') }} style={{marginLeft:1, opacity:0.2}}>2</button>
      <button onClick={()=>{ load('03') }} style={{marginLeft:1, opacity:0.2}}>3</button>
      <button onClick={()=>{ load('04') }} style={{marginLeft:1, opacity:0.2}}>4</button>
      <button onClick={()=>{ load('05') }} style={{marginLeft:1, opacity:0.2}}>5</button>
      
    </React.Fragment>
  );
};

