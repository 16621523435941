import React from 'react';

const styles = {
  title: {
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		fontSize: 12,
		fontWeight: 500,
    paddingBottom: 7, 
    display:'block',
    color: '#444444', 
    margin: 0, 
  }
}
const LittleTitle = (props) => {
  return (
    <h3 style={styles.title}>
      {props.children}
    </h3>
  )
}
export default LittleTitle;