import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Backdrop, CircularProgress} from '@material-ui/core';
import ApiServer from "../../../../resources/conn/ApiServer";
import GlobalEvents from "../../../../resources/event/GlobalEvents";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
    minWidth: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  input: {
    display: 'none',
  },
}));

export default function ColorsCreate(props) {

  const classes = useStyles();

  // form status
  const {enqueueSnackbar} = useSnackbar();
  const [loading,setLoading] = React.useState(false);
  const [submitEnabled,setSubmitEnabled] = useState(true);

  // form input
  const [colorName,setColorName] = useState('');
  const [code,setCode] = useState('');
  const [hex,setHex] = useState('');
  const [pantone,setPantone] = useState('');

  const onSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();

    if(submitEnabled === false) return;

    setSubmitEnabled( false );
    setLoading( true );

    const data = {
      color_name: String(colorName).trim(),
      code: String(code).trim() || '',
      hex: String(hex).trim() || '',
      pantone: String(pantone).trim() || '',
    }

    new ApiServer().post( 'colors', 'create', data ).then(( e )=>{

      if(e.statusText==='OK'){
        GlobalEvents.dispatch('updateColors');
        enqueueSnackbar('Create a new!', {variant:'success'});
        clearAll();

        // ToolDialog close
        if(props.hasOwnProperty('setOpen')){
          props.setOpen(false);
        }
        
      }
      setSubmitEnabled( true );
      setLoading( false );

    }).catch((err)=>{

      console.log( err.statusText, err);
      enqueueSnackbar('Error!', {variant:'error'});
      setSubmitEnabled( true );
      setLoading( false );

    });

  };

  const onChange = (event) => {

    if(event === undefined) return;

    const { value, name } = event.target;

    switch (name) {
      case 'colorName':   setColorName( value ); break;
      case 'code':        setCode( value ); break;
      case 'hex':         setHex( String(value).indexOf('#') === 0 ? value : `#${value}` ); break;
      case 'pantone':     setPantone( value ); break;
      default: console.log('Nenhum');
        break;
    }
  };

  const clearAll = () => {
    setSubmitEnabled(true);
    setColorName('');
    setCode('');
    setHex('');
    setPantone('');
  }

  return (
    <Container component="main" maxWidth="xs">
      <div>
        <form className={classes.form} noValidate autoComplete="off" >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField 
                InputLabelProps={{ shrink: true }} shrink
                autoComplete="colorname"
                name="colorName"
                variant="outlined"
                required
                fullWidth
                label="Color Name"
                autoFocus
                onChange={onChange}
                value={colorName}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField 
                InputLabelProps={{ shrink: true }} shrink
                autoComplete="colorcode"
                name="code"
                variant="outlined"
                fullWidth
                label="Code"
                onChange={onChange}
                value={code}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container xs={12} sm={12} spacing={2}>
                <Grid item xs={10} sm={10}>
                  <TextField 
                    InputLabelProps={{ shrink: true }} shrink
                    autoComplete="colorhex"
                    name="hex"
                    variant="outlined"
                    fullWidth
                    label="Hex (#FFF)"
                    onChange={onChange}
                    value={hex}
                    inputProps={{ maxLength: 9 }}
                  />
                </Grid>
                <Grid item xs={2} sm={2} alignContent="center" alignItems="center">
                <div style={{ width:50, height:50, marginRight:20, backgroundColor:(hex.length>6?hex:'#eeeeee'), }}></div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField 
                InputLabelProps={{ shrink: true }} shrink
                autoComplete="colorpantone"
                name="pantone"
                variant="outlined"
                fullWidth
                label="Pantone"
                onChange={onChange}
                value={pantone}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                disabled={!submitEnabled}
                type="no_submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Create
              </Button>

            </Grid>
          </Grid>

        </form>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </Container>
  );
}