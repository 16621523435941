import React from "react";
import { Tooltip } from "@material-ui/core";

import undoIcon from "./../../../../assets/personalSVG/history-undo.svg";
import redoIcon from "./../../../../assets/personalSVG/history-redo.svg";
import { useGlobal } from "../../../../resources/setting/global";

// icons
// https://seekicon.com/free-icon/rotate-right-icon_3

const style = {
  button: {
    minWidth: 30,
    backgroundColor: "transparent",
    border: 0,
    padding: 0,
    paggingTop: 5,
    margin: 0,
		cursor: 'pointer',
    padding: '5px 7px',
  },
  icon: {
    height: 17,
    color: "#777777",
    fill: "#777777",
  },
  division: {
    marginLeft: 20,
  },
};

export default function MenuHistory(props) {

  const { valGlobal } = useGlobal();
  // const { valHistory } = useHistory();

  const undo = () => {
		// valHistory.undo( valGlobal.canvas );
    valGlobal.canvas.history.undo();
	}

	const redo = () => {
		// valHistory.redo( valGlobal.canvas );
    valGlobal.canvas.history.redo();
	}

  return (
   <React.Fragment>
      <Tooltip title="History: Undo" placement="top">					
        <a style={style.button} onClick={undo} >
          <img src={undoIcon} style={style.icon}/>
        </a>
      </Tooltip>
      <Tooltip title="History: Redo" placement="top">					
        <a style={style.button} onClick={redo}>
          <img src={redoIcon} style={style.icon}/>
        </a>
      </Tooltip>
    </React.Fragment>
  );
}
