import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import router from "./../../../resources/router/router";

export default function Dashboard() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {[...router].reverse().map( (el,index) => <Route path={el.path} component={() => el.component} key={index}></Route>)}
        </Switch>
      </Router>
    </React.Fragment>
  );
}
