import React, { useEffect, useState } from "react";
import { Grid, Slider, TextField, Typography } from "@material-ui/core";
import {fabric} from 'fabric';
import { useGlobal } from "../../../../../../resources/setting/global";
import Effects from "../../../../../../resources/fabricjs/Effects";
import UtilsCanvas from "../../../../../../resources/utils/UtilsCanvas";
import If from "../../../../../../resources/utils/If";
import UtilsColor from "../../../../../../resources/utils/UtilsColor";
import SliderInput from "../../../../../../resources/component/general/inputs/SliderInput";

const styles = {
  controlls: {
    paddingLeft: 10,
    paddingRight: 10,
    display: 'block', 
    // background: '#eeeeee', 
    // borderRadius: 7,
  },
  selectedColor: {
    display:'block',
    borderRadius:'4px',
    backgroundColor: '#000000',
    padding:'10px 7px',
    textAlign:'center',
    cursor:'pointer', 
    marginTop: 15, 
    marginBottom: 15
  }
}

const BoxRemoveColor = (props) => {
  
  const {valGlobal} = useGlobal();
  const [allowSelectColor, setAllowSelectColor] = useState(false);
  const [color, setColor] = useState('#1210ED'); //'#aaaaaa'
  const [distance, setDistance] = useState(0.2);

  let hex = '';
  let timeColor = 0;

  const apply = ( setting ) => {
    new Effects().RemoveColor( valGlobal.canvas, props.index, setting );
  }

  const onChangeDistance = ( dist ) => {
    let value = isNaN(Number(dist)) ? 0 : Number(dist) ;
    value = value >= 100 ? 100 : value ;
    setDistance(value);
    const v = {
      ...props.values,
      color: color, 
      distance: value/100,
    };
    apply(v);
  };

  const onChangeColor = () => {

    setAllowSelectColor(true);

    valGlobal.canvas.on("mouse:move", onChangeSelectColor);
    valGlobal.canvas.on("mouse:down:before", () => {

      setAllowSelectColor(false);

      valGlobal.canvas.off("mouse:move");
      valGlobal.canvas.off("mouse:down:before");

      clearTimeout(timeColor);
      timeColor = setTimeout( ()=>{
        const v = {
          ...props.values,
          color: hex, 
          distance: distance/100,
        };
        apply(v);
      }, 100);

    });
  };

  const onChangeSelectColor = ( e ) => {

    // get the current mouse position
    const mouse = valGlobal.canvas.getPointer(e.e);
    const x = parseInt(mouse.x);
    const y = parseInt(mouse.y);

    // get the color array for the pixel under the mouse
    // const c = document.getElementById('canvas');
    // const px = valGlobal.canvas.getContext('2d').getImageData(x, y, 1, 1).data;
    const px = valGlobal.canvas.getContext("2d").getImageData(x, y, 1, 1).data;
   
    const r = `rgb(${px[0]},${px[1]},${px[2]},${px[3]})`;
    const h = `#${new fabric.Color(r).toHex()}`;
    
    console.log('RGB:',r,'HEX:',h);
    hex = h;
    setColor(h);

  };

  const updateComponents = ( c ) => {

    // get active object
    let object = UtilsCanvas.getActiveObject( c );
    if (object === undefined) return;
    if (object.hasOwnProperty('filters') === false) return;

    object.filters = object.filters || [];
    object.filters.map((el)=>{
      if(el.name === props.name){ // 'RemoveColor'){
        setColor(el.color);
        setDistance(el.distance*100);
      }
      return el;
    })

  };

  useEffect(() => {    
    updateComponents( valGlobal.canvas );
  },[]);

  useEffect(() => {
    //updateComponents( valGlobal.canvas );
  },[valGlobal]);

return (
    <div style={styles.controlls}>

        <SliderInput value={distance} min={0} max={100} step={1} default={0} onChange={(e)=>onChangeDistance(e.value)} isInt={true} decimal={0}/>

        <div style={{...styles.selectedColor,backgroundColor:color}}
        onClick={()=>onChangeColor()}
        >
          <span style={{color: new UtilsColor().invertColorBW( color ) }}>
           <If value={allowSelectColor===true}>
              select color now
            </If>
            <If value={allowSelectColor===false}>
              selected color <strong>{color}</strong>
            </If>
          </span>
        </div>
    </div>
    );
}
export default BoxRemoveColor;