import React, { useState } from 'react';
import { GridList, GridListTile, makeStyles } from '@material-ui/core';
import { useGlobal } from '../../../../resources/setting/global';
import Images from '../../../../resources/fabricjs/Images';
import ApiServer from '../../../../resources/conn/ApiServer';
import BoxPanel from './../../../../resources/component/general/views/BoxPanel';

const useStyles = makeStyles((theme) => ({
  gridList: {
    // width: 'auto',
  },
}));

export default function PanelBrands() {

  const classes = useStyles();
  const {valGlobal} = useGlobal();
  const URL_IMAGE = process.env.REACT_APP_IMAGE_HOST;
  const [list, setList] = useState([]);

  const setImage = ( el ) => {
    const c = valGlobal.canvas;
    new Images().add( c, {
      source: URL_IMAGE + el.file_name,
      scale: 1,
    }, v => c.centerObject( v.object ) );
  }

  const getList = () => {
    new ApiServer().get( 'brands', 'get' ).then(el => setList(el.data) );
  }

  React.useEffect(()=>{
    getList();
  },[]);

  return (
      <div style={{padding:10}}>
        <BoxPanel title="Brands">
          <GridList 
          // cellHeight={85} 
          cols={1} className={classes.gridList}>
            {list.map((el) => (
              <GridListTile key={el.id} cols={1} style={{marginBottom:30, maxHeight:85, height:'auto'}}>
                <center>
                  <img src={URL_IMAGE + '100/' + el.file_name} alt={el.name} style={{cursor:'pointer',maxHeight:80,width:'auto',maxWidth:140}} onClick={()=>setImage(el)} />
                </center>
              </GridListTile>
            ))}
          </GridList>
        </BoxPanel>
    </div>
  );
}
