import React from 'react';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import MenuBar from '../../layouts/dashboard/MenuBar';

export default function Home(props) {
  return (
    <Container component="main" maxWidth="md" style={{paddingTop:100}}>
      <MenuBar/>
      <center>
        <Typography variant="h6" component="h6">WELCOME TO</Typography>
        <Typography variant="h1" component="h1">AVENUZ</Typography>
        <Typography style={{fontSize:30,fontWeight:300}}>Awesome app with 4D incredible effects</Typography>
      </center>
    </Container>
  );
}