import React, {useState} from "react";
import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import HdrStrongIcon from '@material-ui/icons/HdrStrong';
import transparentSmall from './../../../../../../assets/images/background-transparent-small.png';
import ApiServer from "../../../../../../resources/conn/ApiServer";
import UtilsObject from "../../../../../../resources/utils/UtilsObject";
import UtilsCanvas from "../../../../../../resources/utils/UtilsCanvas";
import UtilsArray from "../../../../../../resources/utils/UtilsArray";
import GlobalEvents from "../../../../../../resources/event/GlobalEvents";
import { useGlobal } from "../../../../../../resources/setting/global";
import UtilsColor from "../../../../../../resources/utils/UtilsColor";

const useStyles = makeStyles((theme) => ({
  scrollBar: {
    overflow: 'scroll',
    overflowX: 'hidden',
    paddingLeft: 10, 
    paddingRight: 10,
  },
  paper: {
    padding: 10,
    //textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(0),
  },
}));

export default function BoxColorStroke() {

  const classes = useStyles();
  const {valGlobal, setGlobal} = useGlobal();
  const [disabledInputs, setDisabledInputs] = useState(true); // disabled inputs
  const [panelsHeight, setPanelsHeight] = useState( 100 ); // scroll height
  // const [opacity, setOpacity] = useState(1);
  const [strokeColor, setStrokeColor] = useState('');
  const [strokeWidth, setStrokeWidth] = useState(0);
  const [workingFillColors, setWorkingFillColors] = useState([]);
  const [colors, setColors] = useState([]); // color after input word filter
  const [colorsFilter, setColorsFilter] = useState([]); // color after input word filter
  const [colorFilter, setColorFilter] = useState(''); // input filter  words

  const resizePanelsHeight = () => {
    setPanelsHeight( 
      window.innerHeight - 
      valGlobal.structure.tableLines.top.height - 
      valGlobal.structure.tableLines.bottom.height );
  };

  const getColors = () => {
    new ApiServer().get('colors', 'get').then( data => {
      if( data.statusText === 'OK' && data.hasOwnProperty('data') ){
        setColors( data.data );
        setColorsFilter( data.data );
      }
    });
  };

  const getData = ( event ) => {

    //const ao = event.hasOwnProperty('activeObject') ? event.activeObject : undefined;
    UtilsObject.getActive( valGlobal.canvas, event && event.activeObject ).then( object => {
      setDisabledInputs( false );
      // setOpacity( object.opacity || 1 );
      setStrokeColor( object.stroke || '' );
      setStrokeWidth( object.strokeWidth || 0 );

    }).catch(resetData);
  }

  const changeColorFilter = ( filter ) => {
    if( !filter ) setColorsFilter( colors ); 
    setColorsFilter(
      colors.filter(
        (el) => String(el.color_name).toLowerCase().indexOf( String(filter).toLowerCase() ) > -1 ||
        String(el.hex).toLowerCase().indexOf( String(filter).toLowerCase() ) > -1
       )
    );
  };

  // const onChangeOpacity = ( event ) => {
  //   const v = Number(event.target.value);
  //   setOpacity( v );
  //   UtilsCanvas.set( valGlobal.canvas, {
  //     opacity: v / 100,
  //   });
  // };

  const selectColorCode = ( event ) => {
    const key = event.keyCode || event.charCode ;
    if (key === 13) {
      const value = colors.find(({code,color_name})=>{ 
        // if code like filter
        return String(code).toLowerCase() === String(colorFilter).toLowerCase() || 
        // if name like filter
        String(color_name).toLowerCase() === String(colorFilter).toLowerCase() 
      });
      if(typeof value === 'object'){
        changeColorStroke( value );
        setColorFilter('');
      }
    }
  }

  const onChangeStrokColor = ( event ) => {
    const v = String(event.target.value).substr(0,9) || '#000000' ;
    setStrokeColor( v );
    UtilsCanvas.set( valGlobal.canvas, {
      stroke: v,
    });
  };

  const onChangeStrokeWidth = ( event ) => {
    const v = Number(event.target.value) || 0 ;
    setStrokeWidth( v );
    UtilsCanvas.set( valGlobal.canvas, {
      strokeWidth: v,
    });
  };

  // https://casesandberg.github.io/react-color/
  const changeColorStroke = ( el, insertToGlobal ) => {

    UtilsObject.getActive( valGlobal.canvas ).then( object => {

      insertToGlobal = insertToGlobal === undefined ? true : insertToGlobal;

      if( el === undefined ){
        el = {
          name: '',
          code: '',
          hex: null,
        };
      }
      // get type object
      const { typeName } = object;

      object.document = object.document || {};
      object.document.pattern = object.document.pattern || {};
      object.document.pallete = object.document.pallete || {};

      // denied change fill color
      switch (typeName) {
        case 'Bend':
        case 'Image': 
        // case 'Line': 
        // case 'Group': 
        break;
        default:

          const strokeRedefine = el.hex === null ? 0 : ( strokeWidth === 0 && el.hex !== null ? 2 : strokeWidth );
          const values = {
            stroke: el.hex,
            strokeWidth: strokeRedefine,
            // document
            document: {
              ...object.document,
               // set stroke pallete
               pallete: {
                ...object.document.pallete,
                stroke: {
                  name: el.color_name,
                  code: el.code,
                  hex: el.hex,
                  width: strokeRedefine,
                },
              },
              // remove stroke pattern
              pattern: { 
                ...object.pattern,
                stroke: {
                  name: "Silk: Silk",
                  url: false,
                  code: false,
                }, 
              },
            },
          };

          // change variable width
          setStrokeWidth( strokeRedefine );
          // change variable color
          setStrokeColor( el.hex );
          // apply object
          UtilsCanvas.set( valGlobal.canvas, values );
          // object.set( values );
          // history
          valGlobal.canvas.history.register();
          // push to global colors
          if( el.hex !== null && insertToGlobal ){
            pushWorkingFillColors( el );
          }
          break;
      }

      //setAnchorEl(null);
      clearColorFilter();
      UtilsCanvas.focus();
      
    });

  }

  const resetData = () => {
    setDisabledInputs( true );
    setStrokeColor( '' );
    setStrokeWidth( 0 );
    // setOpacity( 1 );
  }

  const clearColorFilter = (event) => {
    setColorsFilter( colors );
    setColorFilter( '' );
  }

  const getWorkingFillColors = () => {
    setWorkingFillColors(valGlobal.working.fillColors);
  }

  const pushWorkingFillColors = ( el ) => {

    let c = [ el, ...valGlobal.working.fillColors ];
    c = UtilsArray.unique( c, ['hex'] );
    c = c.slice(0,8);

    setGlobal({...valGlobal, 
      working: {...valGlobal.working, 
        fillColors: c,
      }
    });      
  
  }

  React.useEffect(()=>{
    getWorkingFillColors();
  },[valGlobal]);

  React.useEffect(()=>{
    getData();
    getColors();
    resizePanelsHeight();
    window.addEventListener("resize", resizePanelsHeight);
    GlobalEvents.on('changeActiveObject',getData);
    return () => {
      window.removeEventListener("resize", resizePanelsHeight);
      GlobalEvents.off('changeActiveObject',getData);
    }
  },[]);

	// local temporary

	const strokeLineCap = [ 'butt', 'round', 'square' ];
	const strokeLineJoin = [ 'bevel', 'round', 'miter' ];
	const strokeDash = [
		[5,5],
		[6,4],
		[7,3],
		[9,3],
		[10,5],
		[15,10],
		[13,7],
		[12,5],
		[12,20],
		[20,30],
	];

	const onChangeLineCap = (e) => {
		const c = valGlobal.canvas;
		UtilsObject.getActive( c ).then( object => {
			UtilsCanvas.set( c, {
				object: object,
				strokeLineCap: e.target.value,
			});
		});
	}

	const onChangeLineJoin = (e) => {
		const c = valGlobal.canvas;
		UtilsObject.getActive( c ).then( object => {
			UtilsCanvas.set( c, {
				object: object,
				strokeLineJoin: e.target.value,
			});
		});
	}

	const onChangeDash = (e) => {
		const c = valGlobal.canvas;
		UtilsObject.getActive( c ).then( object => {
			UtilsCanvas.set( c, {
				object: object,
				strokeDashArray: strokeDash[e.target.value],
			});
		});
	}

  return (
    <div className={classes.scrollBar} style={{height: panelsHeight}}>
      {/* <Paper className={classes.paper}> */}
      <div style={{marginBottom:10}}>
        <Typography variant="subtitle1" gutterBottom>Stroke</Typography>

        <Grid container spacing={0}>
          {workingFillColors.map((el) => (
            <Grid item xs={3} key={el.id}>
                <div style={{height: 15, backgroundColor: el.hex, borderRadius: 3, marginRight: 0, cursor: 'pointer' }}
                title={`Name: ${el.color_name}\nHex: ${el.hex}\nCode: ${el.code}\n`}
                onClick={()=>changeColorStroke(el,false)}
                >&nbsp;</div>
            </Grid>
          ))}
        </Grid>

        <div>
					Dash (traço)
					<select width="100%" style={{width:'100%'}} onChange={onChangeDash}>
						<option value="">None</option>
						{strokeDash.map( (el,index) => (
							<option value={index}>{el.join(',')}</option>
						))}
					</select>
        </div>
        <div>
					Cap (forma)
					<select width="100%" style={{width:'100%'}} onChange={onChangeLineCap}>
						<option value="">None</option>
						{strokeLineCap.map( el => (
							<option value={el}>{el}</option>
						))}
					</select>
        </div>
        <div>
					Corner (canto)
					<select width="100%" style={{width:'100%'}} onChange={onChangeLineJoin}>
						<option value="">None</option>
						{strokeLineJoin.map( el => (
							<option value={el}>{el}</option>
						))}
					</select>
        </div>

        <div style={{marginTop:3,marginBottom:3,}}>
          <label style={{color:'#777777'}}>Strk Color:</label>
        </div>
        <div>
          <TextField value={strokeColor}
            disabled={disabledInputs}
            onChange={ onChangeStrokColor } 
            variant="outlined" size="small"
            fullWidth
            inputProps={{ 
              style: {
                textAlign: 'center',
                fontSize: 11,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 5,
                paddingRight: 5,
              } 
            }} />
        </div>
        <div style={{marginTop:3,marginBottom:3,}}>
          <label style={{color:'#777777'}}>Strk Width:</label>
        </div>
        <div>
          <TextField value={strokeWidth}
            disabled={disabledInputs}
            onChange={ onChangeStrokeWidth } 
            variant="outlined" size="small"
            fullWidth
            inputProps={{ 
              style: {
                textAlign: 'center',
                fontSize: 11,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 5,
                paddingRight: 5,
              } 
            }} />
        </div>
        {/* <hr style={{margin:'15px 0 10px 0'}}/> */}
        <div style={{marginTop:3,marginBottom:3,}}>
          <label style={{color:'#777777'}}>Search:</label>
          <div style={{color:'#777777',float:'right'}}>
            <Search style={{fontSize:13}} />
          </div>
        </div>
        <div>
          <TextField value={''}
            disabled={disabledInputs}
            // onChange={ onChangeInput } 
            variant="outlined" size="small"
            fullWidth

            onKeyUp={ selectColorCode }
            onChange={ (e)=>{ setColorFilter( e.target.value ); changeColorFilter( e.target.value )} }
            onBlur={()=>UtilsCanvas.focus()}
            value={ colorFilter }

            inputProps={{ 
              style: {
                textAlign: 'center',
                fontSize: 11,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 5,
                paddingRight: 5,
              } 
            }} />
        </div>
      </div>

      <div
        key = {'transparent'}
        onClick={()=>changeColorStroke( undefined, false )}
        style={{ height: 25, margin: 1, marginBottom:2, borderRadius: 5, cursor: "pointer", backgroundColor: '#ffffff', backgroundImage: `url(${transparentSmall})`, border: '1px solid #cccccc'}} >
        <div style={{ padding: "5px 1px 3px 0px", textAlign: "center", fontSize: "11px", color: '#777777'}} >
          <div style={{ fontSize: 12 }}>&nbsp;</div>
        </div>
      </div>

      {/* </Paper> */}
      {colorsFilter.map((el) => (
        // <div style={{display:'block',width:'100$',height:30, cursor:'pointer', backgroundColor: el}} key={el}>
        //   <div style={{padding:'6px 3px 3px 5px',fontSize:'12px',color: new UtilsColor().invertColorBW( el , true ) }}>{el}</div>
        // </div>
        <div
          key = {el.id}
          onClick={()=>changeColorStroke(el)}
          style={{
          // width: 65,
            // height: 65,
            // borderRadius: 40,
            // margin: "2px",
            // width: '100%',

            height: 30,
            margin: '1px',
            borderRadius: 5,

            cursor: "pointer",
            backgroundColor: el.hex+'CC',
            border: `5px solid ${el.hex ? el.hex : '#eeeeee'}`,
          }}
        >
          {/* <HdrStrongIcon style={{ fontSize: 11, color: '#ffffff77', marginLeft: 2 }}/> */}
          <div
            style={{

              padding: "10px 1px 3px 0px",

              // padding: "0px 1px 3px 0px",
              textAlign: "center",
              fontSize: "11px",
              color: new UtilsColor().invertColorBW(el.hex, true),
              // color: '#333333',
            }}
          >
            {/* {el.hex}  */}
            <div style={{ fontSize: 10 }}> {el.code ? el.code : el.color_name } </div>
          </div>
        </div>
      ))}
      {/* </Paper> */}
    </div>
  );
}
