import { Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import GlobalEvents from '../../../../resources/event/GlobalEvents';
import { useGlobal } from '../../../../resources/setting/global';
import UtilsCanvas from '../../../../resources/utils/UtilsCanvas';
import UtilsObject from '../../../../resources/utils/UtilsObject';

const PIXEL_TO_MILLIMETER = 0.2645833333;
const MILLIMETER_TO_PIXEL = 3.7795275591;

const styles = {
  box: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		fontSize: 12,
		fontWeight: 400,
    maxWidth: 180,
  }
}

export default function ElementDimension() {

  const {valGlobal} = useGlobal();
  // const [activeObject,setActiveObject] = useState(undefined);
  const [width,setWidth] = useState(null);
  const [height,setHeight] = useState(null);
  // const [inputEnabled,setInputEnabled] = useState(false);

  const timeOut = 200;
  const isInt = false;
  const decimal = 1;
  const max = 500;
  const min = 1;

  const [times,setTime] = useState(0);
  
  const changeTimeOut = ( event, setHook, callback ) => {
    let v = event.target.value;
    v = v.replace(',','.')
    setHook( (isNaN(v) && v !== ' ' && v !== '-') ? min : v );
    if( v === '' ) return; // waint keyup
    clearTimeout( times );
    setTime( 0 );
    setTime( setTimeout(()=>{

      let v = isInt === true ? (event.target.value >> 0) : Number(Number(event.target.value).toFixed(decimal));;
      v = v < min ? min : v ;
      v = v > max ? max : v ;
      
      setHook( v );
      //dispatchChange( v );
      if( callback !== undefined ){
        callback( v );
      }

    },timeOut));
  };

  const changeWidth = ( v ) => {
    const c = valGlobal.canvas;
    UtilsCanvas.set( c, { width: v * MILLIMETER_TO_PIXEL });
    // el.set({ width: v * MILLIMETER_TO_PIXEL });
    // el.setCoords();
    // UtilsCanvas.renderAll( c );
  }

  const changeHeight = (v) => {
    const c = valGlobal.canvas;
    UtilsCanvas.set( c, { height: v * MILLIMETER_TO_PIXEL });
  }
  
  const clearAll = () => {
    setWidth('');
    setHeight('');
  }

  const setDimensions = ( event ) => {
    const c = valGlobal.canvas;
    UtilsObject.getActive( c, event && event.activeObject ).then( object => {
      // setActiveObject( object );
      const { width, height, scaleX, scaleY } = object;
      setWidth( Number( ( width * scaleX ) * PIXEL_TO_MILLIMETER ).toFixed(1) );
      setHeight( Number( ( height * scaleY ) * PIXEL_TO_MILLIMETER ).toFixed(1) );
    }).catch(clearAll);
  }

  useEffect(()=>{
    setDimensions();
    GlobalEvents.on("changeActiveObject", setDimensions );
    return () => {
      GlobalEvents.off("changeActiveObject", setDimensions );
    };
  },[]);

  return (
    <div style={styles.box}>

        <Grid container spacing={1} style={{textAlign:'center',alignItems:"center",display:'flex',paddingTop:7}}>
          <Grid item xs={3} style={{textAlign:'left',marginBottom:5}}>
            Unit
          </Grid>
          <Grid item xs={9} style={{textAlign:'left',marginBottom:5}}>
            Milimmeters (mm)
          </Grid>
          <Grid item xs={3} style={{textAlign:'left'}}>
            Width
          </Grid>
          <Grid item xs={5} >
            <TextField 
              value={width}
              onChange={(e) => changeTimeOut(e,setWidth,changeWidth)} 
              onKeyUp={(e) => changeTimeOut(e,setWidth,changeWidth)} 
              variant="outlined" size="small"
              fullWidth
              inputProps={{ 
                style: {
                  textAlign: 'center',
                  fontSize: 11,
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                } 
              }} />
          </Grid>
          {/* <Grid item xs={2} style={{textAlign:'center'}}>
            mm
          </Grid> */}
          <Grid item xs={4} style={{textAlign:'right',color:'#999999'}}>
            {width && Number(width * MILLIMETER_TO_PIXEL).toFixed(0)} px
          </Grid>
          <Grid item xs={3} style={{textAlign:'left'}}>
            Height
          </Grid>
          <Grid item xs={5}>
            <TextField 
              value={height}
              onChange={(e) => changeTimeOut(e,setHeight,changeHeight)} 
              onKeyUp={(e) => changeTimeOut(e,setHeight,changeHeight)} 
              variant="outlined" size="small"
              fullWidth
              inputProps={{ 
                style: {
                  textAlign: 'center',
                  fontSize: 11,
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                } 
              }} />
          </Grid>
          {/* <Grid item xs={2} style={{textAlign:'center'}}>
            mm
          </Grid> */}
          <Grid item xs={4} style={{textAlign:'right',color:'#999999'}}>
            {height && Number(height * MILLIMETER_TO_PIXEL).toFixed(0)} px
          </Grid>
        </Grid>

    </div>
  );
}
