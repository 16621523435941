import React, { createContext, useContext, useState } from "react";
import UtilsScreen from "../utils/UtilsScreen";

export const GlobalContext = createContext();

export default function GlobalProvider({ children }) {

  // Alguns valores pre-definidos para serem utlizados
  // para configuracoes em diversos locais

  const [sizes] = useState({
    canvas: {
      width: 100,
      height: 100,
      radius: 12,
    },
    width: {
      icons: 55,
      panel: 220,
      panelRight: 100,
      exports: 80,
    },
    height: {
      top: 50,
      bottom: 50,
    },
  });

  // Configurações globais
  // Variaveis utilizadas e compartilhadas pelo sistema inteiro

  const [valGlobal, setGlobal] = useState({
    // Fabric.js Object
    uid: null,
    canvas: null,
    app: {
      name: "AVENUZ",
      package: "br.com.avenuz.natancabral.gnnc",
    },
    sizes: {
      // screen inside navigation 
      widthScreen: UtilsScreen.getNavigation().width,
      heightScreen: UtilsScreen.getNavigation().height,
      // stage canvas
      widthStage: sizes.width,
      heightStage: sizes.height,
      // clip inside canvas
      widthClip: sizes.width,
      heightClip: sizes.height,
    },
    colors: {
      backgroundColorOut: "#dddddd",
      backgroundColor: "#dddddd",
    },
    //for new shapes form
    working: {
      tool: {
        current: null, // null|draw|line|circle|rect|square
      },
      fillColors: [],
      fillColor: "#FFFFFF", //'rgba(255,0,0,0.9)",
      fillImage: null,
      strokeColor: "#999999",
      strokeWidth: 5,
      opacity: 1,
      zoom: 1,
    },
    actions: {
      allowChangePanel: true,
      allowLoading: true, //show global screen loading
      allowRecoveryHistory: true, //Redo/Undo
    },
    settings: {
      rectResize: {
        border: 0,
        borderWidth: 0,
        hoverCursor: "default",
        lockMovementX: true,
        lockMovementY: true,
        evented: false,
        hasControls: false,
        selectable: false,
        absolutePositioned: false,
        hasBorders: false,
        hasRotatingPoint: false,
        preserveObjectStacking: false,
        objectCaching: false,
      },
    },
    // Layout space, sizes, columns, rows
    structure: {
      // Canvas.js
      canvas: {
        //borderRadius: sizes.canvas.radius,
        borderTopRightRadius: sizes.canvas.radius,
        borderBottomRightRadius: sizes.canvas.radius,
      },
      // Structure.js
      table: {
        fontSize: "11px",
        width: "100%",
        height: "100%",
        borderSpacing: "0px",
      },
      tableLines: {
        top: {
          height: sizes.height.top,
        },
        middle: {},
        bottom: {
          height: sizes.height.bottom,
        },
      },
      tableColumns: {
        icons: {
          width: sizes.width.icons,
        },
        panel: {
          backgroundColor: "#dddddd", // "#e9e9e9",
          width: sizes.width.panel,
          // borderRadius: sizes.canvas.radius,
          borderBottomLeftRadius: sizes.canvas.radius,
          borderTopLeftRadius: sizes.canvas.radius,
        },
        main: {
          //width: '100%',
          height: "100%",
        },
        panelRight: {
          width: sizes.width.panelRight,
          borderRight: '1px solid #dddddd',
        },
        export: {
          width: sizes.width.exports,
        },
      },
      tableCell: {
        logo: {
          height: sizes.height.top,
        },
        paper: {
          // backgroundColor: '#eeeeee',// '#f9f9f9', // "#FFFFFF",
          // border: '1px solid #ffffff',
          // // boxShadow: '1px 1px 5px #bbbbbb',
          // padding: 10, 
          // display: 'block', 
          // borderRadius: 7,
          // color: '#444444',
          // marginBottom: 10,

          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontSize: 12,
          fontWeight: 400,
          color: '#444444', 
      
          padding: 10, 
          marginBottom: 4,
          boxShadow: '0px 0px 3px #999999CC',
          backgroundColor: '#ffffff',
          borderRadius: 7,

        },
      },
    },
    // div places
    places: {
      logoDiv: {
        paddingLeft: '0px',
        paddingTop: '0px',
      },
      iconsDiv: {
        padding: "5px",
      },
      mainDiv: {
        width: "100%",
        height: "100%",
        borderBottomRightRadius: sizes.canvas.radius,
        borderTopRightRadius: sizes.canvas.radius,
    },
      exportsDiv: {
        paddingLeft: 14,
        paddingRight: 14,
      },
    },
  });

  // let time = 0;
  // const setGlobalTimeOut = ( v ) => {
  //   clearTimeout( time );
  //   time = setTimeout(() => {
  //     setGlobal( v ); 
  //     console.log( 'setGlobalTimeOut()', v );
  //   }, 300);
  // };

  return (
    <GlobalContext.Provider value={{ valGlobal, setGlobal }}>
      {children}
    </GlobalContext.Provider>
  );
}

// created a personal hook
// this call context inside file
export function useGlobal() {
  const context = useContext( GlobalContext );
  //if(!context) throw new Error('useGlobal need privider. useGlobal must be used within a GlobalProvider');
  const { valGlobal, setGlobal } = context;
  return { valGlobal, setGlobal };
}
